import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '@fuse/shared.module';
import {AlertComponent} from './alert.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const routes = [
    {
        path     : 'alert',
        component: AlertComponent
    }
];

@NgModule({
    declarations: [
        AlertComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        MatSlideToggleModule
    ],
    exports     : [
        AlertComponent
    ]
})

export class AlertModule
{
}
