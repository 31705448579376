import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {
    debounceTime,
    finalize,
    map,
    startWith,
    switchMap,
    tap,
} from "rxjs/operators";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable, Subject } from "rxjs";

import { fuseAnimations } from "@fuse/animations";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AssignService } from "./assign.service";
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";
import { PatientService } from "../../patient/patient.service";
import { Patient } from "../../patient/patient";
import { Cassette } from "../cassette";
import { StatusService } from "../../status/status.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogUtil } from "../../../../util/DialogUtil";
import { MatDialog } from "@angular/material/dialog";
import * as copy from "copy-to-clipboard";
import { BaseComponent } from "../../../base/base.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { ApiService } from "../../../common/service/api.service";
import { CovidSetting } from "../../../screening/setting/screening-email-setting/ScreeningEmailSetting.model";
import { FirebaseUtilService } from "../../../common/service/FirebaseUtil.service";
import { TextUtils } from "../../../../util/TextUtils";
import { CookieService } from "ngx-cookie-service";
import { CassetteListService } from "../list/cassette-list.service";
import { time } from "@ngtools/webpack/src/benchmark";
const timeZone = require("moment-timezone");

export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: "assign-table",
    templateUrl: "./assign.component.html",
    styleUrls: ["./assign.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AssignComponent
    extends BaseComponent
    implements OnInit, OnDestroy
{
    ELEMENT_DATA: Cassette[] = [];
    searchMoviesCtrl = new FormControl();
    filteredMovies: any;
    selectedPatient: string;
    dataSource = new MatTableDataSource<Cassette>();
    selection = new SelectionModel<Cassette>(true, []);

    stateCtrl = new FormControl();
    filteredStates: Observable<State[]>;
    filteredPatients: Observable<Patient[]>;
    urlData = "";
    HYPHEN = "-";
    HASH = "#";
    enableAssign = false;
    states: State[] = [
        {
            name: "Arkansas",
            population: "2.978M",
            // https://commons.wikimedia.org/wiki/File:Flag_of_Arkansas.svg
            flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arkansas.svg",
        },
        {
            name: "California",
            population: "39.14M",
            // https://commons.wikimedia.org/wiki/File:Flag_of_California.svg
            flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg",
        },
    ];
    columns = [
        {
            columnDef: "Sr",
            header: "Sr.",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
        {
            columnDef: "Barcode",
            header: "Barcode",
            cell: (element: Cassette, index: number) => `${element.barcode}`,
        },
        {
            columnDef: "Type",
            header: "Type",
            cell: (element: Cassette, index: number) => `${element.type}`,
        },
        {
            columnDef: "Load No",
            header: "Load No",
            cell: (element: Cassette, index: number) => `${element.loadNo}`,
        },
        {
            columnDef: "Cycle No",
            header: "Cycle No",
            cell: (element: Cassette, index: number) => `${element.cycleNo}`,
        },
        {
            columnDef: "Result",
            header: "Result",
            cell: (element: Cassette, index: number) =>
                `${this.getCassetteResult(element)}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getLoadColor(element)}`,
        },
        {
            columnDef: "Date",
            header: "Date",
            cell: (element: Cassette, index: number) => `${element.date}`,
        },
        {
            columnDef: "Time",
            header: "Time",
            cell: (element: Cassette, index: number) => `${element.time}`,
        },

        {
            columnDef: "BD",
            header: "BD",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeBd ? element.barcodeBd : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.statusBd)}`,
        },
        {
            columnDef: "BI",
            header: "BI(Spore Test)",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeBi ? element.barcodeBi : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.statusBi)}`,
        },
        {
            columnDef: "EI",
            header: "PCD",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeEi ? element.barcodeEi : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.statusEi)}`,
        },

        {
            columnDef: "Status",
            header: "Status",
            cell: (element: Cassette, index: number) =>
                `${this.getLoadStatus(element)}`,
        },
        // `${this.page * 10 + index + 1}`
        {
            columnDef: "Remove",
            header: "Remove",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
    ];
    displayedColumns = this.columns.map((c) => c.columnDef);

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    @ViewChild("input", { static: true }) input: any;

    @ViewChild("filter", { static: true })
    filter: ElementRef;
    isLoading = false;
    errorMsg: string;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    page = 0;

    patient: Patient;
    barcode: string;
    searchText: string;
    map = new Map<string, Cassette>();
    isEnterPressed = false;
    isAssignedPressed = false;
    platform = "web";
    settingDto: any;
    selected: any;
    enablePatientEmail = "";
    enableLabCheckoutEmail = "";

    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Cassette[]>;

    constructor(
        private userService: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: AssignService,
        private patientService: PatientService,
        private changeDetectorRefs: ChangeDetectorRef,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private statusService: StatusService,
        private afs: AngularFirestore,
        public dialog: MatDialog,
        private configService: ConfigService,
        private globals: Globals,
        private apiService: ApiService,
        private firebaseUtilService: FirebaseUtilService,
        private cookieService: CookieService,
        public cassetteListService: CassetteListService
    ) {
        super(configService, globals);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.filteredStates = this.stateCtrl.valueChanges.pipe(
            startWith(""),
            map((state) =>
                state ? this._filterStates(state) : this.states.slice()
            )
        );

        this.filteredPatients = this.searchMoviesCtrl.valueChanges.pipe(
            startWith(""),
            debounceTime(500),
            tap(() => {
                this.errorMsg = "";
                this.filteredMovies = [];
                this.isLoading = true;
            }),
            switchMap((value) =>
                this.patientService
                    .getPatient(
                        TextUtils.removeSpace(value).replace(
                            /[^a-zA-Z0-9]/g,
                            ""
                        )
                    )
                    .pipe(
                        finalize(() => {
                            // console.log('value2',value);
                            this.isLoading = false;
                        })
                    )
            )
        );
    }

    private _filterStates(value: string): State[] {
        const filterValue = value.toLowerCase();
        return this.states.filter(
            (state) => state.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    @HostListener("document:keydown", ["$event"])
    handleKeyboardEven(event: KeyboardEvent) {
        const key = event.key;
        if (key === "Backspace" || key === "Delete") {
           // this.selectedPatient = null;
        }
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        const key = event.key;
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("Barcode")
        ) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.barcode.length > 0) {
                this.selectBarcode();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.barcode = "";
    }
    clearInputSearchPatient() {
        this.selectedPatient = "";
    }

    ngOnInit(): void {
        this.input.nativeElement.focus();
        console.log(this.globals.getLocalTimezoneName());
        this.urlData = this.route.snapshot.queryParams.barcode;
        if (this.urlData !== "" && this.urlData !== undefined) {
            this.barcode = this.urlData;
            this.selectBarcode();
        }

        this.firebaseUtilService
            .getObserableId(
                "lab/" + this.userService.getCookie("lab") + "/covid",
                "config"
            )
            .subscribe((dto) => {
                if (dto) {
                    this.settingDto = new CovidSetting(dto);
                    // this.selected = this.settingDto.enableStaffEmail;
                    this.enablePatientEmail =
                        this.settingDto.enablePatientEmail;
                    this.enableLabCheckoutEmail =
                        this.settingDto.enableLabCheckoutEmail;
                }
            });
    }

    // ngAfterViewInit(): void {
    //     this.barcode = this.urlData;
    // }

    onClickAssign(): void {
        if (this.dataSource.data.length) {
            const dialogUtil = new DialogUtil();

            const actualPatientId = this.selectedPatient.substr(
                0,
                this.selectedPatient.indexOf(this.HASH)
            );
            let customMsg = "";
            this.patientService
                .getPatientById(actualPatientId.toUpperCase())
                .then((data: any) => {
                    console.log(data);
                    if (data.deleted == true) {
                        this.showAlert(
                            "This patient is deleted you will not assign any cassette to this patient "
                        );
                    } else {
                        this.patientService
                            .getPatientLastCovidResultForAssignCassette(
                                actualPatientId.toUpperCase()
                            )
                            .then((data: any) => {
                                if (data && data[0].inOffice !== true) {
                                    customMsg = `In Office assessment is not completed for this patient. Are you sure to assign`;
                                } else {
                                    customMsg = "Are you sure to assign ";
                                }

                                dialogUtil.openConfirmationDialog(
                                    this.dialog,
                                    customMsg +
                                        " " +
                                        this.dataSource.data.length +
                                        " cassette(s)?",
                                    {
                                        onConfirm: () => {
                                            this.submit();
                                            // this.paginationService.shareBarcodeLinkUrl(localStorage.getItem('lab'), [], "this.patient.id"
                                            // ).subscribe(dataResult => {
                                            //     const message = 'Mail has been sent!';
                                            //     this.openSnackBar(message, 'OK');
                                            // });
                                        },
                                        onCancel: () => {},
                                        onAlways: () => {},
                                    }
                                );
                            });
                    }
                });
        } else {
            alert("There is no cassette for assignment");
        }
    }

    submit(): void {
        const list: string[] = [];
        this.selection.selected.forEach((element) => {
            if (list.indexOf(element.id) < 0) {
                list.push(element.id);
            }
        });
        if (list.length < 1) {
            this.openSnackBar("Please Select at least one cassette(s)", "OK");
            return;
        }

        let patientRealId = this.selectedPatient.substr(
            0,
            this.selectedPatient.indexOf(this.HASH)
        );
        patientRealId = patientRealId.trim();

        const patientIdHyph = this.selectedPatient
            .replace(new RegExp(this.HASH, "g"), "")
            .toLowerCase();

        // removes spaces in between name and id, if exist
        const patientId = patientIdHyph.replace(new RegExp(" ", "g"), "");

        const subs1 = this.paginationService
            .getPatientObserable(patientRealId)
            .subscribe(
                (patients) => {
                    if (patients && patients.length > 0) {
                        // check screening
                        this.patient = patients[0];
                        this.assign();
                    } else {
                        this.showAlert("No patient found for " + patientRealId);
                        // this.openSnackBar('No Patient found for' + patientId, 'OK');
                    }
                    subs1.unsubscribe();
                },
                (error) => {
                    this.openSnackBar("error come" + error, "OK");
                },
                () => {
                    //dp something
                }
            );
    }

    selectBarcode(): void {
        if (this.barcode === "" || this.barcode === undefined) {
            alert("Please enter barcode first");
            return;
        }
        const value = this.barcode.replace(/\s/g, "").toUpperCase();
        this.barcode = "";
        let isCassetteAlready = false;
        this.dataSource.data.forEach((cassette) => {
            if (cassette.barcode.includes(value)) {
                isCassetteAlready = true;
            }
        });
        if (isCassetteAlready) {
            alert(" This cassette has been already added " + value);
            return;
        } else {
        }

        const subs1 = this.paginationService
            .getCassetteEntryObserable(value)
            .subscribe(
                (data) => {
                    if (data && data.length > 0) {
                        const cass: Cassette = data[0];
                        this.updateRow(cass);
                        this.enableAssign = true;

                        const eStatus: CassetteStatus =
                            this.statusService.getCassetteStatus(cass);
                        if (this.statusService.canAssign(cass)) {
                            if (
                                eStatus ===
                                    CassetteStatus.IN_PROGRESS_BI_PENDING &&
                                this.globals.cassetteConfig
                                    .canShowAlertIfIncubatorPending
                            ) {
                                const dialogUtil = new DialogUtil();
                                dialogUtil.openConfirmationDialog(
                                    this.dialog,
                                    "Incubator Result for the cassette is pending. Do you still want to assign this cassette?",
                                    {
                                        onConfirm: () => {
                                            this.addCassetteDirect(cass);
                                        },
                                        onCancel: () => {
                                            this.enableAssign = false;
                                        },
                                        onAlways: () => {},
                                    }
                                );
                            } else {
                                this.addCassetteDirect(cass);
                            }
                        } else if (eStatus === CassetteStatus.USED) {
                            this.enableAssign = false;
                            alert("Cassette is already assigned: " + value);
                        } else {
                            alert(
                                "Cassette current status is " +
                                    eStatus.valueOf()
                            );
                        }
                    } else {
                        alert("Cassette not found " + value);
                    }
                    subs1.unsubscribe();
                },
                (error) => {
                    //dp something
                },
                () => {
                    //dp something
                }
            );
        this.barcode = "";
    }

    addCassetteDirect(cass) {
        this.dataSource.data.push(cass);
        this.dataSource.filter = "";
        this.selection.toggle(cass);
    }

    assign(): void {
        this.isAssignedPressed = true;
        const time = new Date().getTime();
        const barcodes = [];
        this.selection.selected.forEach((element) => {
            // const cassetteData = this.cassetteListService.getCassetteStatusById(this.urlData);
            // cassetteData.(cassette =>{
            //     console.log(cassetteData.status);
            //     console.log(element.barcode);
            //     if(cassetteData.status === 'Ready'){
            barcodes.push(element.barcode);
            this.paginationService.updateCassette(element.id, {
                patientId: this.patient.id,
                patientName:
                    this.patient.firstName + " " + this.patient.lastName,
                isAssignDone: true,
                status: "Assigned",
                assigned: {
                    id: this.userService.getCookie("email"),
                    name: this.userService.getCookie("name"),
                    time: time,
                },
                patient: {
                    patientId: this.patient.id,
                    patientName:
                        this.patient.firstName + " " + this.patient.lastName,
                },
                updatedBy: this.userService.getCookie("name"),
                dateTimeNum: -1 * time,
                platform: this.platform,
            });
            // }
            // })
        });
        this.openSnackBar("Cassette(s) assigned Successfully.", "OK");
        this.selection.clear();
        this.selectedPatient = "";
        while (this.dataSource.data.length > 0) {
            this.dataSource.data.pop();
        }
        this.dataSource.filter = "";

        this.isAssignedPressed = false;
        this.showAlert("Cassette(s) assigned Successfully.");

        // check for config

        this.apiService
            .shareBarcodeLinkUrl(
                this.userService.getCookie("lab"),
                barcodes,
                this.patient.id,
                this.userService.getCookie("center")
            )
            .then((dataResult) => {
                if (
                    this.enablePatientEmail === "Yes" &&
                    this.enableLabCheckoutEmail === "Yes"
                ) {
                    const message = "Mail has been sent to lab and patient !";
                    this.openSnackBar(message, "OK");
                } else if (this.enableLabCheckoutEmail === "Yes") {
                    const message = "Mail has been sent to lab!";
                    this.openSnackBar(message, "OK");
                } else if (this.enablePatientEmail === "Yes") {
                    const message = "Mail has been sent to patient!";
                    this.openSnackBar(message, "OK");
                }
            });

        this.barcode = "";
    }

    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => {},
            onCancel: () => {},
            onAlways: () => {},
        });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }
    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userService.getCookie("defaultTz"))
            .format("DD-MM-YYYY");
        row.time = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userService.getCookie("defaultTz"))
            .format("HH:mm");
        //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    nextPage(): void {
        this.paginationService.next();
    }

    prevPage(): void {
        this.paginationService.prev();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    displayFn(patient: Patient) {
        if (patient) {
            return patient.firstName;
        }
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.statusBd,
                load.statusBi,
                load.statusEi
            )
        );
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(
            this.statusService.getStatusNew(
                load.statusBd,
                load.statusBi,
                load.statusEi
            )
        );
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }

    copyCassette() {
        var copyText = "";

        if (this.dataSource.data.length === 0) {
            alert("No cassette added");
            return;
        }

        for (var i = 0; i < this.dataSource.data.length; i++) {
            const cassette = this.dataSource.data[i];
            copyText = copyText + "Barcode: " + cassette.barcode;
            copyText = copyText + ", Program: " + cassette.program;
            copyText = copyText + ", Type: " + cassette.type;
            copyText = copyText + ", Sterilizer: " + cassette.machineName;
            copyText = copyText + ", Cycle Number: " + cassette.cycleNo;
            copyText = copyText + ", Load Number: " + cassette.loadNo;

            // cassette.added.time = moment(cassette.added.time).format("DD-MM-YYYY | HH:mm");

            if (cassette.startedBy) {
                copyText =
                    copyText +
                    ", Load Start Date | Time: " +
                    timeZone(cassette.startedBy.time)
                        .tz(this.userService.getCookie("defaultTz"))
                        .format("DD-MM-YYYY | HH:mm");
                copyText =
                    copyText + ", Load Started By: " + cassette.startedBy.name;
            } else if (cassette.added) {
                copyText =
                    copyText +
                    ", Load Start Date | Time: " +
                    timeZone(cassette.added.time)
                        .tz(this.userService.getCookie("defaultTz"))
                        .format("DD-MM-YYYY | HH:mm");
                copyText =
                    copyText + ", Load Started By: " + cassette.added.name;
            }

            // cassette.updated.time = moment(cassette.updated.time).format("DD-MM-YYYY | HH:mm");
            copyText =
                copyText +
                ", Processed Date | Time: " +
                timeZone(cassette.updated.time)
                    .tz(this.userService.getCookie("defaultTz"))
                    .format("DD-MM-YYYY | HH:mm");
            copyText = copyText + ", Processed By: " + cassette.updated.name;

            const assignedTime = timeZone(timeZone.now())
                .tz(this.userService.getCookie("defaultTz"))
                .format("DD-MM-YYYY | HH:mm");
            // copyText = copyText + ', Assigned Time: ' + assignedTime;
            copyText =
                copyText +
                ", Assigned By: " +
                this.userService.getCookie("name");

            copyText = copyText + "\n";
        }

        copy(copyText);
        alert("Copied");
    }

    // remove row from the to be assigned table
    remove(id) {
        const index = id - 1; // index starts from 0
        // const index = this.dataSource.data.indexOf(id);  // got index
        this.ELEMENT_DATA = this.dataSource.data.splice(index, 1); // what id to delete and which one to delete
        this.dataSource = new MatTableDataSource(this.dataSource.data); // refresh the data
        // removing cassette from selction array as well
        this.selection.selected.splice(index, 1);
    }

    has2Hash(searchPatient: string) {
        return searchPatient.split("#").length === 3;
    }
}
