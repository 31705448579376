import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import * as moment from 'moment';
import { Globals } from '../../../../common/global';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CovidQuestionPaginationService } from './covid-question-pagination.service';
import { CovidQuestion } from './CovidQuestion';


@Component({
    selector: 'app-covidquestion',
    templateUrl: './covidquestion.component.html',
    styleUrls: ['./covidquestion.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CovidquestionComponent implements OnInit, OnDestroy, AfterViewInit {

    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    length: any;
    perPageData = this.global.perPageData;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;
    protected extraCta = {
        visibility: true,
        text: 'Add Question',
    };
    urlData = '';
    isLoading = true;
    hasFirstDataShown = false;
    enableInfoMsg = false;

    cstObservableData: Observable<any>;
    constructor(public paginationService: CovidQuestionPaginationService,
        private changeDetectorRefs: ChangeDetectorRef,
        private route: ActivatedRoute, public dialog: MatDialog, private router: Router, private global: Globals,
        public snackBar: MatSnackBar) {
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.urlData = this.route.snapshot.queryParams.center;
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {
            if (data && data.length > 0) {
                data.forEach(value => this.updateRow(value));
                this.dataSource.data = data;
                this.hasFirstDataShown = true;
            } else {
                    this.hasFirstDataShown = false;
                    this.enableInfoMsg = false;
                    setTimeout(()=>{
                        this.hasFirstDataShown = true;
                        this.enableInfoMsg = true;
                    },6000)

                // if (this.hasFirstDataShown) {
                //     this.openSnackBar('No data available', 'OK');
                // }
            }
            this.isLoading = false;
        });
    }


    updateRow(row): any {
        row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
        row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    nextPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.next();
    }


    prevPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.prev();
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getColumn(): any[] {
        return [
            { columnDef: 'Sr', header: 'Sr.', cell: (element: CovidQuestion, index: number) => `${this.page * 10 + index + 1}` },
            { columnDef: 'Title', header: 'Question', cell: (element: CovidQuestion, index: number) => `${element.title}` },
            { columnDef: 'SubQuestion', header: 'SubQuestion', cell: (element: CovidQuestion, index: number) => `${element.title}` },
            { columnDef: 'Order', header: 'Order', cell: (element: CovidQuestion, index: number) => `${element.order}` },
            { columnDef: 'Risk', header: 'Risk', cell: (element: CovidQuestion, index: number) => `${element.risk}` },
            { columnDef: 'type', header: 'Type', cell: (element: CovidQuestion, index: number) => `${element.type}` },
            { columnDef: 'Edit', header: 'Edit', cell: (element: CovidQuestion, index: number) => `${element.id}` },
            { columnDef: 'Action', header: 'Action', cell: (element: CovidQuestion, index: number) => `${element.id}` },

        ];
    }

    getTableName(): string {
        return 'patientQuestion';
    }

    getTitleMatIcon(): string {
        return 'label';
    }

    getTitle(): string {
        return 'Screening Questions';
    }

    getOrderByColumn(): string {
        return 'order';
    }


    openDetail(row) {
        window.open(row.link, '_blank');
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    deleteConsent(docId) {
        this.paginationService.deleteConsentById(docId).then(result => {
            const msg = 'Consent Deleted Successfully';
            this.openSnackBar(msg, 'OK');
        }).catch((error) => {
            this.handleError(error);
        });
    }

    editQuest(docId) {
        this.router.navigate(['/editcovidquestion'], { queryParams: { token: docId } });
    }

    openDialogWithRefDel(ref: TemplateRef<any>): void {



        this.dialog.open(ref, { width: '400px' });


        // this.router.navigate(['/viewtool']);
    }

}
