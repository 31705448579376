import {AfterViewInit, Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {Cell} from '../cell';
import {CellService} from '../cell.service';
import {Asset} from '../../asset/asset';
import {BaseCellComponent} from '../basecell/basecell.component';

const util = require('util');

@Component({
    selector: 'cell-incubator',
    templateUrl: './incubator.component.html',
    styles: [':host {display: block; border: solid 1px red; width: 100%;height: 100%}']
})
export class IncubatorComponent extends BaseCellComponent{
    @Input() cell: Cell;
    path: string;

    constructor(private cellService: CellService) {
        super('IncubatorComponent');
    }


}
