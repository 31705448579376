
import {DialogConfirmationComponent} from '../module/common/dialog-confirmation/dialog-confirmation.component';
import {MatDialog} from '@angular/material/dialog';


export interface IDialogConfirmation {
  onConfirm();
  onCancel();
  onAlways();
}

export class DialogUtil {
  public openConfirmationDialog(dialog: MatDialog, message: string, callBack: IDialogConfirmation): void {

    const dialogRef = dialog.open(DialogConfirmationComponent, {
      width: '500px',
      data: { ok: 0, message: message, hasCancel: true},
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.ok === 1) {
        callBack.onConfirm();
      } else {
        callBack.onCancel();
      }
      callBack.onAlways();
    });
  }

    public openAlertDialog(dialog: MatDialog, message: string, callBack: IDialogConfirmation): void {

        const dialogRef = dialog.open(DialogConfirmationComponent, {
            width: '500px',
            data: { ok: 0, message: message, hasCancel: false},
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.ok === 1) {
                callBack.onConfirm();
            } else {
                callBack.onCancel();
            }
            callBack.onAlways();
        });
    }

}
