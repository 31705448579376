export const locale = {
    lang: 'fr',
    data: {
        'NAV': {

            'APPLICATIONS': 'Programlar',
            'Home': 'Dashboard',
            'Report': 'Report',
            'Checkout': 'Checkout',
            'Labels': 'Labels',
            'Alert': 'Alert',
            'Actions': 'Actions',
            'Inventory': 'Inventory Management',
            'SAMPLE': {
                'TITLE': 'Örnek',
                'BADGE': '15'
            },
            'DASHBOARD': {
                'TITLE': 'DASHBOARD',
                'BADGE': '25'
            }
        }
    }
};
