import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import { AngularFireAuth } from '@angular/fire/auth';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../common/service/authentication.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
})
export class MaintenanceComponent implements OnInit {
    EmailVerification:any;
    noLabAssigned = '';
    resend = '';
    checkValBtn: any;
    recover = '';
    registraionMessage: any;
  constructor(private _fuseConfigService: FuseConfigService, public afAuth: AngularFireAuth,
              public router: Router, public authService: AuthenticationService) {
      // Configure the layout
      this._fuseConfigService.config = {
          layout: {
              navbar: {
                  hidden: true
              },
              toolbar: {
                  hidden: true
              },
              footer: {
                  hidden: true
              },
              sidepanel: {
                  hidden: true
              }
          }
      };
  }

  ngOnInit() {

    this.checkValBtn = localStorage.getItem('resendBtn');

    //this.EmailVerification = localStorage.getCookie('EmailVerification');
    this.authService.getValidationMessage().then(result=>{
        console.log(result.registrationHeader);
        this.EmailVerification=result.registrationHeader;
        this.registraionMessage=result.registrationMessage;
        })

    this.resend = localStorage.getItem('resendBtn');



      this.recover = localStorage.getItem('recover');
  }

    SendVerificationMail(){
        window.alert('Please validate your email address. Kindly check your inbox.');
        this.authService.reSendVerificationMail();
        }
    redirectLogin(){

        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    }


