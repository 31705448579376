import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {LoginComponent} from 'app/main/module/auth/login/login.component';
import {SharedModule} from '../../common/shared.module';
import {ConfigService} from "../../common/config/config.service";
import {FirebaseUtilService} from "../../common/service/FirebaseUtil.service";


const routes = [
    {
        path     : 'login',
        component: LoginComponent
    }
];


@NgModule({
    declarations: [
        LoginComponent
    ],
    providers: [ConfigService, FirebaseUtilService],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        FuseSharedModule,
       // NgxSpinnerModule
    ]
})
export class LoginModule
{

}