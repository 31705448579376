import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StorageService } from 'app/main/module/common/service/storage.service';
import {BaseListService} from "../../../base/base-list.service";

@Injectable({ providedIn: 'root' })

export class HubwizeService extends BaseListService {

    protected isOrderByNegative = true;
    constructor(private afsChild: AngularFirestore, private userServiceChild: StorageService) {
        super(afsChild, userServiceChild);
        this.table = 'barcode';
        this.orderBy = 'date';
    }
    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }

    getFirstQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .limit(this.ROWS));
    }

    getNextQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .startAfter(this.latestEntry)
            .limit(this.ROWS));
    }

    getPrevQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            .limit(this.ROWS));

    }

    getCollectionPath(): string {
        return this.table;
    }

    getSearchQueryWithOutText(): any {
    }

    getSearchQueryWithText(): any {
    }
}
