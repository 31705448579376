import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {CovidService} from '../../../covid.service';
import {Globals} from '../../../../common/global';
import * as moment from 'moment';
import { FirebaseUtilService} from '../../../../common/service/FirebaseUtil.service';
const timeZone = require('moment-timezone');
import { StorageService} from '../../../../common/service/storage.service';
import { ApiService} from '../../../../common/service/api.service';
import {Location} from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';


@Component({
    selector: 'app-covidresultdetail',
    templateUrl: './screening-patient-result-detail.component.html',
    styleUrls: ['./screening-patient-result-detail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ScreeningPatientResultDetailComponent implements OnInit {

    urlPatientId = '';
    urlLabName = '';
    viewData: any = {};
    feverish = '';
    breathing = '';
    flu = '';
    heartdisease = '';
    covidContact = '';
    cough = '';
    taste = '';
    questArr: Array<any> = [];
    fullName = '';
    inOfficeStatus: boolean;
    firstName = '';
    lastName = '';
    email = '';
    phone: number;
    firstNameOpt: any;
    lastNameOpt: any;
    disablePre: any;
    temperature = '';
    token = '';
    queryParam = {};
    inOfficeTime = '';
    preOfficeTime = '';
    addedOn: any;
    patientId = '';
    inOfficeTemperature = '';
    covidoptional = '';
    patientSign = '';
    staffSign = '';
    staffName = '';
    unit = '';
    unitInOffice = '';
    age: any;
    relationship = '';
    innerHtml = '';
    innerHtml1 = '';
    spo = '';
    dob: any;
    doa: any;
    appointTime = '';
    consentUrl= '';
    pdfUrl= '';
    searchTxt: any;
    //dataUrl: any;
 //dataUrl = this.covidService.getDataUrl();  
    constructor(public covidService: CovidService,
        private route: ActivatedRoute, private router: Router, public global: Globals,
                private firebaseUtilService: FirebaseUtilService, public storageService: StorageService,
                public apiService: ApiService,private location: Location) {
    }

    ngOnInit(): void {

       this.token = this.route.snapshot.queryParams.token;
        //console.log(this.token);
        if (this.token) {
            const keyVal = this.global.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }
        sessionStorage.setItem('token', this.token);
        this.route.queryParams.subscribe(params => {
            const token = params['token'];
            //console.log(token);
          });
        // if (this.queryParam['searchTxt']) {
        //     this.searchTxt = this.queryParam['searchTxt'];
        //     sessionStorage.setItem('searchPat', this.searchTxt);
        // } else {
        //     sessionStorage.setItem('searchPat', '');
        // }

        this.urlPatientId = this.queryParam['patient'];
        console.log( this.urlPatientId );
        this.urlLabName = this.queryParam['labName'];
        this.covidService.getCovidResultById(this.urlPatientId).then(viewData => {
            this.viewData = viewData;
            console.log('view data=',this.viewData);
            //this.fullName = this.viewData.urlPatientId + ' ' + this.viewData.firstName + ' ' + this.viewData.lastName;
            this.fullName = this.viewData.firstName + ' ' + this.viewData.lastName;
            this.firstName = this.viewData.firstName;
            this.lastName = this.viewData.lastName;
            this.inOfficeStatus = this.viewData.inOffice;
            this.email = this.viewData.email;
            this.phone = this.viewData.phone;
            this.patientId = this.viewData.patientId;
            // this.addedOn = Math.abs(this.viewData.addedOn);
            this.addedOn= this.apiService.getDateStringMomentByTz(Math.abs(this.viewData.addedOn));
            this.covidoptional = this.viewData.covidoptional;
            console.log('covidOptional=',this.covidoptional);
            console.log('Inoffice ViewData=',this.viewData.inOfficeTime);
            if (this.viewData.inOfficeTime) {
                // this.inOfficeTime = this.viewData.inOfficeTime;
                this.inOfficeTime = this.apiService.getDateStringMomentByTz(this.viewData.inOfficeTime);

            }
            console.log('pre ViewData=',this.viewData.preOfficeTime);
            if (this.viewData.preOfficeTime) {
                // this.preOfficeTime = this.viewData.preOfficeTime;
                this.preOfficeTime = this.apiService.getDateStringMomentByTz(this.viewData.preOfficeTime);
                console.log(this.preOfficeTime);
            }
            // this.inOfficeTime = this.viewData.inOfficeTime?this.viewData.inOfficeTime: '';
            // this.preOfficeTime = this.viewData.preOfficeTime ? this.viewData.preOfficeTime:'N/A';

            // this.temperature = this.viewData.temperature ? this.viewData.temperature : 'N/A';
            if (this.viewData.temperature) {
                this.temperature = this.viewData.temperature;
               // console.log(this.temperature);
                this.unit = this.viewData.unit;
               // console.log(this.unit);
                this.innerHtml = '&#176;';
                //console.log(this.innerHtml);

            }
            if (this.viewData.inOfficeTemperature) {
                this.inOfficeTemperature = this.viewData.inOfficeTemperature;
                this.unitInOffice = this.viewData.unitInOffice;
                this.innerHtml1 = '&#176;';

            }

            if (this.viewData.login && this.viewData.login !== undefined) {
                this.disablePre = true;
            }

            if (this.covidoptional === 'y') {
                this.firstNameOpt = this.viewData.firstNameOpt;
                this.lastNameOpt = this.viewData.lastNameOpt;
                this.relationship = this.viewData.relationship;
            }

            if (this.viewData.patientSign) {
                this.patientSign = this.viewData.patientSign;
            }
            else{
                this.patientSign = localStorage.getItem('signatureImagePat');
                console.log('PatientSign',this.patientSign)
            }
            if (this.viewData.staffSign) {
                this.staffSign = this.viewData.staffSign;
            }
            if (this.viewData.staffName) {
                this.staffName = this.viewData.staffName;
            }
            // if(this.viewData.unit){
            //     this.unit = this.viewData.unit;
            // }
            // if(this.viewData.unitInOffice){
            //     this.unitInOffice = this.viewData.unitInOffice;
            // }
            if (this.viewData.ageStamp) {
                if (Math.abs(this.viewData.ageStamp) > 105) {
                    this.age = this.calculateAge(this.viewData.ageStamp);
                    this.dob = moment(this.viewData.ageStamp).format('DD/MM/YYYY');
                } else {
                    this.age = this.viewData.ageStamp;
                    this.dob = '';
                }
            }

            if (this.viewData.spo) {
                this.spo = this.viewData.spo;

            }
            if (this.viewData.doa) {
                this.doa = this.apiService.getDateStringMomentByTz(this.viewData.doa);
                // this.doa = this.viewData.doa;
            }
            if (this.viewData.appointTime) {
                this.appointTime = this.viewData.appointTime;
            }

            this.pdfUrl = this.viewData.pdfUrl;
            //console.log(this.pdfUrl);
            this.consentUrl = this.viewData.consentUrl;
            // fetching only value from object
            const values: Array<any> = Object.values(this.viewData);
            for (const value of values) {
                if (value.question) {
                   // console.log(value.question);
                    this.questArr.push({
                        question: value.question,
                        questionNo: value.questionNo,
                        questionItems: value.questionItems,
                        inOfficeSelection: value.inOfficeSelection,
                        preAppointSelection: value.preAppointSelection,
                        questionVisibility : value.questionVisibility
                    });
                }
            }

            const sorted = this.questArr.sort((t1, t2) => {
                const name1 = t1.questionNo;
                const name2 = t2.questionNo;
                if (name1 > name2) { return 1; }
                if (name1 < name2) { return -1; }
                return 0;
            });

            this.questArr = sorted;
        }); 

    }
    back(): void {
        this.location.back()
      }
      
    goBack() {
        window.history.back();
   
       }
    // back(): void {
    //     this.router.navigate('')
    //   }
    

    calculateAge(dob) {
        if (dob) {
            const diffMs = Date.now() - dob;
            const ageDt = new Date(diffMs);
            return Math.abs(ageDt.getUTCFullYear() - 1970);
        }

    }

}
