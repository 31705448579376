export class CovidSetting {
    patientEmailFrom: string;
    patientEmailSubject: string;
    patientEmailBody: string;

    patientEmailAlertFrom: string;
    patientEmailAlertSubject: string;
    patientEmailAlertBody: string;
    patientEmailAlertOfficeBody: string;

    enableStaffEmail:string;
    enablePatientEmail:string;
    enableLabCheckoutEmail:string;
    selectedhours:string;

    staffEmailFrom: string;
    staffEmailSubject: string;
    staffEmailBody: string;
    staffMailDaysArray: any;

    /**
     * Constructor
     *
     * @param product
     */
    constructor(product?) {
        product = product || {};

        this.enableStaffEmail = product.enableStaffEmail || '';
        this.enablePatientEmail = product.enablePatientEmail || '';
        this.enableLabCheckoutEmail = product.enableLabCheckoutEmail || '';
        this.selectedhours= product.selectedhours || '';

        this.patientEmailFrom = product.patientEmailFrom || '';
        this.patientEmailSubject = product.patientEmailSubject || '';
        this.patientEmailBody = product.patientEmailBody || '';

        this.patientEmailAlertFrom = product.patientEmailAlertFrom || '';
        this.patientEmailAlertSubject = product.patientEmailAlertSubject || '';
        this.patientEmailAlertBody = product.patientEmailAlertBody || '';
        this.patientEmailAlertOfficeBody = product.patientEmailAlertOfficeBody || '';


        this.staffEmailFrom = product.staffEmailFrom || '';
        this.staffEmailSubject = product.staffEmailSubject || '';
        this.staffEmailBody = product.staffEmailBody || '';
        this.staffMailDaysArray = product.staffMailDaysArray || [];


    }

    toString(): string {
        return

        this.staffEmailFrom +
        this.staffEmailSubject +
        this.staffEmailBody +

        this.patientEmailFrom +
        this.patientEmailSubject +
        this.patientEmailBody


    }
}
