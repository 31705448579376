import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {LabelComponent} from './label.component';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';
import {LabelService} from './label.service';
import { LablelhistoryComponent } from './labelhistory/lablelhistory.component';
import { LabelbarcodeComponent } from './labelbarcode/labelbarcode.component';
import { LabelprefixComponent } from './labelprefix/labelprefix.component';
import {HubwizeService} from "./hubwize/hubwize.service";
import {HubwizeComponent} from "./hubwize/hubwize.component";
import { LabeldetailComponent } from './labeldetail/labeldetail.component';
import { LabelresultpopupComponent } from './labelresultpopup/labelresultpopup.component';

const routes = [
    {
        path     : 'label',
        component: LabelComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'hubWize',
        component: HubwizeComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'labelhistory',
        component: LablelhistoryComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'labelprefix',
        component: LabelprefixComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'labelbarcode',
        component: LabelbarcodeComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'labeldetail',
        component: LabeldetailComponent,
        canActivate: [AuthGuard],
    },

];

@NgModule({
    declarations: [
        LabelComponent,
        HubwizeComponent,
        LablelhistoryComponent,
        LabelbarcodeComponent,
        LabelprefixComponent,
        LabeldetailComponent,
        LabelresultpopupComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        SharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
       // NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,

    ],
    exports     : [
        LabelComponent,
        HubwizeComponent,
        LablelhistoryComponent,
        LabelbarcodeComponent,
        LabelprefixComponent,
        LabeldetailComponent,

    ],
    providers   : [
        LabelService,
        HubwizeService
],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})

export class LabelModule
{
}
