import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { AfterViewInit, Component, TemplateRef } from '@angular/core';
import { CellService } from './cell/cell.service';
import { Observable } from 'rxjs';
import { Cell } from './cell/cell';
import { Asset } from './asset/asset';
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
// import * as util from 'util'; // has no default export
// import { inspect } from 'util'; // or directly

const util = require('util');



export interface Tile {
    color: string;
    cols: number;
    rows: number;
    text: string;

}

export interface PeriodicElement {
    name: string;
    updatedBy: string;
    updatedOn: string;
    // checkOption: symbol;
}

const ELEMENT_DATA = [
    { name: 'Ensure that remaining solied cassettes are washed', updatedBy: 'Nisha', updatedOn: '27/02/2020 | 12:20' },
    { name: 'Ensure Autoclave water containers are filled up', updatedBy: 'Nisha', updatedOn: '27/02/2020 | 12:20' },
    { name: 'Make sure Stericenter is clean', updatedBy: 'Nisha', updatedOn: '27/02/2020 | 12:21' },
    { name: 'Ensure all equipments are shut off', updatedBy: 'Nisha', updatedOn: '27/02/2020 | 12:21' },
    // {name: 'Hydrogensdsa', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogenadad', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogensdsa', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogenadad', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogensdsa', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogenadad', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogensdsa', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogenadad', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogensdsa', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogenadad', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogensdsa', updatedBy: 'Nisha', updatedOn: 25-2-2020},
    // {name: 'Hydrogenadad', updatedBy: 'Nisha', updatedOn: 25-2-2020},
];

@Component({
    selector: 'anms-dashimage',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements AfterViewInit {
    todayDate: Date = new Date();
    itemsObservable: Observable<Cell[]>;
    cells: Cell[];
    dataSource: MatTableDataSource<{ name: string; updatedBy: string; updatedOn: string }>;

    displayedColumns = ['name', 'updatedBy', 'updatedOn'];
    column: any;
    private dialogRef: any;

    // constructor(
    //     private _fuseTranslationLoaderService: FuseTranslationLoaderService, public analyticService: AnalyticsService,
    //     formBuilder: FormBuilder
    // ) {}

    name: string;
    updatedBy: string;
    updatedOn: number;
    constructor(private cellService: CellService, public dialog: MatDialog, private formBuilder: FormBuilder) {
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.cellService.first();
        this.itemsObservable = this.cellService.itemsObservable;
        this.itemsObservable.subscribe(data => {
            data = data.sort((obj1, obj2) => {
                return ((obj1.x - obj2.x) !== 0) ? (obj1.x - obj2.x) : (obj1.y - obj2.y);
            });
            data.forEach(value => {
                //dp something 
            });

            this.cells = data;
        });
    }
    getColumn(): any[] {
        return [

            { columnDef: 'name', header: 'Task', cell: (element: DashboardComponent, index: string) => `${element.name}` },
            { columnDef: 'updatedBy', header: 'Updated By', cell: (element: DashboardComponent, index: string) => `${element.updatedBy}` },
            { columnDef: 'updatedOn', header: 'Time', cell: (element: DashboardComponent, index: string) => `${element.updatedOn}` },
        ];
    }

    ngOnInit() {

        this.fetchSodData();

    }
    ngAfterViewInit(): void {

    }

    getImage(type: string, id: string): Observable<Asset> {
        return this.cellService.getBackgroundImage(type, id);
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.dialog.open(ref, {
            width: '100%'
        });
    }


    fetchSodData() {

        //const date = {{todayDate | date:'dd-MM-yyyy'}};
        const newdate = '19-02-2020';
        const resultData = this.cellService.sodDetails(newdate).subscribe(data => {
            const data1 = data.data();

            data1.sod.map(data => {
                const obj = {
                    name: undefined,
                    updatedBy: undefined,
                    updatedOn: undefined
                };

                obj.name = data.name;
                obj.updatedBy = data.updatedBy;
                obj.updatedOn = data.updatedOn;
                ELEMENT_DATA.push(obj);

            })
            this.dataSource = new MatTableDataSource(ELEMENT_DATA);

        })

    }

}

