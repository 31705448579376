import {
    AfterViewInit, ApplicationRef,
    ChangeDetectorRef,
    Component,
    ElementRef, HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import {StorageService} from '../../../common/service/storage.service';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {StatusService} from '../../status/status.service';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfigService} from "../../../common/config/config.service";
import {Globals} from "../../../common/global";
import {BaseComponent} from "../../../base/base.component";
import {Observable, Subscription} from "rxjs";
import {ContentObserver} from "@angular/cdk/observers";
import {MatDialog} from "@angular/material/dialog";
import {EmaildDetailComponent} from "../../email/viewemaildetails/vieweEmailDetails.component";
import {LoadInfiniteListService} from "./load-infinite-list.service";
import {LoadListColumns} from "./columns";
import {TextUtils} from "../../../../util/TextUtils";
import {LoadService} from "../load.service";
import {DialogUtil} from "../../../../util/DialogUtil";

const timeZone = require('moment-timezone');


@Component({
    selector: 'load-table',
    templateUrl: 'load-list.component.html',
    styleUrls: ['load.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LoadlistComponent extends BaseComponent implements OnDestroy, AfterViewInit, OnInit {
    protected searchText: string;
    hasSearchedText = false;
    public dataCount:number;
    searchTxt = '';
    isLoading = false;
    dataSource: MatTableDataSource<Element>;
    isInitComplete = false;
    isMoreDataAvailable = true;
    limit = 10;
    perPageData = this.globals.perPageData;
    columns = this.getColumn();
    displayedColumns = this.columns.map(c => c.columnDef);
    isEnterPressed = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    static isComeBack = false;


    @ViewChild('filter', {static: true})
    filter: ElementRef;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any




    @ViewChild('table')
    myTable: MatTable<any>; /*not exatly sure what the type should be set too */
    loadSearchBarcode: Array<any>;
    searchData: string;

    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: LoadInfiniteListService,
        public loadListService: LoadService,
        private changeDetectorRefs: ChangeDetectorRef,
        private apprRefs: ApplicationRef,

        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals,
        private readonly contentObserver: ContentObserver,
        private dialog: MatDialog) {
        super(configService, globals);
    }

    ngOnInit(): void {
        this.initData();
    }
    ngAfterViewInitViaBase() {
        super.ngAfterViewInitViaBase();
    }


    getColumn(): any[] {

        return LoadListColumns(0);
    }

    updateRow(row): any {
        if(row) {
            row.date = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
            row.time = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
        }
    }

    viewLOad(barcode) {
        this.router.navigate(['/loaddetail'], { queryParams: { barcode: barcode } });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    openDetail(Url) {
        window.open(Url, "_blank");
    }

    initData() {
        if(LoadlistComponent.oldData && LoadlistComponent.oldData.length > 0){
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck()
            this.changeDetectorRefs.detectChanges()
            this.dataN = LoadlistComponent.oldData;
            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            this.isInitComplete = true;
            this.isLoading = false;
            this.initWithOldData()
            return
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.paginationService.init('load', this.limit, 'startTimeNum', {reverse: false, prepend: false})

        this.subscription = this.paginationService.data.subscribe(data => {
            if(data){} else return
                        this.dataN = data;
            if (this.dataSource && this.dataN && this.dataSource.data.length == this.dataN.length) {
                this.isMoreDataAvailable = false;
            }
            try{
                data.forEach(value => this.updateRow(value));
            } catch (e){}

            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if(this.dataN && this.dataN.length > 0){
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000)
            } else {

            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 200)
        });
    }

    initWithOldData(){
        this.paginationService.initWithPrev(LoadlistComponent.cursor, LoadlistComponent.oldData,
            'load', this.limit, 'startTimeNum', {reverse: false, prepend: false})

        this.subscription = this.paginationService.data.subscribe(data => {
            if(data){} else return

            this.dataN = data;
            if (this.dataSource && this.dataN &&
                (this.dataSource.data.length - LoadlistComponent.oldData.length)  == this.dataN.length) {
                console.log('isMoreDataAvailable' + 'this.dataSource.data.length'  +this.dataSource.data.length
                + 'this.dataN.length'  +this.dataN.length)
                // this.isMoreDataAvailable = false;
            }
            data.forEach(value => this.updateRow(value));
            this.dataSource = new MatTableDataSource(this.dataN);

            this.dataSource.data = LoadlistComponent.oldData.concat(this.dataN);
            if(this.dataSource.data && this.dataSource.data.length > 0){
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000)
            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 1000)
        });
        setTimeout(() => {
            this.scrollToBottom();
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck()
            this.changeDetectorRefs.detectChanges()
        }, 1000)
    }

    ngOnChanges() {
        console.log('ngOnChanges'); 
        this.perPageData = 1;// sets page to 1
        this.dataN = [];   
                                       // reset array that populate paging
                  // initiate search request to API
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector('mat-table');
            table.scrollBy({behavior: 'smooth', left: 0, top: table.scrollHeight});
        }

    }

    tableContentChanged() {
        const table = document.querySelector('mat-table');
        table.scrollBy(0, table.scrollHeight);
    }

    ngAfterViewInit(): void {}


    ngOnDestroy(): void {
        LoadlistComponent.isComeBack = true
        console.log('ngOnDestroy length' + this.dataN.length)
        this.subscription.unsubscribe();
        LoadlistComponent.oldData = this.dataSource.data
        LoadlistComponent.cursor = this.paginationService.getCursor()
    }

    hasMore = () => {
        return !this.dataSource || this.dataSource.data.length < this.limit;
    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.paginationService.more();
    }

    isDataAvailable() {
        if (this.dataSource && this.dataSource.data.length > 0) {
            return true;
        }
        return false;
    }

    isMoreDataAvailableFun() {
        if (this.isMoreDataAvailable && this.dataSource.data.length % this.limit === 0) {
            return true;
        }
        return false;
    }

    public onClickSearch() {

        if(this.searchText && this.searchText.length > 0){
            this.loadListService.getLoadById(this.searchText).subscribe(resultData => {
                // console.log('Result Search=',resultData);
            //    localStorage.setItem('searchResult','');
            //    this.searchData=''
                this.loadSearchBarcode=resultData;
                console.log(this.loadSearchBarcode)
                 localStorage.setItem('searchResult', JSON.stringify(this.loadSearchBarcode));
                if(resultData && resultData.length >0){
                    this.router.navigate(['/loaddetail'], { queryParams: { barcode: this.searchText } });
                } else {
                    this.showAlert(`Load not found with barcode "` + this.searchText + `"`);
                }
            });
        }
    }
    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => {
            },
            onCancel: () => {
            },
            onAlways: () => {

            }
        });
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.searchText = '';
    }

    refreshSearch(event) {
        // if (event.code === 'Backspace' && this.searchText.length === 1) {
        //     this.searchText = '';
        //     this.onClickSearch();
        // }
    }


    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }


    handleEnterSearch(event: KeyboardEvent) {
        if (event.key.includes('Enter')
            && event.code.includes('Enter')
            && document.activeElement.id.includes('search')) {
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch();
            }
        } else {
            this.isEnterPressed = false;
        }
    }
}