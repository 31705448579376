import { AfterViewInit, Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FuseConfigService } from '@fuse/services/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CovidService } from '../../../covid.service';
import { Globals } from '../../../../common/global';
import { StorageService } from 'app/main/module/common/service/storage.service';


@Component({
    selector: 'self-assessment-resolution',
    templateUrl: './self-assessment-resolution.component.html',
    styleUrls: ['./self-assessment-resolution.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:orientationchange)': 'isMobileLandscapeFunction()'
    }
})
export class SelfAssessmentResolutionComponent implements AfterViewInit, OnInit, OnDestroy {

    urlData = '';
    cassetteData: any;
    barcode = '';
    cycleNo = '';
    name = '';
    id = '';
    time = '';
    type = '';
    program = '';
    loadNo: number;
    machineName = '';
    status = '';
    result = ' ';
    barcodeBi: any;
    barcodeEi: any;
    barcodeBd: any;
    isAssignDone: boolean;
    statusBi: string;
    statusEi: string;
    statusBd: string;
    date = new Date();
    patientName = '';
    patientId = '';
    assignedBy = '';
    assignedOn: any;
    status1 = '';
    covidresult = '';
    urlLabName = '';
    logoUrl = '';
    labWebSiteUrl = '';
    isLoading: boolean;
    extraNote = '';
    emergencyCall = '';
    token = '';
    queryParam = {};
    isMobileLandscapeModeActivated = false;


    constructor(public covidService: CovidService,
        private route: ActivatedRoute, private router: Router,
        private userService: StorageService,
        private snackBar: MatSnackBar, private _fuseConfigService: FuseConfigService,
        private globals: Globals) {

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.isLoading = true;
    }


    ngOnInit(): void {
        // this.urlData = this.route.snapshot.queryParams.barcode;
        this.urlLabName = this.userService.getCookie("lab");
        this.covidresult = localStorage.getItem('covidresult');
        this.extraNote = localStorage.getItem('extraNote');

        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.globals.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }

        const collectionPath = `/lab/${this.queryParam['labName']}/config/`;
        const logoPath = this.covidService.getLabLogo(collectionPath);
        logoPath.ref.get().then(data => {

            const logoPathName = data.data();

            this.logoUrl = logoPathName['logoUrl'] ? logoPathName['logoUrl'] : this.globals.siteLogo;

            this.labWebSiteUrl = logoPathName['websiteUrl'];
        });


        this.covidService.getPhoneNumber(this.queryParam['labName']).then(async phone => {
            this.emergencyCall = phone.phoneNumber;
        })

    }

    ngAfterViewInit(): void {
        if (this.urlData !== '' && this.urlData !== undefined) {
            //dp something
        } else {

            // window.alert('No Data found, try again!');
        }
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    startAssessment() {
        this.router.navigate(['/covid-assessment-start'], { queryParams: { barcode: null } });
    }

    gotoWebsite() {
        // dentistry website
        this.router.navigate(['/covid-assessment-start'], { queryParams: {} });
    }

    windowClose() {
        window.close();
        // window.top.close();
        // window.open('','_self').close();
    }

    visitLab() {
        if (this.userService.getCookie("lab")) {
            this.router.navigate(['/covid']);
        } else {
            if (this.labWebSiteUrl) {
                window.open(this.labWebSiteUrl, 'blank');
            } else {
                alert('Please ask sterilwize team to add your website URL');
                window.open('', 'blank');
            }
        }
    }

    hideLoader() {
        this.isLoading = false;
    }

    isMobileLandscapeFunction() {

        if (screen.orientation && screen.orientation.type && (screen.orientation.angle === 90 || screen.orientation.angle === -90) && this.isMobile() && screen.width < 820) {
            // alert('Kindly Change the landscape orientation. This app is not compatible for mobile landscape mode');
            this.isMobileLandscapeModeActivated = true;

        }
        else {
            this.isMobileLandscapeModeActivated = false;
        }
    }
    isMobile() {
        const mobileCheck = {
            Android: () => navigator.userAgent.match(/Android/i),
            BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
            iOS: () => navigator.userAgent.match(/iPhone/i),
            Opera: () => navigator.userAgent.match(/Opera Mini/i),
            Windows: () => navigator.userAgent.match(/IEMobile/i),
            detectmob: () => window.innerWidth <= 799,
            any: () => mobileCheck.Android() || mobileCheck.BlackBerry() || mobileCheck.iOS() || mobileCheck.Opera() || mobileCheck.Windows() || mobileCheck.detectmob(),
        };
        return mobileCheck.any() ? true : false;
    };
    ngOnDestroy(): void {
        localStorage.setItem('extraNote', '');
    }

}