import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseListService } from '../../base/base-list.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import {reject} from 'lodash-es';

@Injectable({ providedIn: 'root' })

export class LabelhistoryService extends BaseListService {

    protected isOrderByNegative = true;
    constructor(private afsChild: AngularFirestore, private userServiceChild: StorageService) {
        super(afsChild, userServiceChild);
        this.table = 'dailyLabelStatus';
        this.orderBy = 'time';
        // this.ROWS = 30;
    }
    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }

    getFirstQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .limit(this.ROWS));
    }

    getNextQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .startAfter(this.latestEntry)
            .limit(this.ROWS));
    }

    getPrevQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            .limit(this.ROWS));

    }

    getCollectionPath(): string {
        return 'lab/' + this.userServiceChild.getCookie('lab') + '/report/chart/' + this.table;
    }

    getSearchQueryWithOutText(): any {
    }

    getSearchQueryWithText(): any {
    }


    getConfigColumnLabel(){
     const configLabelPath= 'lab/' + this.userServiceChild.getCookie('lab') + '/config';
     return new Promise((res, rej )=>{
         this.afsChild.collection(configLabelPath).doc('label').get().subscribe(labelData =>{
             res(labelData.data().prefix);
         })

     })
    }

    getLabelDataBetweenRange(from, to){
        const configLabelPath= 'lab/' + this.userServiceChild.getCookie('lab') + '/report/chart/dailyLabelStatus';
            this.afsChild.collection(configLabelPath, ref=> ref.where('time', '>=', to).where('time', '<=', from))
                .snapshotChanges()
                .pipe(map((actions: any) => {
                    return actions.map((a: any) => {
                        return a.payload.doc.data();
                    });
                }));
    }

}
