import { NgModule } from '@angular/core';

import {PatientScreeningModule} from './patient/patient-screening.module';

import {StaffScreeningModule} from './staff/staff-screening.module';
import {ScreeningSettingModule} from './setting/ScreeningSetting.module';
import {CovidService} from './covid.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {SelfAssessmentQuestionsService} from './patient/self-assessment/questions/self-assessment-questions.service';

@NgModule({
    imports: [
        // Faq
        PatientScreeningModule,
        ScreeningSettingModule,
        StaffScreeningModule
    ],
    providers: [
        CovidService
    ]
})
export class ScreeningModule
{

}
