import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    ValidatorFn, Validators, AbstractControl, Form
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { CovidService } from '../../../covid.service';
import { PatientService } from '../../../../sterilwize/patient/patient.service';
import { Globals } from '../../../../common/global';
import { AngularFireStorage } from '@angular/fire/storage'
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../../../common/service/api.service';
import { FirebaseUtilService } from '../../../../common/service/FirebaseUtil.service';
import { SelfAssessmentQuestionsService } from './self-assessment-questions.service';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { AreaChartStackedComponent } from '@swimlane/ngx-charts';

interface IDNumber {
    id: number;
}



@Component({
    selector: 'self-assessment-questions',
    templateUrl: './self-assessment-questions.component.html',
    styleUrls: ['./self-assessment-questions.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:orientationchange)': 'isMobileLandscapeFunction($event)'
    }
})



export class SelfAssessmentQuestionsComponent implements OnInit, OnDestroy {

    selectedRadio: any;
    selecteCheck: any;

    // allQuestions = JSON.parse(sessionStorage.getItem('questions')) ;
    // allQuestions: any = Questions;

    allQuestions: any;
    questionArray: any[];
    currentQuestionId = 'travelout';
    // question = this.allQuestions[this.currentQuestionId];
    question: any;
    toggleBool = false;
    questionnaireObj: Array<any> = [];
    urlPatientId = '';
    urlLabName = '';
    allQuestionnaireObj = { firstName: '', lastName: '' };
    // allQuestionnaireObj = Object.assign({}, this.allQuestions);
    resultData: any;
    form: FormGroup;
    tempForm: FormGroup;
    tempFormOne: FormGroup;

    action: any;
    emailId = '';
    phone: number;
    firstNameOpt = '';
    lastNameOpt = '';
    fullName = '';
    direct: any;
    token = '';
    selectedValue: Boolean;
    queryParam = {};
    temperature = '';
    // allResults: any = Results;  // results being assign here
    allResults: any
    customArr: Array<any> = [];
    covidresult = '';
    logoUrl = '';
    siteLogo = '';
    signatureImage = '';
    signatureImageUrlStaff = '';
    signatureImagePat = '';
    signatureImageUrlPat = '';
    staffName = '';
    relationship = '';
    unit = 'C';
    // selected = 'C';
    patientFullName = '';
    dob: any;
    docId = '';
    doa: any;
    checkExist: boolean;
    checkExistData: any;
    minT = 0;
    maxT = 0;
    matError = '';
    questArr: Array<any> = [];
    arrTest: Array<any> = [];
    arrTestInoffice: Array<any> = [];
    @Input()
    max: 'D' | null;
    today = new Date();
    isMobileLandscapeModeActivated = false;
    maxLengthTemp = 5;
    dobString = '';
    selctValue: any;
    parents: Array<any> = [];
    answerMap = new Map();
    getSelectValue: any;


    ordersData = [
        { id: 100, name: 'order 1' },
        { id: 200, name: 'order 2' },
        { id: 300, name: 'order 3' },
        { id: 400, name: 'order 4' }
    ];
    elseBlock: any;
    checkdob = parseInt(sessionStorage.getItem('checkdob'), 10);
    private _unsubscribeAll: Subject<any>;
    arrTest2: Array<any> = [];
    constructor(private formBuilder: FormBuilder, private _fuseConfigService: FuseConfigService,
        private route: ActivatedRoute, private router: Router, public covidService: CovidService,
        public patientService: PatientService, public apiService: ApiService,
        public globals: Globals, private storage: AngularFireStorage,
        public firebaseUtil: FirebaseUtilService,
        private userService: StorageService,
        private covidQuestionService: SelfAssessmentQuestionsService) {

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.form = this.formBuilder.group({
            orders: new FormArray([])
        });
        this._unsubscribeAll = new Subject();
        this.addCheckboxes();
    }


    private addCheckboxes() {
        this.ordersData.forEach((o, i) => {
            const control = new FormControl(i === 0); // if first item set to true, else false
            (this.form.controls.orders as FormArray).push(control);
        });
    }

    uploadSignature(fileData) {
        return new Promise((resolve, reject) => {

            const file = fileData.split(',')[1];
            const metadata = {
                contentType: 'image/png',
            };
            const n = Date.now();
            const signatureRef = this.storage.ref(`/signature/${n}.png`);
            signatureRef.putString(file, 'base64', metadata).then(snapshot => {
                if (snapshot) {
                    signatureRef.getDownloadURL().subscribe(url => {
                        resolve(url);

                    });

                }
            }).catch(error => { // dp something
            });

        })
    }


    ngOnInit(): void {

        this.covidQuestionService.onDataChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(questionArray => {
                this.questArr = questionArray.filter((element) => {
                    return element !== undefined;
                });

                // if(this.checkdob >= 70){
                //     this.questionArray = questionArray;
                // }
                // if(this.checkdob < 70){
                //     this.allQuestions = Object.entries(questionArray) // converts each entry to [key, value]
                //         .filter(([k, v]) => v['minAge'] === 0) // define the criteria to include/exclude items
                //         .reduce((acc, [k, v]) => {
                //             acc[k] = v;
                //             return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
                //         }, []);
                //
                //     // this.questionArray = Object.assign({}, this.allQuestions);
                // }
                this.questionArray = this.questArr;
                this.allQuestionnaireObj = this.covidQuestionService.getQuestionObjFromDocuments(this.questArr);
                this.allQuestions = this.allQuestionnaireObj;


                this.question = this.allQuestionnaireObj[this.questArr[0].id];

                console.log(this.questArr);
                console.log("allQuestion" + this.allQuestions);
                console.log("question" + this.question);

            });




        // if(this.checkdob >= 70){
        //
        //     this.allQuestions = QuestionsOverage;
        //
        //     this.allQuestionnaireObj = Object.assign({}, this.allQuestions);
        // }
        // if(this.checkdob < 70){
        //     this.allQuestions = Object.entries(this.allQuestions) // converts each entry to [key, value]
        //         .filter(([k, v]) => k !== 'overage') // define the criteria to include/exclude items
        //         .reduce((acc, [k, v]) => {
        //             acc[k] = v;
        //             return acc; // this function can be improved, it converts the [[k, v]] back to {k: v, k: v, ...}
        //         }, {});
        //
        //     this.allQuestionnaireObj = Object.assign({}, this.allQuestions);
        // }



        this.tempForm = this.formBuilder.group({

            temperature: ['', [Validators.pattern(/^\d*\.?\d*$/),
            (control: AbstractControl) => Validators.max(this.maxT)(control),
            (control: AbstractControl) => Validators.min(this.minT)(control)
            ]],
            unit: ['', ''],

        });
        this.fullName = localStorage.getItem('fullName');
        this.staffName = this.userService.getCookie("staffName");
        this.relationship = localStorage.getItem('relationship');
        localStorage.setItem('extraNote', '');

        this.tempFormOne = this.formBuilder.group({
            covidTestDate: ['', ''],

        });



        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.globals.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }


        // if(this.checkdob >= 70){
        //     this.covidService.getAllQuestionOverageData(this.queryParam['labName']).then( async covidQuest =>{
        //         this.allQuestions = await covidQuest;
        //         this.allQuestionnaireObj = Object.assign({}, this.allQuestions);
        //         this.question = this.allQuestions[this.currentQuestionId];
        //     })
        // }else{
        //     this.covidService.getAllQuestionData(this.queryParam['labName']).then( async covidQuest =>{
        //         this.allQuestions = await covidQuest;
        //         this.allQuestionnaireObj = Object.assign({}, this.allQuestions);
        //         this.question = this.allQuestions[this.currentQuestionId];
        //
        //     })
        //
        // }



        // START getting image url after uploading signature signatureImageUrlStaff
        // this.signatureImage = localStorage.getItem('signatureImage');
        // console.log('this.signature=', this.signatureImage)
        this.signatureImagePat = localStorage.getItem('signatureImagePat');
        console.log('this.signatureImagePat=', this.signatureImagePat)
        // if(this.signatureImage){
        //     this.uploadSignature(this.signatureImage).then((urlData:any) =>{
        //
        //         this.signatureImageUrlStaff = urlData;
        //     })
        //
        // }
        if (this.signatureImagePat) {
            this.uploadSignature(this.signatureImagePat)
                .then((urlData: any) => {
                    this.signatureImageUrlPat = urlData;
                    console.log('signature=',this.signatureImageUrlPat)
                })

        }

        // END getting image url after uploading signature signatureImageUrlStaff


        if (this.queryParam['type']) {
            this.direct = this.queryParam['type'];
        }

        this.action = this.queryParam['key'];
        this.urlPatientId = this.queryParam['patient'];
        this.urlLabName = this.queryParam['labName'];
        this.emailId = this.queryParam['email'];
        this.phone = this.queryParam['phone'];
        this.dob = new Date(this.queryParam['dob']).getTime();
        this.dobString = moment(this.dob, 'DD/MM/YYYY').format('DD/MM/YYYY'),

            console.log(this.queryParam['dob']);
        console.log(this.queryParam['doa']);
        if (this.action === 'add') {
            this.doa = this.queryParam['doa'] ? this.queryParam['doa'] : '';
        }
        // this.temperature = this.tempForm.value.temperature;
        // logo path from global.ts
        this.siteLogo = this.globals.siteLogo;

        this.covidService.getAllResolution(this.urlLabName).then(data => {

            this.allResults = data;



        });


        const collectionPath = `/lab/${this.urlLabName}/config/`;
        const logoPath = this.covidService.getLabLogo(collectionPath);
        logoPath.ref.get().then(data => {
            const logoPathName = data.data();
            this.logoUrl = logoPathName.logoUrl;

        });
        // get patient detail

        this.patientService.getPatientByIdWithoutLogin(this.urlPatientId, this.urlLabName).then(async (resultData: any) => {


            this.resultData = await resultData;


            if (this.resultData === undefined) {
            }
            if (this.action === 'guest') {
                this.patientFullName = this.fullName;
            } else {
                if (this.resultData) {
                    this.patientFullName = this.resultData.firstName + ' ' + this.resultData.lastName;

                }

            }

        });

        if (localStorage.getItem('covidoptional') === 'y') {
            // this.question.question = this.replaceQuestTest(this.question.question);
            this.question.question = this.question.question;
            // updating quest 1 directly
            // this.allQuestions[this.currentQuestionId].question = this.replaceQuestTest(this.question.question);
            this.allQuestions[this.currentQuestionId].question = this.question.question;


        }

        if (this.action === 'edit') {
            this.covidService.getCovidResultByIdWithoutLogin(this.urlPatientId, this.urlLabName).then((editData: any) => {
                this.allQuestionnaireObj = editData;
            });
        }
    }

    getQuestions() {
        this.firebaseUtil.getDocuments(`/lab/${FirebaseUtilService.LAB_PLACE_HOLDER}/covid/constant/patientQuestion`)
            .subscribe(async documents => {


            });
    }

    createQuestionObject() {

    }


    submit() {
        const selectedOrderIds = this.form.value.orders
            .map((v, i) => (v ? this.ordersData[i].id : null))
            .filter(v => v !== null);
    }

    createQuestion(key, selectedVal) {
        console.log('question visibility function calling  222');

        if (selectedVal === 'yes' || selectedVal === 'Yes' || selectedVal === 'no' || selectedVal === 'No') {
            console.log('quesvalue =', selectedVal);
            this.selectedValue = false;

        } else if (selectedVal == '') {
            this.selectedValue = true;
        }
        else {
            console.log('quesvalueElse =', selectedVal);
            this.selectedValue = false;
        }
        switch (this.action) {

            case 'add':
            case 'guest':

                return this.allQuestionnaireObj[key].questionVisibility = this.selectedValue;

                break;
            case 'edit':
            case 'start':

                return this.allQuestionnaireObj[key].questionVisibility = this.selectedValue;

                break;

            default:
                return;
        }

    }

    createObj(key, selectedVal) {

        if (selectedVal === 'yes') {
            selectedVal = true;
            this.getCustomMessage(this.allQuestionnaireObj[key], this.allQuestionnaireObj[key].risk);
        } else {
            selectedVal = false;
        }

        switch (this.action) {

            case 'add':
            case 'guest':
                this.arrTest2.push(this.allQuestionnaireObj[key].preAppointSelection = selectedVal);
                this.arrTest2.push(this.allQuestionnaireObj[key].inOfficeSelection = selectedVal);
                console.log(this.arrTest2);
                return this.arrTest2
                break;
            case 'edit':
            case 'start':
                this.arrTest.push(this.allQuestionnaireObj[key].inOfficeSelection = selectedVal);
                this.arrTest.push(this.allQuestionnaireObj[key].isSelectedYesorNo = selectedVal);
                console.log(this.arrTest);
                return this.arrTest;
                break;

            default:
                return;
        }
    }


    getRiskLevel() {

        var riskLevel = 'Low';
        for (let i = 0; i < this.questionArray.length; i++) {


        }
    }


    onClickButton(cta: string, label: string, questionId: any) {

        this.temperature = this.tempForm.value.temperature;
        if (this.temperature) {
            this.unit = this.tempForm.value.unit;
        } else {
            this.unit = 'C';
        }

        // let questCta ={};

        const id = this.question.id;
        const order = this.question.order;
        const description = this.question.description;
        console.log(description);
        this.createObj(id, label); // function called
        this.createQuestion(id, '');
        this.answerMap.set(order, label);
        console.log(this.answerMap);

        switch (cta) {
            case 'r7':
                // this.allQuestionnaireObj['addedOn'] = (-1) * new Date().getTime();
                if (this.fullName && this.fullName !== undefined) {
                    if (this.fullName.includes('-')) {
                        this.firstNameOpt = this.fullName.split('-')[0];
                        this.lastNameOpt = this.fullName.split('-')[1];

                    } else {
                        this.firstNameOpt = this.fullName;
                        this.lastNameOpt = '';
                    }
                }

                // patient and staff signature
                if (this.action === 'add') {
                    if (this.temperature) {
                        this.allQuestionnaireObj['unit'] = this.unit;
                        this.allQuestionnaireObj['isUnit'] = true; // boolean for checking val
                    } else {
                        this.allQuestionnaireObj['unit'] = '';
                        this.allQuestionnaireObj['isUnit'] = false;
                    }
                    this.allQuestionnaireObj['covidTestDate'] = this.tempFormOne.value.covidTestDate ? moment(this.tempFormOne.value.covidTestDate).toDate().getTime() : '';
                    if (this.allQuestionnaireObj['covidTestDate']) {
                        this.allQuestionnaireObj['covidTest'] = true;
                    }

                    this.allQuestionnaireObj['patientSign'] = localStorage.getItem('signatureImagePat');
                    console.log(this.allQuestionnaireObj['patientSign'])
                } else {
                    this.allQuestionnaireObj['unitInOffice'] = this.unit;
                    this.allQuestionnaireObj['patientSign'] = localStorage.getItem('signatureImagePat');
                }
                // this.allQuestionnaireObj['staffSign'] = this.signatureImageUrlStaff;
                // this.allQuestionnaireObj['patientSign'] = this.signatureImageUrlPat;
                // patient and staff signature


                if (this.action === 'start') {
                    this.allQuestionnaireObj['login'] = this.direct;
                }

                if (this.action !== 'edit') {
                    this.allQuestionnaireObj['temperature'] = this.temperature;
                }
                // staff name added
                if (this.action !== 'add') {
                    this.allQuestionnaireObj['staffName'] = this.staffName;
                }
                // staff name added
                if (this.action === 'add' || this.action === 'edit') {
                    if (this.action === 'add') {
                        if (localStorage.getItem('covidoptional') === 'y') {
                            //    write the code for optional Data
                            this.allQuestionnaireObj['covidoptional'] = 'y';
                            this.allQuestionnaireObj['relationship'] = this.relationship;

                        }

                        this.allQuestionnaireObj['firstNameOpt'] = this.firstNameOpt;
                        this.allQuestionnaireObj['lastNameOpt'] = this.lastNameOpt;

                        this.allQuestionnaireObj['patientId'] = this.urlPatientId;
                        this.allQuestionnaireObj['preOfficeTime'] = new Date().getTime();
                        console.log(this.allQuestionnaireObj['preOfficeTime']);

                        if (this.resultData) {
                            this.allQuestionnaireObj['ageStamp'] = this.dob ? this.dob : '';
                            this.allQuestionnaireObj['patientId'] = this.resultData.id;
                            this.allQuestionnaireObj['firstName'] = this.resultData.firstName;
                            this.allQuestionnaireObj['lastName'] = this.resultData.lastName;
                            this.allQuestionnaireObj['dobString'] = this.dobString;

                            const splitMapFName = this.resultData.firstName.toUpperCase().split(" ");
                            let splitMapF = {};
                            for (let i of splitMapFName) {
                                splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                            }

                            const splitMapLName = this.resultData.lastName.toUpperCase().split(" ");
                            let splitMapL = {};
                            for (let j of splitMapLName) {
                                splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                            }
                            const firstNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.firstName).replace(/\s/g, ''));
                            const lastNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.lastName).replace(/\s/g, ''));
                            const fullNameMap = (this.resultData.firstName + this.resultData.lastName).replace(/\s/g, '').toUpperCase();
                            const idMap = this.getMapValue(this.toUpperCaseString(this.resultData.id));
                            const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap, splitMapF, splitMapL);
                            anyMap[fullNameMap] = true;
                            this.allQuestionnaireObj['firstNameMap'] = firstNameMap;
                            this.allQuestionnaireObj['lastNameMap'] = lastNameMap;
                            this.allQuestionnaireObj['anyMap'] = anyMap;

                            // const idMap = this.getMapValue(this.toUpperCaseString(id));
                            this.allQuestionnaireObj['firstNameMap'] = firstNameMap;
                            this.allQuestionnaireObj['lastNameMap'] = lastNameMap;
                            this.allQuestionnaireObj['anyMap'] = anyMap;
                        }
                        this.allQuestionnaireObj['labName'] = this.urlLabName;
                    }

                } else {
                    // guest Section custom data
                    if (this.resultData) {
                        this.allQuestionnaireObj['firstName'] = this.resultData.firstName;
                        this.allQuestionnaireObj['lastName'] = this.resultData.lastName;
                    }
                    this.allQuestionnaireObj['email'] = this.emailId;
                    this.allQuestionnaireObj['phone'] = this.phone;
                    if (this.resultData) {
                        this.allQuestionnaireObj['patientId'] = this.resultData.id ? this.resultData.id : '';

                        const splitMapFName = this.resultData.firstName.toUpperCase().split(" ");
                        let splitMapF = {};
                        for (let i of splitMapFName) {
                            splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                        }

                        const splitMapLName = this.resultData.lastName.toUpperCase().split(" ");
                        let splitMapL = {};
                        for (let j of splitMapLName) {
                            splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                        }

                        const firstNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.firstName).replace(/\s/g, ''));
                        const lastNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.lastName).replace(/\s/g, ''));
                        const fullNameMap = (this.resultData.firstName + this.resultData.lastName).replace(/\s/g, '').toUpperCase();
                        const idMap = this.getMapValue(this.toUpperCaseString(this.resultData.id));
                        const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap, splitMapF, splitMapL);
                        anyMap[fullNameMap] = true;
                        // const idMap = this.getMapValue(this.toUpperCaseString(id));
                        this.allQuestionnaireObj['firstNameMap'] = firstNameMap;
                        this.allQuestionnaireObj['lastNameMap'] = lastNameMap;
                        this.allQuestionnaireObj['anyMap'] = anyMap;

                    }
                    this.allQuestionnaireObj['firstNameOpt'] = this.firstNameOpt;
                    this.allQuestionnaireObj['lastNameOpt'] = this.lastNameOpt;
                    this.allQuestionnaireObj['labName'] = this.urlLabName;
                    // return;

                }

                if (this.action === 'add') {
                    if (this.direct && this.direct !== undefined) {
                        this.allQuestionnaireObj['login'] = this.direct;
                    }

                    this.allQuestionnaireObj['email'] = this.emailId;
                    this.allQuestionnaireObj['phone'] = this.phone;
                    this.allQuestionnaireObj['inOffice'] = false;
                    this.allQuestionnaireObj['preOffice'] = true;
                    this.allQuestionnaireObj['doa'] = this.queryParam['doa'] ? this.queryParam['doa'] : '';

                    if (this.queryParam['docId']) {
                        this.docId = this.queryParam['docId'];
                        this.covidService.checkCovidResultByIdWithoutLogin(this.queryParam['docId'], this.queryParam['labName']).then(async (checkExist: any) => {
                            // this.checkExist = await checkExist;
                            this.checkExistData = await checkExist;
                            if (this.checkExistData) {
                                this.allQuestionnaireObj['addedOn'] = this.checkExistData.addedOn;
                                this.allQuestionnaireObj['doa'] = this.checkExistData.doa;
                                this.allQuestionnaireObj['appointTime'] = this.checkExistData.appointTime;
                                this.allQuestionnaireObj['doaTimestamp'] = this.checkExistData.doaTimestamp;
                                this.allQuestionnaireObj['pdf'] = {};
                                this.allQuestionnaireObj['optionalDate'] = localStorage.getItem('optionalDate') ? localStorage.getItem('optionalDate') : '';
                                this.allQuestionnaireObj['tokenId'] = checkExist.tokenId;

                                const updateData = this.covidService.updateCovidResultWithoutLogin(this.allQuestionnaireObj, this.docId, this.queryParam['labName']);
                                updateData.then(result => {
                                    const msg = 'Covid screening Data Updated Successfully';
                                    // generate pdf Consent update
                                    // this.apiService.generateConsentPdf({labId:this.queryParam['labName'],docId:this.queryParam['docId']}).then(resultD =>{
                                    //     }
                                    // ).catch(err =>{
                                    //
                                    // })

                                    // this.openSnackBar(msg, 'OK');
                                }).catch((error) => {//dp something

                                    // this.handleError(error);
                                });

                            } else {
                                const setDateData = this.covidService.setCovidResultWithoutLogin(this.allQuestionnaireObj, this.docId, this.queryParam['labName']);
                                setDateData.then(result => {
                                    const msg = 'Covid screening Data  Updated Successfully';
                                    // this.openSnackBar(msg, 'OK');
                                }).catch((error) => {
                                    // this.handleError(error);
                                });
                            }
                        });
                    } else {
                        const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj, this.queryParam['labName']);
                        returnData.then(result => {
                            const msg = 'Covid screening Data  Added Successfully';
                            // this.openSnackBar(msg, 'OK');
                        }).catch((error) => {
                            // this.handleError(error);
                        });
                    }


                    // const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj,this.queryParam['labName']);
                    // returnData.then(result => {
                    //     const msg = 'Covid screening Data  Added Successfully';
                    //     // this.openSnackBar(msg, 'OK');
                    // }).catch((error) => {
                    //     // this.handleError(error);
                    // });

                }
                if (this.action === 'start') {  // 2 cases
                    this.allQuestionnaireObj['ageStamp'] = this.dob ? this.dob : '';
                    this.allQuestionnaireObj['patientId'] = this.urlPatientId;
                    this.allQuestionnaireObj['patientId'] = this.resultData.id ? this.resultData.id : '';
                    this.allQuestionnaireObj['inOffice'] = false;
                    this.allQuestionnaireObj['dobString'] = this.dobString;
                    const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj, this.queryParam['labName']);
                    returnData.then(result => {
                        console.log('result action start=',result);
                        const msg = 'Covid screening Data  Added Successfully';
                        // this.openSnackBar(msg, 'OK');
                    }).catch((error) => {
                        // this.handleError(error);
                    });

                }
                if (this.action === 'guest') { // 1 case

                    this.allQuestionnaireObj['firstName'] = this.firstNameOpt;
                    this.allQuestionnaireObj['lastName'] = this.lastNameOpt;

                    const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj, this.queryParam['labName']);
                    returnData.then(result => {
                        const msg = 'Covid screening Data  Added Successfully';
                        // this.openSnackBar(msg, 'OK');
                    }).catch((error) => {
                        // this.handleError(error);
                    });

                }
                if (this.action === 'edit') { // 1 case

                    this.allQuestionnaireObj['inOfficeTemperature'] = this.temperature;
                    this.allQuestionnaireObj['inOffice'] = true;
                    this.allQuestionnaireObj['inOfficeTime'] = new Date().getTime();
                    const returnData = this.covidService.updateCovidResultWithoutLogin(this.allQuestionnaireObj, this.urlPatientId, this.queryParam['labName']);
                    returnData.then(result => {
                        const msg = 'Covid screening Data  Updated Successfully';
                        // this.openSnackBar(msg, 'OK');
                    }).catch((error) => {
                        // this.handleError(error);
                    });
                }


                if (this.customArr.length > 0) {

                    switch (this.action) {
                        case 'add':
                            if (this.customArr.includes('high')) {
                                this.covidresult = this.replaceText(this.allResults.homeHighRiskResolution);

                            } else if (this.customArr.includes('medium')) {
                                this.covidresult = this.replaceText(this.allResults.homeModerateRiskResolution);

                            } else if (this.customArr.includes('probable')) {
                                this.covidresult = this.replaceText(this.allResults.homeProbableRiskResolution);
                            } else if (this.customArr.includes('low')) {
                                this.covidresult = this.replaceText(this.allResults.homeLowRiskResolution);
                            }
                            else {
                                this.covidresult = this.replaceText(this.allResults.homeRiskRiskResolution);
                            }
                            break;
                        case 'guest':
                            if (this.customArr.includes('high')) {
                                this.covidresult = this.allResults.casePublic.high;

                            } else if (this.customArr.includes('medium')) {
                                this.covidresult = this.allResults.casePublic.high;

                            } else {
                                this.covidresult = this.allResults.casePublic.low;
                            }
                            break;

                        case 'start':
                            if (this.customArr.includes('high')) {
                                this.covidresult = this.replaceText(this.allResults.caseOfficeDirect.high);

                            } else if (this.customArr.includes('medium')) {
                                this.covidresult = this.replaceText(this.allResults.caseOfficeDirect.high);

                            } else {
                                this.covidresult = this.replaceText(this.allResults.caseOfficeDirect.low);
                            }
                            break;

                        case 'edit':

                            if (this.customArr.includes('high')) {
                                this.covidresult = this.replaceText(this.allResults.inOfficeHighRiskResolution);

                            } else if (this.customArr.includes('medium')) {
                                this.covidresult = this.replaceText(this.allResults.inOfficeModerateRiskResolution);

                            } else if (this.customArr.includes('probable')) {
                                this.covidresult = this.replaceText(this.allResults.inOfficeProbableRiskResolution);
                            } else if (this.customArr.includes('low')) {
                                this.covidresult = this.replaceText(this.allResults.inOfficeLowRiskResolution);
                            }
                            else {
                                this.covidresult = this.replaceText(this.allResults.inOfficeRiskRiskResolution);
                            }

                    }
                } else {

                    switch (this.action) {
                        case 'add':
                            let fullName = '';
                            if (this.resultData) {
                                fullName = this.resultData.firstName + ' ' + this.resultData.lastName;
                            } else {
                                fullName = 'Patient';
                            }
                            const customRes = this.allResults.homeRiskRiskResolution.replace('fullName', fullName);
                            this.covidresult = customRes;
                            localStorage.setItem('extraNote', 'Y');
                            break;
                        case 'edit':
                            this.covidresult = this.replaceText(this.allResults.inOfficeRiskRiskResolution);
                            break;
                        case 'guest':

                            this.covidresult = this.replaceText(this.allResults.homeRiskRiskResolution);
                            break;
                        case 'start':

                            this.covidresult = this.replaceText(this.allResults.homeRiskRiskResolution);
                            break;
                    }

                }
                localStorage.setItem('covidresult', this.covidresult);
                // emailing in case patient selected any yes
                console.log('self-assessment-questions.component labId (this.urlLabName)= ' + this.urlLabName)

                console.log('self-assessment-questions.component labId (this.queryParam[labName]) = ' + this.queryParam['labName'])

                if (this.customArr.length > 0) {//this.queryParam['labName']

                    // const data = { patientId: this.queryParam['patient'], labId: this.urlLabName, email: this.emailId };

                    const data = { patientId: this.queryParam['patient'], labId: this.queryParam['labName'], email: this.emailId };

                    this.apiService.sendMailIfPatientSelectedAnyYes(data).subscribe(resultData => {

                    })

                }

                // if (this.customArr.length > 0) {
                //     const data = { patientId: this.queryParam['patient'], labId: this.urlLabName, email: this.emailId };
                //     this.apiService.sendMailIfPatientSelectedAnyYes(data).subscribe(resultData => {
                //     })
                // }

                this.router.navigate(['/screeningResolution'], { queryParams: { token: this.token } });
                break;
            default:
                if (localStorage.getItem('covidoptional') === 'y') {
                    // this.allQuestions[cta].question = this.replaceQuestTest(this.allQuestions[cta].question);
                    this.allQuestions[cta].question = this.allQuestions[cta].question;

                }
                // this.question = this.allQuestions[cta];
                //console.log(this.allQuestions[cta].parent.length);
                //console.log('p check22=',Object.keys(this.allQuestions[cta].parent).length);
                if (this.allQuestions[cta].parent === undefined || Object.keys(this.allQuestions[cta].parent).length == 0) {
                    this.question = this.allQuestions[cta];
                } else {
                    console.log('Parent calling' + order + cta);
                    this.checkParent(cta, order, label);

                }

        }

    }

    checkParent(cta: any, order: any, label: any) {

        this.answerMap.forEach((value: string, key: string) => {
            console.log('testing ');
            console.log('CTA=', this.allQuestions[cta], 'parent=', this.allQuestions[cta].parent);
            console.log("order   " + order);
            if (this.allQuestions[cta] !== undefined || this.allQuestions[cta] !== "") {
                for (var key2 in this.allQuestions[cta].parent) {
                    console.log("key " + key.toString().toUpperCase() + "key2 " + key2.toString().toUpperCase());
                    if (key.toString().toUpperCase() == key2.toString().toUpperCase()) {
                        console.log("value " + value.toString().toUpperCase() + "value2 " + this.allQuestions[cta].parent[key2].toString().toUpperCase());
                        if (value.toString().toUpperCase() == this.allQuestions[cta].parent[key2].toString().toUpperCase()) {

                            this.question = this.allQuestions[cta];
                        } else {
                            this.createQuestion(this.questArr[order].id, label);
                            console.log("order" + order + "length" + this.questArr.length);
                            if (this.questArr.length !== (order + 1)) {
                                console.log('Parent check2=', this.allQuestions[this.questArr[order + 1].id].parent);
                                if (this.allQuestions[this.questArr[order + 1].id].parent === undefined || Object.keys(this.allQuestions[cta].parent).length == 0
                                    || Object.keys(this.allQuestions[this.questArr[order + 1].id].parent).length == 0) {
                                    this.question = this.allQuestions[this.questArr[order + 1].id]
                                } else {
                                    // this.createQuestion(this.questArr[order+1].id,label);
                                    this.checkParent(this.questArr[order + 1].id, (order + 1), label);

                                }
                            } else {
                                console.log("Reached last question.?????");

                                this.createObj(this.questArr[order].id, label);
                                this.createQuestion(this.questArr[order].id, label);
                                console.log("quetion hide");


                                switch ('r7') {


                                    case 'r7':
                                        console.log("next cta");
                                        // this.allQuestionnaireObj['addedOn'] = (-1) * new Date().getTime();
                                        if (this.fullName && this.fullName !== undefined) {
                                            if (this.fullName.includes('-')) {
                                                this.firstNameOpt = this.fullName.split('-')[0];
                                                this.lastNameOpt = this.fullName.split('-')[1];

                                            } else {
                                                this.firstNameOpt = this.fullName;
                                                this.lastNameOpt = '';
                                            }
                                        }

                                        // patient and staff signature
                                        if (this.action === 'add') {
                                            if (this.temperature) {
                                                this.allQuestionnaireObj['unit'] = this.unit;
                                                this.allQuestionnaireObj['isUnit'] = true; // boolean for checking val
                                            } else {
                                                this.allQuestionnaireObj['unit'] = '';
                                                this.allQuestionnaireObj['isUnit'] = false;
                                            }
                                            this.allQuestionnaireObj['covidTestDate'] = this.tempFormOne.value.covidTestDate ? moment(this.tempFormOne.value.covidTestDate).toDate().getTime() : '';
                                            if (this.allQuestionnaireObj['covidTestDate']) {
                                                this.allQuestionnaireObj['covidTest'] = true;
                                            }

                                            this.allQuestionnaireObj['patientSign'] = this.signatureImageUrlPat;
                                        } else {
                                            this.allQuestionnaireObj['unitInOffice'] = this.unit;
                                            this.allQuestionnaireObj['patientSign'] = this.signatureImageUrlPat;
                                        }
                                        // this.allQuestionnaireObj['staffSign'] = this.signatureImageUrlStaff;
                                        // this.allQuestionnaireObj['patientSign'] = this.signatureImageUrlPat;
                                        // patient and staff signature


                                        if (this.action === 'start') {
                                            this.allQuestionnaireObj['login'] = this.direct;
                                        }

                                        if (this.action !== 'edit') {
                                            this.allQuestionnaireObj['temperature'] = this.temperature;
                                        }
                                        // staff name added
                                        if (this.action !== 'add') {
                                            this.allQuestionnaireObj['staffName'] = this.staffName;
                                        }
                                        // staff name added
                                        if (this.action === 'add' || this.action === 'edit') {
                                            if (this.action === 'add') {
                                                if (localStorage.getItem('covidoptional') === 'y') {
                                                    //    write the code for optional Data
                                                    this.allQuestionnaireObj['covidoptional'] = 'y';
                                                    this.allQuestionnaireObj['relationship'] = this.relationship;

                                                }

                                                this.allQuestionnaireObj['firstNameOpt'] = this.firstNameOpt;
                                                this.allQuestionnaireObj['lastNameOpt'] = this.lastNameOpt;

                                                this.allQuestionnaireObj['patientId'] = this.urlPatientId;
                                                this.allQuestionnaireObj['preOfficeTime'] = new Date().getTime();
                                                console.log(this.allQuestionnaireObj['preOfficeTime']);

                                                if (this.resultData) {
                                                    this.allQuestionnaireObj['ageStamp'] = this.dob ? this.dob : '';
                                                    this.allQuestionnaireObj['patientId'] = this.resultData.id;
                                                    this.allQuestionnaireObj['firstName'] = this.resultData.firstName;
                                                    this.allQuestionnaireObj['lastName'] = this.resultData.lastName;
                                                    this.allQuestionnaireObj['dobString'] = this.dobString;

                                                    const splitMapFName = this.resultData.firstName.toUpperCase().split(" ");
                                                    let splitMapF = {};
                                                    for (let i of splitMapFName) {
                                                        splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                                                    }

                                                    const splitMapLName = this.resultData.lastName.toUpperCase().split(" ");
                                                    let splitMapL = {};
                                                    for (let j of splitMapLName) {
                                                        splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                                                    }

                                                    const firstNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.firstName).replace(/\s/g, ''));
                                                    const lastNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.lastName).replace(/\s/g, ''));
                                                    const fullNameMap = (this.resultData.firstName + this.resultData.lastName).replace(/\s/g, '').toUpperCase();
                                                    const idMap = this.getMapValue(this.toUpperCaseString(this.resultData.id));
                                                    const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap,splitMapF,splitMapL);
                                                    anyMap[fullNameMap] = true;
                                                    this.allQuestionnaireObj['firstNameMap'] = firstNameMap;
                                                    this.allQuestionnaireObj['lastNameMap'] = lastNameMap;
                                                    this.allQuestionnaireObj['anyMap'] = anyMap;

                                                    // const idMap = this.getMapValue(this.toUpperCaseString(id));
                                                    this.allQuestionnaireObj['firstNameMap'] = firstNameMap;
                                                    this.allQuestionnaireObj['lastNameMap'] = lastNameMap;
                                                    this.allQuestionnaireObj['anyMap'] = anyMap;
                                                }
                                                this.allQuestionnaireObj['labName'] = this.urlLabName;
                                            }

                                        } else {
                                            // guest Section custom data
                                            if (this.resultData) {
                                                this.allQuestionnaireObj['firstName'] = this.resultData.firstName;
                                                this.allQuestionnaireObj['lastName'] = this.resultData.lastName;
                                            }
                                            this.allQuestionnaireObj['email'] = this.emailId;
                                            this.allQuestionnaireObj['phone'] = this.phone;
                                            if (this.resultData) {
                                                this.allQuestionnaireObj['patientId'] = this.resultData.id ? this.resultData.id : '';

                                                const splitMapFName = this.resultData.firstName.toUpperCase().split(" ");
                                                let splitMapF = {};
                                                for (let i of splitMapFName) {
                                                    splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                                                }

                                                const splitMapLName = this.resultData.lastName.toUpperCase().split(" ");
                                                let splitMapL = {};
                                                for (let j of splitMapLName) {
                                                    splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                                                }

                                                const firstNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.firstName).replace(/\s/g, ''));
                                                const lastNameMap = this.getMapValue(this.toUpperCaseString(this.resultData.lastName).replace(/\s/g, ''));
                                                const fullNameMap = (this.resultData.firstName + this.resultData.lastName).replace(/\s/g, '').toUpperCase();
                                                const idMap = this.getMapValue(this.toUpperCaseString(this.resultData.id));
                                                const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap, splitMapF, splitMapL);
                                                anyMap[fullNameMap] = true;
                                                // const idMap = this.getMapValue(this.toUpperCaseString(id));
                                                this.allQuestionnaireObj['firstNameMap'] = firstNameMap;
                                                this.allQuestionnaireObj['lastNameMap'] = lastNameMap;
                                                this.allQuestionnaireObj['anyMap'] = anyMap;

                                            }
                                            this.allQuestionnaireObj['firstNameOpt'] = this.firstNameOpt;
                                            this.allQuestionnaireObj['lastNameOpt'] = this.lastNameOpt;
                                            this.allQuestionnaireObj['labName'] = this.urlLabName;
                                            // return;

                                        }

                                        if (this.action === 'add') {
                                            console.log("inside loop" + this.action);
                                            if (this.direct && this.direct !== undefined) {
                                                this.allQuestionnaireObj['login'] = this.direct;
                                            }

                                            this.allQuestionnaireObj['email'] = this.emailId;
                                            this.allQuestionnaireObj['phone'] = this.phone;
                                            this.allQuestionnaireObj['inOffice'] = false;
                                            this.allQuestionnaireObj['preOffice'] = true;
                                            this.allQuestionnaireObj['doa'] = this.queryParam['doa'] ? this.queryParam['doa'] : '';

                                            if (this.queryParam['docId']) {
                                                this.docId = this.queryParam['docId'];
                                                this.covidService.checkCovidResultByIdWithoutLogin(this.queryParam['docId'], this.queryParam['labName']).then(async (checkExist: any) => {
                                                    // this.checkExist = await checkExist;
                                                    this.checkExistData = await checkExist;
                                                    if (this.checkExistData) {
                                                        this.allQuestionnaireObj['addedOn'] = this.checkExistData.addedOn;
                                                        this.allQuestionnaireObj['doa'] = this.checkExistData.doa;
                                                        this.allQuestionnaireObj['appointTime'] = this.checkExistData.appointTime;
                                                        this.allQuestionnaireObj['doaTimestamp'] = this.checkExistData.doaTimestamp;
                                                        this.allQuestionnaireObj['pdf'] = {};
                                                        this.allQuestionnaireObj['optionalDate'] = localStorage.getItem('optionalDate') ? localStorage.getItem('optionalDate') : '';
                                                        this.allQuestionnaireObj['tokenId'] = checkExist.tokenId;

                                                        const updateData = this.covidService.updateCovidResultWithoutLogin(this.allQuestionnaireObj, this.docId, this.queryParam['labName']);
                                                        updateData.then(result => {
                                                            const msg = 'Covid screening Data Updated Successfully';
                                                            // generate pdf Consent update
                                                            // this.apiService.generateConsentPdf({labId:this.queryParam['labName'],docId:this.queryParam['docId']}).then(resultD =>{
                                                            //   console.log('Result=',resultD)

                                                            //     }
                                                            // ).catch(err =>{

                                                            // })

                                                            // this.openSnackBar(msg, 'OK');
                                                        }).catch((error) => {//dp something

                                                            // this.handleError(error);
                                                        });

                                                    } else {
                                                        const setDateData = this.covidService.setCovidResultWithoutLogin(this.allQuestionnaireObj, this.docId, this.queryParam['labName']);
                                                        setDateData.then(result => {
                                                            const msg = 'Covid screening Data  Updated Successfully';
                                                            // this.openSnackBar(msg, 'OK');
                                                        }).catch((error) => {
                                                            // this.handleError(error);
                                                        });
                                                    }
                                                });
                                            } else {
                                                const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj, this.queryParam['labName']);
                                                returnData.then(result => {
                                                    console.log(result);
                                                    const msg = 'Covid screening Data  Added Successfully';
                                                    // this.openSnackBar(msg, 'OK');
                                                }).catch((error) => {
                                                    // this.handleError(error);
                                                });
                                            }


                                            // const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj,this.queryParam['labName']);
                                            // returnData.then(result => {
                                            //     const msg = 'Covid screening Data  Added Successfully';
                                            //     // this.openSnackBar(msg, 'OK');
                                            // }).catch((error) => {
                                            //     // this.handleError(error);
                                            // });

                                        }
                                        if (this.action === 'start') {  // 2 cases
                                            this.allQuestionnaireObj['ageStamp'] = this.dob ? this.dob : '';
                                            this.allQuestionnaireObj['patientId'] = this.urlPatientId;
                                            this.allQuestionnaireObj['patientId'] = this.resultData.id ? this.resultData.id : '';
                                            this.allQuestionnaireObj['inOffice'] = false;
                                            this.allQuestionnaireObj['dobString'] = this.dobString;
                                            const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj, this.queryParam['labName']);
                                            returnData.then(result => {
                                                const msg = 'Covid screening Data  Added Successfully';
                                                // this.openSnackBar(msg, 'OK');
                                            }).catch((error) => {
                                                // this.handleError(error);
                                            });

                                        }
                                        if (this.action === 'guest') { // 1 case

                                            this.allQuestionnaireObj['firstName'] = this.firstNameOpt;
                                            this.allQuestionnaireObj['lastName'] = this.lastNameOpt;

                                            const returnData = this.covidService.addCovidResultWithoutLogin(this.allQuestionnaireObj, this.queryParam['labName']);
                                            returnData.then(result => {
                                                const msg = 'Covid screening Data  Added Successfully';
                                                // this.openSnackBar(msg, 'OK');
                                            }).catch((error) => {
                                                // this.handleError(error);
                                            });

                                        }
                                        if (this.action === 'edit') { // 1 case

                                            this.allQuestionnaireObj['inOfficeTemperature'] = this.temperature;
                                            this.allQuestionnaireObj['inOffice'] = true;
                                            this.allQuestionnaireObj['inOfficeTime'] = new Date().getTime();
                                            const returnData = this.covidService.updateCovidResultWithoutLogin(this.allQuestionnaireObj, this.urlPatientId, this.queryParam['labName']);
                                            returnData.then(result => {
                                                const msg = 'Covid screening Data  Updated Successfully';
                                                // this.openSnackBar(msg, 'OK');
                                            }).catch((error) => {
                                                // this.handleError(error);
                                            });
                                        }


                                        if (this.customArr.length > 0) {
                                            console.log("length " + this.customArr.length);

                                            switch (this.action) {
                                                case 'add':
                                                    if (this.customArr.includes('high')) {
                                                        this.covidresult = this.replaceText(this.allResults.homeHighRiskResolution);
                                                        console.log('high=', this.covidresult);

                                                    } else if (this.customArr.includes('medium')) {
                                                        this.covidresult = this.replaceText(this.allResults.homeModerateRiskResolution);
                                                        console.log('medium=', this.covidresult);

                                                    } else if (this.customArr.includes('probable')) {
                                                        this.covidresult = this.replaceText(this.allResults.homeProbableRiskResolution);
                                                        console.log('probable=', this.covidresult);
                                                    } else if (this.customArr.includes('low')) {
                                                        this.covidresult = this.replaceText(this.allResults.homeLowRiskResolution);
                                                        console.log('Low=', this.covidresult);
                                                    }
                                                    else {
                                                        this.covidresult = this.replaceText(this.allResults.homeRiskRiskResolution);
                                                    }
                                                    break;
                                                case 'guest':
                                                    if (this.customArr.includes('high')) {
                                                        this.covidresult = this.allResults.casePublic.high;

                                                    } else if (this.customArr.includes('medium')) {
                                                        this.covidresult = this.allResults.casePublic.high;

                                                    } else {
                                                        this.covidresult = this.allResults.casePublic.low;
                                                    }
                                                    break;

                                                case 'start':
                                                    if (this.customArr.includes('high')) {
                                                        this.covidresult = this.replaceText(this.allResults.caseOfficeDirect.high);

                                                    } else if (this.customArr.includes('medium')) {
                                                        this.covidresult = this.replaceText(this.allResults.caseOfficeDirect.high);

                                                    } else {
                                                        this.covidresult = this.replaceText(this.allResults.caseOfficeDirect.low);
                                                    }
                                                    break;

                                                case 'edit':

                                                    if (this.customArr.includes('high')) {
                                                        this.covidresult = this.replaceText(this.allResults.inOfficeHighRiskResolution);

                                                    } else if (this.customArr.includes('medium')) {
                                                        this.covidresult = this.replaceText(this.allResults.inOfficeModerateRiskResolution);

                                                    } else if (this.customArr.includes('probable')) {
                                                        this.covidresult = this.replaceText(this.allResults.inOfficeProbableRiskResolution);
                                                    } else if (this.customArr.includes('low')) {
                                                        this.covidresult = this.replaceText(this.allResults.inOfficeLowRiskResolution);
                                                    }
                                                    else {
                                                        this.covidresult = this.replaceText(this.allResults.inOfficeRiskRiskResolution);
                                                    }

                                            }
                                        } else {

                                            switch (this.action) {
                                                case 'add':
                                                    let fullName = '';
                                                    if (this.resultData) {
                                                        fullName = this.resultData.firstName + ' ' + this.resultData.lastName;
                                                    } else {
                                                        fullName = 'Patient';
                                                    }
                                                    const customRes = this.allResults.homeRiskRiskResolution.replace('fullName', fullName);
                                                    this.covidresult = customRes;
                                                    localStorage.setItem('extraNote', 'Y');
                                                    break;
                                                case 'edit':
                                                    this.covidresult = this.replaceText(this.allResults.inOfficeRiskRiskResolution);
                                                    break;
                                                case 'guest':

                                                    this.covidresult = this.replaceText(this.allResults.homeRiskRiskResolution);
                                                    break;
                                                case 'start':

                                                    this.covidresult = this.replaceText(this.allResults.homeRiskRiskResolution);
                                                    break;
                                            }

                                        }
                                        localStorage.setItem('covidresult', this.covidresult);
                                        // emailing in case patient selected any yes

                                        if (this.customArr.length > 0) {
                                            const data = { patientId: this.queryParam['patient'], labId: this.urlLabName, email: this.emailId };
                                            console.log('data data data=', data);
                                            this.apiService.sendMailIfPatientSelectedAnyYes(data).subscribe(resultData => {
                                                console.log('result Data=', resultData);
                                            })
                                        }

                                        this.router.navigate(['/screeningResolution'], { queryParams: { token: this.token } });
                                        break;
                                }


                            }

                        }
                    }
                }

            } else {
                console.log("Reached last question last one.");
            }

        });
    }


    getCustomMessage(quest, risk) {
        this.customArr.push(risk);
    }



    replaceQuestTest(data) {
        // const fullName = this.patientFullName?this.patientFullName:'Patient'  ;
        const fullName = sessionStorage.getItem('questName');

        // const fullName = this.patientFullName;
        const pattern1 = /Have you tested/g;
        const pattern2 = /Do you/g;
        const pattern3 = /Are you/g;
        const pattern4 = /Have you travelled/g;
        return data.replace(pattern1, 'Has ' + fullName + ' tested').
            replace(pattern2, 'Does ' + fullName).
            replace(pattern3, 'Is ' + fullName).
            replace(pattern4, 'Did ' + fullName + ' travel');

    }


    replaceText(str) {

        const pattern = /{fullName}/g;

        let fullName = '';
        switch (this.action) {

            case 'edit':
                if (this.allQuestionnaireObj) {
                    fullName = this.allQuestionnaireObj.firstName + ' ' + this.allQuestionnaireObj.lastName;
                } else {
                    fullName = 'Patient';
                }

                break;
            case 'add':
            case 'start':
                if (this.resultData) {
                    fullName = this.resultData.firstName + ' ' + this.resultData.lastName;
                } else {
                    fullName = 'Patient';
                }
                break;

        }
        const newStr = str.replace(pattern, fullName);
        return newStr;
    }


    onClickBack(id: string) {
        switch (id) {

            case 'r7':
                this.router.navigate(['/screeningResolution']);
                break;
            case 'startAssessment':
                this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.token } });
                break;
            default:
                this.question = this.allQuestions[id];
        }

    }

    changeEvent(event, itemVal) {
        if (event.target.checked) {
            this.toggleBool = false;
        } else {
            this.toggleBool = true;
        }
    }


    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, '');
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = '';
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;

        }
        return obj;
    }

    toUpperCaseString(str) {
        return (str.trim()).toUpperCase();
    }



    onChangeSelect(eventVal) {
        this.temperature = '';

    }

    numericOnlyTemp(event): boolean {
        // if (/^[0-9].*$/.test(event.key)) {
        //     this.tempForm.markAllAsTouched();
        //     return;
        // }
        const unitVal: string = this.tempForm.value.unit;
        if (this.tempForm.invalid) {
            this.tempForm.markAllAsTouched();
        }

        if (unitVal === 'C') {
            this.minT = 20;
            this.maxT = 40;
            this.matError = 'Value range (34 - 39)C';
        } else if (unitVal === 'F') {
            this.maxLengthTemp = 6;
            this.minT = 80;
            this.maxT = 110;
            this.matError = 'Value range (96 - 108)F';

        }



        // const patt = /^[0-9]\d*$/;
        const patt = /^\d*\.?\d*$/;
        const result = patt.test(event.key);
        return result;

    }

    numericOnlyTempKeyUp(event): boolean {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            if (/^[0-9].*$/.test(event.key)) {
                this.tempForm.markAllAsTouched();
                return;
            }
            const unitVal: string = this.tempForm.value.unit;
            if (this.tempForm.invalid) {
                this.tempForm.markAllAsTouched();
            }

            if (unitVal === 'C') {
                this.minT = 34;
                this.maxT = 39;
                this.matError = 'Value range (34 - 39)C';
            } else if (unitVal === 'F') {
                this.maxLengthTemp = 6;
                this.minT = 96;
                this.maxT = 108;
                this.matError = 'Value range (96 - 108)F';

            }
            // const patt = /^[0-9]\d*$/;
            const patt = /^\d*\.?\d*$/;
            const result = patt.test(event.key);
            return result;
        }

    }

    numericOnlyTempBody(event): boolean {

        const unitVal = this.tempForm.value.unit;
        const patt = /^[0-9]\d*$/;
        const result = patt.test(event.key);
        return result;
    }

    calculateAge(dob) {
        const diffMs = Date.now() - dob;
        const ageDt = new Date(diffMs);
        return Math.abs(ageDt.getUTCFullYear() - 1970);
    }

    isMobileLandscapeFunction(event) {

        if (screen.orientation && screen.orientation.type && (screen.orientation.angle === 90 || screen.orientation.angle === -90) && this.isMobile() && screen.width < 820) {
            // alert('Kindly Change the landscape orientation. This app is not compatible for mobile landscape mode');
            this.isMobileLandscapeModeActivated = true;

        }
        else {
            this.isMobileLandscapeModeActivated = false;
        }
    }
    isMobile() {
        const mobileCheck = {
            Android: () => navigator.userAgent.match(/Android/i),
            BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
            iOS: () => navigator.userAgent.match(/iPhone/i),
            Opera: () => navigator.userAgent.match(/Opera Mini/i),
            Windows: () => navigator.userAgent.match(/IEMobile/i),
            detectmob: () => window.innerWidth <= 799,
            any: () => mobileCheck.Android() || mobileCheck.BlackBerry() || mobileCheck.iOS() || mobileCheck.Opera() || mobileCheck.Windows() || mobileCheck.detectmob(),
        };
        return mobileCheck.any() ? true : false;
    };

    ngOnDestroy() {
        localStorage.setItem('fullName', '');
        localStorage.setItem('covidoptional', '');
        // localStorage.setItem('staffName', '');
        this.userService.setCookie("staffName", '');
        // localStorage.setItem('signatureImage','');
        // localStorage.setItem('signatureImagePat','');
        sessionStorage.setItem('questName', '');
    }


}
