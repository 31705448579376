import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    HostListener,
    OnInit,
    TemplateRef,
    OnChanges,
    ViewChild,
    ViewEncapsulation, Inject
} from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { Observable, Subject, Subscription } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../common/service/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Resolve } from '@angular/router';
import { TeamService } from './team.service';
import { Team } from './team';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../../base/base.component';
import { ConfigService } from '../../common/config/config.service';
import { Globals } from '../../common/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TextUtils } from '../../../util/TextUtils';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../../common/service/authentication.service';
import { ApiService } from '../../common/service/api.service';
import { SuccessfulComponent } from '../patient/successful/successful.component';
import { PaginationService } from '../../common/pagination/pagination.service';
import { noop as _noop } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { Role } from '../../common/role';
import { MatTable } from '@angular/material/table';
import { truncate } from 'lodash';
import { IpServiceService } from '../../common/service/ip-service.service';
import { CovidStaffDto } from '../../screening/staff/CovidSatffDto.modal';
import { sortAndDeduplicateDiagnostics } from 'typescript';
// import { DOCUMENT} from '@angular/common';
import { LogsService } from '../logs/logs.service';

@Component({
    selector: 'team-table',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class TeamComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    // dataSource: MatTableDataSource<any>();
    dataSource = new MatTableDataSource<any>();
    // displayedColumns: string[] = [ 'avatar', 'name', 'position', 'office', 'email', 'phone'];

    displayedColumns: string[] = ['Sr', 'name', 'role', 'email', 'screening', 'action', 'delete'];

    displayedColumnsNoDelete: string[] = ['Sr', 'name', 'role', 'email', 'screening'];


    registerForm: FormGroup;
    registerFormAdmin: FormGroup;
    registerFormEdit: FormGroup;
    staffForm: FormGroup;
    staffDropDown: any;
    columns = this.getColumn();

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('table') table: MatTable<any>;
    page = 0;
    role = this.userService.getCookie('role');
    lab = this.userService.getCookie('lab');
    types: Array<any> = [];
    currentRoleData: any;
    beforeEdit: any;
    datePick = false;
    tStamp: any;
    message = '';
    msgColor = '';
    color = ['green', 'red', 'blue'];
    isCheckedVal = false;
    validD: any;
    length: any;
    @Input()
    min: 'D' | null;
    today = new Date();
    emailToDel = '';
    perPageData = this.globals.perPageData;
    staffEmail = '';
    staffName = '';
    urlLabName = this.userService.getCookie("lab");
    loggedInEmail = this.userService.getCookie('email');
    // Private
    spinner = false;
    hasFirstDataShown = false;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Team[]>;
    limit = 1000;
    full = false;
    isSticky = true;
    roleData: any;
    enableInfoMsg = false;
    dataN: Array<any> = [];
    hasEditDataShown = true;
    staffScreeningEnable: boolean = true;
    teamName = '';
    mailData: any = {}

    daysArrayList: any = [{
        days: [{ value: 0, valueView: 'Sun', checked: true },
        { value: 1, valueView: 'Mon', checked: true },
        { value: 2, valueView: 'Tue', checked: true },
        { value: 3, valueView: 'Wed', checked: true },
        { value: 4, valueView: 'Thu', checked: true },
        { value: 5, valueView: 'Fri', checked: true },
        { value: 6, valueView: 'Sat', checked: true }],
    }];
    isDisabled = false;
    staticObjArr: any;

    ipAddress = '';
    staffFormSelector: FormGroup;
    loadingSpinner: boolean;
    types2: any[];

    constructor(
        private userService: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private authService: AuthenticationService,
        public logService: LogsService,
        private paginationService: TeamService,
        // private changeDetectorRefs: ChangeDetectorRef,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private configService: ConfigService,
        private globals: Globals,
        public snackBar: MatSnackBar,
        private cookieService: CookieService,
        // private paginationServiceV2: PaginationService,
        private apiService: ApiService,
        private roleConfig: Role,
        private ip: IpServiceService
        // @Inject(DOCUMENT) private document: Document
    ) {
        super(configService, globals);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // ------------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        console.log('You scrolled!');
    }


    ngOnInit(): void {
        // this.getIP();
        this.page = 0;
        this.hasFirstDataShown = false;
        this.paginationService.allTeamData().then(data => {
            this.hasFirstDataShown = true
            this.staffDropDown = data;

        });

        this.roleData = this.route.snapshot.data.teamResolver as Team;
        console.log('roleData', this.roleData);
        if (this.roleData.data)
            if (!this.roleConfig.authRole.includes(this.roleData.data.role)) {
                this.router.navigate(['/home']);

            }

        this.paginationService.getCovidConfig().then(data => {
            console.log("isStaffPreScreeningEnabled=", data.isStaffPreScreeningEnabled);
            if (data.isStaffPreScreeningEnabled == true) {
                this.staffScreeningEnable = true;
                console.log("isStaffPreScreeningEnabled=", this.staffScreeningEnable);
            } else {
                this.staffScreeningEnable = false;
                console.log("isStaffPreScreeningEnabledelse=", this.staffScreeningEnable);
            }
        });

        this.staffFormSelector = this._formBuilder.group({

            staff: ['', [Validators.required], ''],

        });

        console.log('loginUserRole', this.role);
        this.paginationService.getAllRole().then(allRole => {
            allRole.forEach(element => {
                const id = element.id;
                const value = element.value;
                if (element.canSelectAsRole) {
                    // console.log('role supportif')
                    this.types.push({ id, value });
                    // if ( this.role == element.id) {
                    //     // console.log('before anjali', this.types);
    
                    // }
                    // else{
                    //     this.types.push({ id, value });
                    //     // console.log('before anjali', this.types);
                    // }
                }
                else{
                    console.log('role support')
                }
            })  
        }).catch(error => { // dp something
        });
        this.role = this.userService.getCookie('role');

        this.getDisplayedColumns();


        if (this.role !== 'user') {
            this.registerForm = this._formBuilder.group({
                name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
                email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
                role: ['', [Validators.required], ''],
                // hasLimit: ['', Validators.required],
                // validTillDate: ['', ''],

            });

        } else {

            this.registerFormAdmin = this._formBuilder.group({
                name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
                email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
                // hasLimit: ['', Validators.required],
                // validTillDate: ['', ''],//////

            });
        }
        this.registerFormEdit = this._formBuilder.group({
            name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
            email: [{
                value: '',
                disabled: true
            }, [Validators.required, Validators.email, [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]]],
            role: ['', Validators.required],
            // hasLimit: ['', Validators.required],
            // validTillDate: [{value :''}, ],
        });


        this.staffForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)], ''],
        });

        this.staticObjArr = this.daysArrayList;


    }

    getDisplayedColumns(): string[] {


        if (this.currentRoleData) {

            const isAdmin = this.currentRoleData.hideAddTeamMember;
            if (!isAdmin) {

                return this.displayedColumns;
            } else {

                return this.displayedColumnsNoDelete;
            }

        }
    }

    enableInOffice(docId, inOffice) {
        let combo = '';
        if (inOffice === undefined) {
            return combo = docId;
        }

        return combo;
    }

    getColumn(): any[] {
        return [
            {
                columnDef: 'Sr.',
                header: 'Sr.',
                cell: (element, index: number) => `${this.page * 10 + index + 1}`,
            },
            { columnDef: 'Name', header: 'Name', cell: (element: CovidStaffDto, index: number) => `${element.name ? element.name : ''}` },
            { columnDef: 'DateTime', header: 'DateTime', cell: (element: CovidStaffDto, index: number) => `${element.inOffTime ? this.apiService.getDateStringMomentByTz(Number(element.inOffTime)) : this.apiService.getDateStringMomentByTz(element.addedOn)}` },
            // {columnDef: 'Time', header: 'Time', cell: (element: CovidStaffDto, index: number) => this.getTime(Math.abs(element.addedOn))},
            { columnDef: 'home', header: 'Staff Response (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preOffice ? element.preOffice : ''}` },
            { columnDef: 'Temperaturehome', header: 'Temperature (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preOffTemperature ? element.preOffTemperature + '\xB0' + element.preOffTemperatureUnit : ''}` },
            { columnDef: 'Spohome', header: 'SpO2 (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preSpo ? element.preSpo : ''}` },
            { columnDef: 'inOffice', header: 'Staff Response (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.inOffice ? element.inOffice : ''}` },
            { columnDef: 'Temperature', header: 'Temperature (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.inOffTemperature ? element.inOffTemperature + '\xB0' + element.unitInOffice : ''}` },
            { columnDef: 'Spo', header: 'SpO2 (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.spo ? element.spo : ''}` },
            { columnDef: 'Signature', header: 'Signature', cell: (element: CovidStaffDto, index: number) => `${element.preStaffSign ? element.preStaffSign : element.staffSign}` },
            { columnDef: 'SignatureSc', header: 'Staff Screener', cell: (element: CovidStaffDto, index: number) => `${element.staffSign ? element.staffSign : element.staffSign}` },
            { columnDef: 'In Office', header: 'In Office', cell: (element: CovidStaffDto, index: number) => this.enableInOffice(element.docId, element.inOffice) },
        ];
    }


    enableInOfficeSingle(docId, login, inOffice) {

        if (login === undefined) {
            switch (inOffice) {
                case true:
                    return 1;
                    break;
                case false:
                    return 2;
                    break;
                default:
                    return 3;
                    break;
            }
        }

    }


    onCancelFrm() {
        this.registerForm.reset();
        // this.registerFormAdmin.reset();
    }
    onEditCancelFrm() {
        this.registerFormEdit.reset();
        // this.registerFormAdmin.reset();
        this.daysArrayList = this.staticObjArr;
    }

    addTeamMember() {

        this.paginationService.checkTeammember(this.paginationService.getCollectionPath(), this.getTrimAndLowerCaseEmail(this.registerForm.value.email)).subscribe(isAvailable => {
            if (isAvailable.exists) {
                this.registerForm.reset();
                const msg = 'Email Already Exist!';
                this.openSnackBar(msg, 'OK');
            } else {

                const teamData =
                {
                    name: this.registerForm.value.name.trim(),
                    lab: this.lab.trim(),
                    email: this.getTrimAndLowerCaseEmail(this.registerForm.value.email),
                    role: this.registerForm.value.role,
                    sortBy: TextUtils.toUpperCase(this.registerForm.value.name),
                    updatedBy: this.roleData.data.name,
                    oneStaffMailDaysArray: this.daysArrayList, // screening email days data
                    ipAddress: this.ipAddress
                };
                // if (this.role === 'admin') {
                //     teamData.role = 'user';
                // }
                // const expiryData = {hasLimit:this.datePick,validTillDate:this.tStamp};
                console.log('teamData', teamData);
                const expiryData = {};
                const returnData = this.paginationService.addTeamMember(teamData, expiryData);
                console.log('returndata', returnData);
                returnData.then((data: any) => {
                    const msg = 'Member Added Successfully';
                    this.openSnackBar(msg, 'OK');
                    // this.initData();
                }).catch((error) => {
                    this.handleError(error);
                });
                this.registerForm.reset();
            }
        });

    }

    getTrimAndLowerCaseEmail(email) {
        return email.trim().toLowerCase();
    }

    // subscription: Subscription;
    // initData() {
    //     if (this.subscription) {
    //         this.subscription.unsubscribe();
    //     }
    //     this.paginationServiceV2.reset();
    //     this.paginationServiceV2.init(this.paginationService.getCollectionPath(), 'name', { reverse: false, prepend: false },true)
    //
    //     this.subscription = this.paginationServiceV2.data.subscribe(data => {
    //         this.dataN = data;
    //         this.dataSource = new MatTableDataSource(this.dataN);
    //         this.dataSource.data = this.dataN;
    //         if(!this.dataN || data.length === 0){
    //             this.enableInfoMsg = false;
    //             this.hasFirstDataShown = false;
    //             setTimeout(()=> {
    //                 this.enableInfoMsg = true;
    //                 this.hasFirstDataShown = true;
    //             },2500)
    //         }
    //     });
    //     this.page = 0;
    // }

    // ngAfterViewInit(): void {
    //     // this.initData();
    //     this.authService.getRoleById(this.role).then(roleData => {
    //         this.currentRoleData = roleData;
    //     });
    // }

    ngAfterViewInit(): void {
        this.paginationService.getWebRole(this.role).then(roleData => {
          this.currentRoleData = roleData;
            console.log(this.currentRoleData);
        });

        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());
        this.paginationService.first();
        this.paginationService.pageSubject.next(0);

        this.paginationService.pageObservable.subscribe(page => {
            this.page = page;
            console.log('Page' + page);
            // this.changeDetectorRefs.detectChanges();
        });

        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {
            // console.log('datataaa',data);
            if (data && data.length > 0) {
                const sorted = data.sort((t1, t2) => {
                    const name1 = t1.name.toLowerCase();
                    const name2 = t2.name.toLowerCase();
                    if (name1 > name2) {
                        return 1;
                    }
                    if (name1 < name2) {
                        return -1;
                    }
                    return 0;
                })

                this.dataSource.data = sorted;
                this.length = data.length;
            }

            // console.log('changes', data);
            // this.filterDataByType(data);
        });

    }

    getOrderByColumn(): string {
        return 'name';
    }

    getTableName(): string {
        return 'team';
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.datePick = false;
        this.dialog.open(ref, { width: '320px', disableClose: true });
    }

    shareAssessmentLink(staffEmail: string): void {
        this.loadingSpinner = false;
        this.paginationService.shareAssessmentLink(staffEmail).subscribe(dataResult => {
            this.loadingSpinner = true;
            this.openSuccessfulDialog();
        });
    }

    openSuccessfulDialog() {
        const dialogRef = this.dialog.open(SuccessfulComponent,
            {
                disableClose: true,
                width: '350px',
                height: '250px',
                // data: {firstName: this.firstName, lastName: this.lastName, pid: this.urlPatientId}
            });
    }

    openDialogWithRefedit(ref: TemplateRef<any>, email) {
        this.hasEditDataShown = false;
        this.paginationService.getMemberById(email.trim()).then((data: any) => {
            this.beforeEdit = data;
            if (data.hasOwnProperty('oneStaffMailDaysArray'))
                this.daysArrayList = data.oneStaffMailDaysArray;
            this.registerFormEdit.setValue({
                // @ts-ignore
                email: data.email,
                // @ts-ignore
                name: data.name,
                // @ts-ignore
                role: data.role,
                // validTillDate: this.validD,
            });
            this.hasEditDataShown = true;
        }).catch(error => {
            this.handleError(error);

        });

        this.dialog.open(ref, { width: '380px', disableClose: true });
    }

    editTeamMember(email) {

        const teamData =
        {
            name: this.registerFormEdit.value.name.trim(),
            lab: this.lab.trim(),
            email: email,
            role: this.registerFormEdit.value.role,
            sortBy: TextUtils.toUpperCase(this.registerFormEdit.value.name),
            labsObject: [], // recreating labsobject after edit,
            oneStaffMailDaysArray: this.daysArrayList,
            ipAddress: this.ipAddress,
            updatedBy: this.roleData.data.name

        };


        // recreating labs object Data
        const labsData: any = {
            labId: this.beforeEdit.labsObject[0].labId,
            labName: this.beforeEdit.labsObject[0].labName,
            role: this.registerFormEdit.value.role,
            updatedBy: this.roleData.data.name,

        };
        teamData.labsObject.push(labsData);
        console.log('TEAM DATA', labsData)
        const returnData = this.paginationService.updateTeamMember(teamData);
        returnData.then(data => {
            this.paginationService.updateUserWhenTeamUpdated(teamData).then(data => {

                const msg = 'Member Updated Successfully';
                this.openSnackBar(msg, 'OK');
                // window.location.reload();
            });


        }).catch((error) => {
            this.handleError(error);
        });
        this.registerForm.reset();

    }

    openDialogWithRefDel(ref: TemplateRef<any>, email): void {
        if (email === this.userService.getCookie('email')) {
            window.alert('You can not delete logged in user.');
            return;
        } else {
            this.emailToDel = email;
            this.dialog.open(ref, { width: '400px' });
        }


        // this.router.navigate(['/viewtool']);
    }

    deleteTeamMember(docId) {

        const returnMsg = this.paginationService.deleteDocumentById(docId);

        // this.initData();
        returnMsg.then(data => {
            this.paginationService.deleteUserWhenTeamdeleted(docId).then(data => {

                this.openSnackBar(data, 'OK');
                // this.openSnackBar(msg, 'OK');
                // window.location.reload();
            });


        }).catch((error) => {
            this.handleError(error);
        });
        this.registerForm.reset();


    }

    openDialogWithRefInOffice(ref: TemplateRef<any>, order) {
        // console.log(order.id);
        this.teamName = order.name;
        const sentData = { email: order.email.trim(), labId: this.lab };
        this.apiService.sendMailStaffFromTeamModule(sentData).subscribe(result => {
            console.log(result);
            this.mailData = result;
            console.log(this.mailData.docId);
            //     need to test and add toast msg
            this.dialog.open(ref, {
                disableClose: true, height: '250px', width: '350px'

            });

        });

    }

    editSinglePage(): void {
        this.staffName = this.staffFormSelector.value.staff;
        this.userService.setCookie("staffName", this.staffName);

        const urlStrGuest = 'key#edit##' + 'docId#' + this.mailData.docId + '##labName#' + this.urlLabName;
        console.log(urlStrGuest);
        this.router.navigate(['/staffassessment'], { queryParams: { token: this.globals.encodeToa(urlStrGuest) } });
        // this.paginationService.getStaffMember(this.teamName).then((data: any) => {
        //     console.log(data);

        // });  

    }

    openDialogWithRefStaff(ref: TemplateRef<any>, obj) {
        console.log(obj);
        if (obj.hasOwnProperty('oneStaffMailDaysArray') && obj.oneStaffMailDaysArray[0].days[new Date().getDay()].checked === false) {
            alert('Sending mail option is disabled, update user detail');
            return false;
        }

        this.paginationService.getCovidConfig().then(data => {
            if (data.enableStaffEmail === 'Yes' && data.staffMailDaysArray[0].days[new Date().getDay()].checked === true) {
                this.staffEmail = obj.email;
                this.dialog.open(ref, { width: '360px', disableClose: true });
            } else {
                alert('Sending mail option is disabled, check staff email configuration');
            }

        });
    }

    sendStaffMail() {

        // get covid staff config
        this.loadingSpinner = true;
        console.log(this.loadingSpinner);
        const sentData = { email: this.staffEmail, labId: this.lab };
        // this.apiService.sendMailStaffFromTeamModule(sentData).subscribe(result => {
        //     console.log(result);
        //     //     need to test and add toast msg
        //     this.loadingSpinner=true;
        //     console.log(this.loadingSpinner);
        //     this.openSuccessfulDialog();
        //     // this.loadingSpinner=true;

        // });


        this.apiService.sendMailStaffFromTeamModule(sentData)
            .subscribe({
                next: data => {
                    console.log(data);
                    //     need to test and add toast msg
                    this.loadingSpinner = false;
                    console.log(this.loadingSpinner);
                    this.openSuccessfulDialog();
                },
                error: HttpErrorResponse => {
                    // this.deletemachineStatus = HttpErrorResponse.error.status;
                    // this.deletemachineError = HttpErrorResponse.error.error;
                    this.loadingSpinner = true;
                    console.log('this.loadingSpinner = ' + this.loadingSpinner)
                    console.log(HttpErrorResponse.error.message)
                    console.log(HttpErrorResponse)
                    // const msg = 'Email Not Exist!';
                    // this.openSnackBar(msg, 'OK');
                    const msg = "Please check EmailID!"
                    this.openSnackBar(msg, 'OK');
                    this.loadingSpinner = false;

                }
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    handleScroll = (scrolled: boolean) => {
        // console.timeEnd('lastScrolled');
        scrolled ? this.getData() : _noop();
        // console.time('lastScrolled');
    }
    hasMore = () => {
        return !this.dataSource || this.dataSource.data.length < this.limit;

    }
    getData() {
        // this.paginationServiceV2.more();
        // this.dataSource.sort = this.sort;


    }

    changeCheckboxEvent(event) {
        // staffMailDaysArray
        console.log(event);
        this.changeDesc(event.source.name, event.checked);
        // console.log(this.daysArrayList);
    }

    changeDesc(value, boolVal) {
        for (let i in this.daysArrayList[0].days) {
            if (this.daysArrayList[0].days[i].valueView === value) {
                this.daysArrayList[0].days[i].checked = boolVal;
                break;
            }

        }
    }

    getIP() {
        this.ip.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res.ip;
            console.log("IpAddress");
            console.log(this.ipAddress);
        });

    }



}
