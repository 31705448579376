import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { fuseAnimations } from "../../../../../../@fuse/animations";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { FuseConfigService } from "../../../../../../@fuse/services/config.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { CovidstaffService } from "../covidstaff.service";
import { CovidService } from "../../covid.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Globals } from "../../../common/global";
import * as firebase from "firebase";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "app/main/module/common/service/storage.service";
import { FirebaseUtilService } from "app/main/module/common/service/FirebaseUtil.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as moment from "moment";

@Component({
    selector: "app-staffassessment",
    templateUrl: "./staffassessment.component.html",
    styleUrls: ["./staffassessment.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class StaffassessmentComponent implements OnInit {
    answers = {};
    patientForm: FormGroup;
    patientFormEmail: FormGroup;
    allQuestionnaireObj = {};
    signatureImageUrlPat = "";
    inOfficeTemperature = "";
    spo = "";
    titleMatIcon = this.getTitleMatIcon();
    //  questionArray: any[];
    matError = "34-39C";
    unitInOffice = "";
    minV: any;
    maxV: any;
    inOffTemperature = "";
    temperatureUnit = "C";
    alertSign = "";
    hasFirstDataShown:Boolean;
    signatureImage = "";
    symptom: any;
    radioErr = "";
    urlStaffId = this.userService.getCookie("email");
    token = "";
    queryParam = {};
    labName = "";
    docId: string;
    key: string;
    staffName = "";
    maxLengthTemp = 5;
    onclicktrue = false;
    href: string;
    showAsterisk: boolean;
    ansObject: Array<any>;
    selectedRadio: any;
    selecteCheck: any;

    // allQuestions = JSON.parse(sessionStorage.getItem('questions')) ;
    // allQuestions: any = Questions;

    allQuestions: any;
    questionArray: any[];
    currentQuestionId = "travelout";
    // question = this.allQuestions[this.currentQuestionId];
    question: any;
    toggleBool = false;
    questionnaireObj: Array<any> = [];
    urlPatientId = "";
    urlLabName = "";
    //allQuestionnaireObj = { firstName: '', lastName: '' };
    // allQuestionnaireObj = Object.assign({}, this.allQuestions);
    resultData: any;
    form: FormGroup;
    tempForm: FormGroup;
    tempFormOne: FormGroup;

    action: any;
    emailId = "";
    phone: number;
    firstNameOpt = "";
    lastNameOpt = "";
    fullName = "";
    direct: any;
    //token = '';
    selectedValue: Boolean;
    //queryParam = {};
    temperature = "";
    // allResults: any = Results;  // results being assign here
    allResults: any;
    customArr: Array<any> = [];
    covidresult = "";
    logoUrl = "";
    siteLogo = "";
    // signatureImage = '';
    signatureImageUrlStaff = "";
    signatureImagePat = "";
    //signatureImageUrlPat = '';
    //staffName = '';
    relationship = "";
    unit = "C";
    // selected = 'C';
    patientFullName = "";
    dob: any;
    // docId = '';
    doa: any;
    checkExist: boolean;
    checkExistData: any;
    minT = 0;
    maxT = 0;
    // matError = '';
    questArr: Array<any> = [];
    arrTest: Array<any> = [];
    arrTestInoffice: Array<any> = [];
    @Input()
    max: "D" | null;
    today = new Date();
    isMobileLandscapeModeActivated = false;
    //maxLengthTemp = 5;
    dobString = "";
    selctValue: any;
    parents: Array<any> = [];
    answerMap = new Map();
    //answerMap= {};
    getSelectValue: any;

    ordersData = [
        { id: 100, name: "order 1" },
        { id: 200, name: "order 2" },
        { id: 300, name: "order 3" },
        { id: 400, name: "order 4" },
    ];
    elseBlock: any;
    checkdob = parseInt(sessionStorage.getItem("checkdob"), 10);
    private _unsubscribeAll: Subject<unknown>;

    @ViewChild(SignaturePad) public signaturePad: SignaturePad;
    public signaturePadOptions: object = {
        // passed through to signature_pad constructor
        minWidth: 1,
        canvasWidth: 250,
        canvasHeight: 150,
        backgroundColor: "gray",
    };

    // public temperatureValidation = [
    //      Validators.pattern(/^\d*\.?\d*$/),
    //     (control: AbstractControl) => Validators.max(this.maxV)(control),
    //     (control: AbstractControl) => Validators.min(this.minV)(control)
    // ];
    allQuestionnaireObj2: any;
    description: any;
    constructor(
        private formBuilder: FormBuilder,
        private _fuseConfigService: FuseConfigService,
        private storage: AngularFireStorage,
        private covidstaffService: CovidstaffService,
        private route: ActivatedRoute,
        private router: Router,
        private cookieService: CookieService,
        private userService: StorageService,
        public firebaseUtil: FirebaseUtilService,
        public global: Globals,
        private covidService: CovidService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this.form = this.formBuilder.group({
            orders: new FormArray([]),
        });
        this._unsubscribeAll = new Subject();
        this.addCheckboxes();
    }

    private addCheckboxes() {
        this.ordersData.forEach((o, i) => {
            const control = new FormControl(i === 0); // if first item set to true, else false
            (this.form.controls.orders as FormArray).push(control);
        });
    }

    ngOnInit(): void {
        this.hasFirstDataShown = false;
        // this.href = this.router.url;
        // console.log("Kirti=",this.href);
        // if (this.href.includes("/staffassessment?token=")) {
        // this.showAsterisk = true;
        // console.log(this.showAsterisk);
        // }
        // else {
        // this.showAsterisk = false;
        // console.log(this.showAsterisk);
        // }
        this.token = this.route.snapshot.queryParams.token;
        console.log(this.token);
        if (this.token) {
            const keyVal = this.global.decodeToa(this.token).split("##");
            const abKey = keyVal.map((para1) => para1.split("#")[0]);
            const abVal = keyVal.map((para1) => para1.split("#")[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }
        console.log(this.queryParam);

        this.covidstaffService.getData(this.queryParam["labName"]).then((questionArray) => {
            console.log("QuestionArray=", questionArray);
            this.hasFirstDataShown = true;
            console.log(questionArray.length);
            this.questArr = questionArray.filter((element) => {
                return element !== undefined;
            });

            this.questionArray = this.questArr;
            this.allQuestionnaireObj2 =
                this.covidstaffService.getQuestionObjFromDocuments(
                    this.questArr
                );
            this.allQuestions = this.allQuestionnaireObj2;

            this.question = this.allQuestionnaireObj2[this.questArr[0].id];

            console.log(this.questArr);
            console.log("allQuestion" + this.allQuestions);
            console.log("question" + this.question);
        });

        firebase
            .auth()
            .signInWithEmailAndPassword(
                this.global.staticEmail,
                this.global.staticPass
            )
            .catch((error) => {
                //do something
            });

        this.patientForm = this.formBuilder.group({
            // Validators.min(36),Validators.max(108)
            inOffTemperature: ["", []],
            temperatureUnit: ["", [Validators.required]],
            spo: [
                "",
                [
                    Validators.min(1),
                    Validators.max(100),
                    Validators.maxLength(5),
                    Validators.pattern(/^\d*\.?\d*$/),
                ],
            ],
        });

      

        // if(this.queryParam['key'] === 'email'){
        //     this.showAsterisk = false;
        //     this.patientForm.get('inOffTemperature').setValidators(this.temperatureValidation);
        // }else{
        //     this.showAsterisk = true;
        //         this.patientForm.get('inOffTemperature').setValidators(this.temperatureValidation.concat(Validators.required));
        // }

        this.covidstaffService
            .getCovidResultByIdWithoutLogin(
                this.queryParam["docId"],
                this.queryParam["labName"]
            )
            .then((data: any) => {
                this.staffName = data.name;
                localStorage.setItem("staffEntryName", this.staffName);
                console.log(this.staffName);
                
            });
        console.log(this.queryParam);
        this.labName = this.queryParam["labName"];
        console.log("LAB_NAME=", this.labName);

        if (this.queryParam["docId"]) {
            this.docId = this.queryParam["docId"];
            console.log("DOCID==", this.docId);
        }

        if (this.queryParam["key"]) {
            this.key = this.queryParam["key"];
            console.log("KEY=", this.key);
        }

        
    }

    uploadSignature(fileData) {
        return new Promise((resolve, reject) => {
            const file = fileData.split(",")[1];
            const metadata = {
                contentType: "image/png",
            };
            const n = Date.now();
            const signatureRef = this.storage.ref(`/signature/${n}.png`);
            signatureRef
                .putString(file, "base64", metadata)
                .then((snapshot) => {
                    if (snapshot) {
                        signatureRef.getDownloadURL().subscribe((url) => {
                            resolve(url);
                        });
                    }
                })
                .catch((error) => {
                    //dp something
                });
        });
    }

    //   getAlltheStorageData(){
    //
    //
    //
    //       const storageRef = this.storage.ref('/Backup/20July/all_namespaces/all_kinds/output11');
    //

    // }

    onClickButton(cta: string, label: string, questionId: any) {
        console.log("cta=", cta, "label=", label);
        this.temperature = this.patientForm.get("inOffTemperature")?.value;
        console.log(this.temperature);
        if (this.temperature) {
            this.unit = this.patientForm.value.unit;
        } else {
            this.unit = "C";
        }

        // let questCta ={};

        const id = this.question.id;
        console.log("QuestionId=", id);
        const order = this.question.order;
        console.log("QUESTIONORDER=", order);
       this.description = this.question.description;
       
        console.log(this.description);
        // console.log(description);
        // this.createObj(id, label); // function called
        this.createQuestion(id, "");
        this.answerMap.set(order, label); 
        this.answerMap.forEach((value, key) => {  
            this.answers[key] = value  
}); 
console.log(this.answers) ;
const ansValue=Object.values(this.answers);
        console.log(ansValue);
        console.log(this.answerMap);
        ansValue.shift();
        console.log(ansValue);
     
        if (ansValue.includes("yes")) {
            this.symptom = "Yes";
            console.log(this.symptom);
            //localStorage.setItem('yesStaffans',this.symptom)
        } else {
            this.symptom = "No";
            console.log(this.symptom);
            //localStorage.setItem('noStaffans',this.symptom)
        }
    
        console.log(this.symptom);
        localStorage.setItem("staffAns", this.symptom);
        switch (cta) {
            case "r7":
                if (this.queryParam["key"] === "email") {
                    this.allQuestionnaireObj["addedOn"] =
                        -1 * new Date().getTime();
                    this.allQuestionnaireObj["preOffTime"] =
                        new Date().getTime();
                    this.allQuestionnaireObj["preStaffSign"] =
                        this.signatureImageUrlPat;
                    this.allQuestionnaireObj["preOffice"] = this.symptom;
                    this.allQuestionnaireObj["labName"] =
                        this.queryParam["labName"];
                    this.allQuestionnaireObj["preSpo"] = this.patientForm.value
                        .spo
                        ? this.patientForm.value.spo
                        : "";
                    this.allQuestionnaireObj["preOffTemperature"] =
                        this.patientForm.value.inOffTemperature;
                    this.allQuestionnaireObj["preOffTemperatureUnit"] =
                        this.patientForm.value.temperatureUnit;
                } else {
                    // this.allQuestionnaireObj['addedOn'] = -1* new Date().getTime();
                    this.allQuestionnaireObj["spo"] = this.patientForm.value.spo
                        ? this.patientForm.value.spo
                        : "";
                    // this.allQuestionnaireObj['name'] = localStorage.getItem('name');
                    this.allQuestionnaireObj["unitInOffice"] =
                        this.patientForm.value.temperatureUnit;
                    this.allQuestionnaireObj["staffSign"] =
                        this.signatureImageUrlPat;
                    this.allQuestionnaireObj["staffName"] =
                        this.userService.getCookie("staffName");
                    //console.log(this.allQuestionnaireObj['staffName']);
                    this.allQuestionnaireObj["inOffTemperature"] =
                        this.patientForm.value.inOffTemperature;
                    this.allQuestionnaireObj["inOffice"] = this.symptom;
                    this.allQuestionnaireObj["inOffTime"] =
                        new Date().getTime();
                    this.allQuestionnaireObj["labName"] =
                        this.queryParam["labName"];
                }

                // this.router.navigate(['/staffconfirmcard'], { queryParams: { token: this.token } });
                this.router.navigate(["/staffinit"], {
                    queryParams: { token: this.token },
                });
                break;
            default:
                if (localStorage.getItem("covidoptional") === "y") {
                    this.allQuestions[cta].question =
                        this.allQuestions[cta].question;
                }

                if (
                    this.allQuestions[cta].parent === undefined ||
                    Object.keys(this.allQuestions[cta].parent).length == 0
                ) {
                    this.question = this.allQuestions[cta];
                    console.log("Question=", this.question);
                } else {
                    console.log("Parent calling" + order + cta);
                    this.checkParent(cta, order, label);
                }
        }
    }

    onClickBack(id: string) {
        switch (id) {

            case 'r7':
                this.router.navigate(['/staffinit']);
                break;
            case 'startAssessment':
                this.router.navigate(['/covidstaffresultlist'], { queryParams: { token: this.token } });
                break;
            default:
                this.question = this.allQuestions[id];
        }

    }
    checkParent(cta:any, order:any, label:any){

        this.answerMap.forEach((value: string, key: string) => {
            console.log('testing ');
            console.log('CTA=',this.allQuestions[cta],'parent=',this.allQuestions[cta].parent);
            console.log("order   " + order);
            if(this.allQuestions[cta] !== undefined || this.allQuestions[cta] !== "" ){
                for(var key2 in this.allQuestions[cta].parent){
                    console.log("key " + key.toString().toUpperCase() + "key2 " + key2.toString().toUpperCase());
                    if(key.toString().toUpperCase() == key2.toString().toUpperCase()){
                        console.log("value " + value.toString().toUpperCase() + "value2 " + this.allQuestions[cta].parent[key2].toString().toUpperCase());
                        if(value.toString().toUpperCase() == this.allQuestions[cta].parent[key2].toString().toUpperCase()){
                            
                            this.question = this.allQuestions[cta];
                        }else{
                            this.createQuestion(this.questArr[order].id,label);
                            console.log("order" + order + "length" + this.questArr.length) ;
                            if(this.questArr.length !== (order +1)){
                              console.log('Parent check2=', this.allQuestions[this.questArr[order + 1].id].parent );
                                if(this.allQuestions[this.questArr[order + 1].id].parent === undefined || Object.keys(this.allQuestions[cta].parent).length==0 
                                || Object.keys(this.allQuestions[this.questArr[order + 1].id].parent).length == 0 ){
                                    this.question = this.allQuestions[this.questArr[order + 1].id]
                                    }else {
                                       // this.createQuestion(this.questArr[order+1].id,label);
                                    this.checkParent(this.questArr[order + 1].id, (order+1), label);
                                       
                                    }
                            } else {
                                console.log("Reached last question.?????");
                            
                                this.createObj(this.questArr[order].id, label);
                                this.createQuestion(this.questArr[order].id,label);
                               console.log("quetion hide");
      
    
                                switch ('r7') {
            
           
                                    case 'r7':
                                        console.log("next cta");
                                        this.router.navigate(['/staffinit'], { queryParams: { token: this.token } });
                                        break;
                                    }

                
                            }

                        }
                    }
                }
    
            }else{
                console.log("Reached last question last one.");
            }
          
        });
    }


    numericOnlyTemp(event): boolean {
        const unitVal: string = this.patientForm.value.temperatureUnit;
        if (this.patientForm.invalid) {
            this.patientForm.markAllAsTouched();
        }
        if (unitVal === "C") {
            this.minV = 34;
            this.maxV = 39;
            this.matError = "34-39C";
        } else if (unitVal === "F") {
            this.maxLengthTemp = 6;
            this.minV = 96;
            this.maxV = 108;
            this.matError = "96-108F";
        }

        const patt = /^\d*\.?\d*$/;
        const result = patt.test(event.key);
        return result;
    }

    createQuestion(key, selectedVal) {
        // console.log('question visibility function calling  222');

        if (
            selectedVal === "yes" ||
            selectedVal === "Yes" ||
            selectedVal === "no" ||
            selectedVal === "No"
        ) {
            console.log("quesvalue =", selectedVal);
            this.selectedValue = false;
        } else if (selectedVal == "") {
            this.selectedValue = true;
        } else {
            console.log("quesvalueElse =", selectedVal);
            this.selectedValue = false;
        }
        switch (this.action) {
            case "add":
            case "guest":
                return (this.allQuestionnaireObj[key].questionVisibility =
                    this.selectedValue);

                break;
            case "edit":
            case "start":
                return (this.allQuestionnaireObj[key].questionVisibility =
                    this.selectedValue);

                break;

            default:
                return;
        }
    }

    getCustomMessage(quest,risk) {
        console.log(quest,risk);
        this.customArr.push(risk);
    }
    getTitleMatIcon(): string {
        return 'assignment';
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    createObj(key, selectedVal) {
        if (selectedVal === "yes")
         {
             selectedVal = true; this.getCustomMessage( this.allQuestionnaireObj2[key], this.allQuestionnaireObj2[key].risk );
        } else {
            selectedVal = false;
        }

        switch (this.action) {
            case "add":
            case "guest":
                return (this.allQuestionnaireObj[key].preAppointSelection =
                    selectedVal);
                break;
            case "edit":
            case "start":
                this.arrTest.push(
                    (this.allQuestionnaireObj[key].inOfficeSelection =
                        selectedVal)
                );
                this.arrTest.push(
                    (this.allQuestionnaireObj[key].isSelectedYesorNo =
                        selectedVal)
                );
                return this.arrTest;
                break;

            default:
                return;
        }
    }

    onChangeSelect(eventVal) {
        this.inOffTemperature = "";
        if (eventVal === "C") {
            this.minV = 34;
            this.maxV = 39;
            this.matError = "34-39C";
        } else if (eventVal === "F") {
            this.minV = 96;
            this.maxV = 108;
            this.matError = "96-108F";
        }

        // this.matError = '';
    }

    replaceText(str) {
        const pattern = /{staffName}/g;

        let staffName = "";
        switch (this.action) {
            case "edit":
                if (this.allQuestionnaireObj) {
                    // fullName = this.allQuestionnaireObj.staffName + ' ' + this.allQuestionnaireObj.lastName;
                } else {
                    staffName = "staff";
                }

                break;
            case "add":
            case "start":
                if (this.resultData) {
                    staffName = this.resultData.staffName 
                } else {
                    staffName = "staff";
                }
                break;
        }
        const newStr = str.replace(pattern, staffName);
        return newStr;
    }

    submit() {
        const selectedOrderIds = this.form.value.orders
            .map((v, i) => (v ? this.ordersData[i].id : null))
            .filter((v) => v !== null);
    }

    setFocus(id) {
        const element = document.getElementById(id);
        // element.focus();
        console.log(element);
        element.scrollIntoView();
    }

    setFocusElement(id) {
        const element = document.getElementById(id);
        element.focus();
    }
}
