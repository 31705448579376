import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewEncapsulation,
    ViewChild,
    Input,
    Output,
    EventEmitter, OnDestroy,
} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FuseConfigService } from '@fuse/services/config.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CovidService } from '../../../covid.service';
import { Globals } from '../../../../common/global';
import { MatDialog } from '@angular/material/dialog';
import { DialogContentCovidComponent } from '../nameConfirmationDialog/dialog-content-covid.component';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PatientService } from '../../../../sterilwize/patient/patient.service';

import * as firebase from 'firebase';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { ApiService} from '../../../../common/service/api.service';

@Component({
    selector: ' covid-assessment-start',
    templateUrl: './self-assessment-init.component.html',
    styleUrls: ['./self-assessment-init.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:orientationchange)': 'isMobileLandscapeFunction($event)'
    }
})
export class SelfAssessmentInitComponent implements AfterViewInit, OnInit, OnDestroy {
    patientForm: FormGroup;
    patientFormEdit: FormGroup;
    checkboxForm: FormGroup;
    urlData = '';
    mobileError: any;
    cassetteData: any;
    barcode = '';
    cycleNo = '';
    name = '';
    id = '';
    time = '';
    type = '';
    program = '';
    loadNo: number;
    machineName = '';
    status = '';
    result = ' ';
    barcodeBi: any;
    barcodeEi: any;
    barcodeBd: any;
    isAssignDone: boolean;
    statusBi: string;
    statusEi: string;
    statusBd: string;
    date = new Date();
    patientName = '';
    patientId = '';
    assignedBy = '';
    assignedOn: any;
    status1 = '';
    email = this.userService.getCookie("email");
    urlPatientId = '';
    urlLabName = '';
    action = '';
    fullName = '';
    direct: any;
    token = '';
    queryParam = {};
    urlStr = '';
    firstName = '';
    lastName = '';
    tempPlaceholder = '';
    logoUrl = '';
    siteUrl = '';
    isReadOnly = true;
    sharedEmail = '';
    mobile = '';
    signatureImage = '';
    alertSign = '';
    relationshipName = '';
    covidoptionalVal = '';
    selfChecked = true;
    ageValue: any;
    doa = '';
    dialogResult = false;
    isMobileLandscapeModeActivated = false;
    // staticEmail = 'sterilwize@xccelerata.com';
    // staticPass = 'abc123';
    acknowledgement: Array<any> = [];

    // acknowledgement = acknowledge;
    checkedList: Array<any> = [];
    @Input()
    min: 'D' | null;
    today = new Date();
    consentValid = false;
    consentValidMsg = '';
    resultData: any;
    patientFullName = 'user';
    allQuestionnaireObj: any;
    dob: any;
    checkExist: any;
    docId = '';
    checkdob: any;
    // for datepicker Acknowledgement
    @Output()
    dateChange: EventEmitter<MatDatepickerInputEvent<any>>;

    @ViewChild(SignaturePad) public signaturePad: SignaturePad;
    public signaturePadOptions: object = { // passed through to signature_pad constructor
        'minWidth': 1,
        'canvasWidth': 250,
        'canvasHeight': 150,
        backgroundColor: 'gray',
    };
    loadingSpinner: boolean = false;
    relationship: any = '';

    constructor(public covidService: CovidService,
        private route: ActivatedRoute, private router: Router,
        private snackBar: MatSnackBar, private _fuseConfigService: FuseConfigService,
        private cookieService: CookieService,
        private _formBuilder: FormBuilder, public globals: Globals,
        public dialog: MatDialog, public patientService: PatientService,
        private userService: StorageService, private apiService: ApiService,
    ) {

        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    async proxyLogin(email, pass) {
        await firebase.auth().signInWithEmailAndPassword(this.globals.staticEmail, this.globals.staticPass)
            .then(async authD => {
                //dp something

            })
            .catch(error => {
                //dp something
                console.log(' proxy error calling =',error);
                this.loadingSpinner = true;
                // window.location.reload();
            });
    }

    ngOnInit(): void {
        console.log("email  " + this.globals.staticEmail + "  pass   "  + this.globals.staticPass);
      //  this.loadingSpinner=false;
        this.proxyLogin(this.globals.staticEmail, this.globals.staticPass);

        this.token = this.route.snapshot.queryParams.token;
        console.log(this.token);
        if (this.token === undefined) {
            this.isReadOnly = false;
        }
        if (this.token) {
            const keyVal = this.globals.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});


            // localStorage.setItem('screeningLab', this.queryParam['labName']);
            this.userService.setCookie('screeningLab', this.queryParam['labName']);
            this.covidService.getAllConsentData(this.queryParam['labName']).then(consentData => {
                this.acknowledgement = consentData;
            }).catch(error => {
                //dp something
                console.log('getAllConsentData error calling =',error);
                this.loadingSpinner = true;
                // window.location.reload();
            });

            // to check patient exist ?
            if (this.queryParam['docId']) {
                this.docId = this.queryParam['docId'];
              
                this.covidService.checkCovidResultByIdWithoutLogin(this.queryParam['docId'], this.queryParam['labName']).then(async (checkExist: any) => {
                    this.checkExist = await checkExist;
                    this.doa = this.checkExist.doa;
                }).catch(error => {
                    //dp something
                    console.log(' WithoutLogin error calling =',error);
                    this.loadingSpinner = true;
                    // window.location.reload();
                });
            }
            if (this.queryParam['key'] === 'edit') {
                this.covidService.getCovidResultById(this.queryParam['patient']).then(async (editData: any) => {
                    this.allQuestionnaireObj = await editData;
                    if (this.allQuestionnaireObj) {
                        this.firstName = this.allQuestionnaireObj.firstName ? this.allQuestionnaireObj.firstName : '';
                        this.lastName = this.allQuestionnaireObj.lastName ? this.allQuestionnaireObj.lastName : '';
                        this.mobile = this.allQuestionnaireObj.phone ? this.allQuestionnaireObj.phone : '';
                        this.sharedEmail = this.allQuestionnaireObj.email ? this.allQuestionnaireObj.email : '';
                        // this.dob = this.allQuestionnaireObj.ageStamp ? this.allQuestionnaireObj.ageStamp : '';
                        this.dob = this.allQuestionnaireObj.ageStamp ? this.allQuestionnaireObj.ageStamp : '';
                        this.patientFullName = this.allQuestionnaireObj.firstName + ' ' + this.allQuestionnaireObj.lastName;
                    }
                }).catch(error => {
                    //dp something
                    console.log(' getCovidResultById error calling =',error);
                    this.loadingSpinner = true;
                    // window.location.reload();
                });
            } else {

                this.patientService.getPatientByIdWithoutLogin(this.queryParam['patient'], this.queryParam['labName']).then(async (resultData: any) => {
                    console.log("getpatientWithoutLogin " + resultData);
                    this.resultData = await resultData;
                    if (this.resultData) {
                        this.firstName = this.resultData.firstName ? this.resultData.firstName : this.queryParam['firstName'];
                        this.lastName = this.resultData.lastName ? this.resultData.lastName : this.queryParam['lastName'];
                        this.mobile = this.resultData.mobileNo ? this.resultData.mobileNo : this.queryParam['mobile'];
                        this.sharedEmail = this.resultData.emailId ? this.resultData.emailId : this.queryParam['email'];

                        // this.dob = this.calculateAge(this.resultData.dob) ? this.calculateAge(this.resultData.dob) : '';
                        if(this.resultData.dobString){
                            console.log(this.resultData.dobString);
                            this.dob = new Date(this.apiService.swapMonthandDate(this.resultData.dobString));
                        }else{
                            this.dob = this.resultData.dob ? new Date(this.resultData.dob) : '';
                        }
                        if (this.checkExist) {
                            this.doa = this.checkExist.doa ? this.checkExist.doa : '';
                        }
                        this.ageValue = this.resultData.dob ? this.calculateAge(this.resultData.dob) : '';
                        this.patientFullName = this.resultData.firstName + ' ' + this.resultData.lastName;
                        console.log("end or patientwihtoutlogin");
                        if (this.queryParam['key'] === 'add') {
                            sessionStorage.setItem('checkdob', this.ageValue);
                           
                            this.openDialog(this.patientFullName);
                        }

                        
                    }
                }).catch(error => {
                    //dp something
                    console.log('----',error);
                    this.loadingSpinner = true;
                    // window.location.reload();
                });
            }
        }
        if (this.queryParam['type']) {
            this.direct = this.queryParam['type'];
        }

        this.action = this.queryParam['key'];
        this.urlPatientId = this.queryParam['patient'];
        this.urlLabName = this.queryParam['labName'];

        this.relationshipName = this.firstName + ' ' + this.lastName ? this.firstName + ' ' + this.lastName : 'patient';


        if (this.token === undefined) {
            this.relationshipName = 'patient';
        }
        // open dialog if user come from shared email
        // if (this.queryParam['key'] === 'add') {
        //     this.openDialog();
        // }

        this.siteUrl = this.globals.siteLogo;
        const collectionPath = `/lab/${this.urlLabName}/config/`;
        const logoPath = this.covidService.getLabLogo(collectionPath);
        logoPath.ref.get().then(data => {
            const logoPathName = data.data();
            if (logoPathName) {
                this.logoUrl = logoPathName.logoUrl;
            }
        }).catch(error => {
            //dp something
            console.log(' getLabLogo',error);
            this.loadingSpinner = true;
            // window.location.reload();
        });


        this.patientForm = this._formBuilder.group({

            firstName: ['', [Validators.required, Validators.pattern(/^\S+/)], ''],
            email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
            lastName: ['', [Validators.pattern(/^\S+/)]],
            // mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)], ''],
            mobile: ['', [Validators.required], ''],
            // temperature: ['', [Validators.required, Validators.pattern(/^-?\d*(\.\d+)?$/), Validators.min(95), Validators.max(106)], ''],
            // staff: ['', [Validators.required],''],
            relationship: ['', [Validators.pattern(/^\S+/)]],
            // dob: ['', [Validators.required,Validators.pattern(/^[0-9]\d*$/),Validators.minLength(1), Validators.maxLength(3),Validators.max(105)],],
            dob: ['', [Validators.required],],

            // acknowledgementChek:[[],[Validators.required]],
        });
        // if(this.action === 'add'){
        this.checkboxForm = this._formBuilder.group({
            optionalDate: ['', ''],
            acknowledgementChek: this._formBuilder.array([], [Validators.required]),
        });
        // }


        // }


        // this.covidService.getAllQuestionData(this.queryParam['labName']).then(questionData =>{
        //     sessionStorage.setItem('questions',JSON.stringify(questionData));
        // })
    }

    isMobileLandscapeFunction(event) {

        if (screen.orientation && screen.orientation.type && (screen.orientation.angle === 90 || screen.orientation.angle === -90) && this.isMobile() && screen.width < 820) {
            // alert('Kindly Change the landscape orientation. This app is not compatible for mobile landscape mode');
            this.isMobileLandscapeModeActivated = true;
            this.dialog.closeAll();

        }
        else {
            this.isMobileLandscapeModeActivated = false;
        }
    }
    isMobile() {
        const mobileCheck = {
            Android: () => navigator.userAgent.match(/Android/i),
            BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
            iOS: () => navigator.userAgent.match(/iPhone/i),
            Opera: () => navigator.userAgent.match(/Opera Mini/i),
            Windows: () => navigator.userAgent.match(/IEMobile/i),
            detectmob: () => window.innerWidth <= 799,
            any: () => mobileCheck.Android() || mobileCheck.BlackBerry() || mobileCheck.iOS() || mobileCheck.Opera() || mobileCheck.Windows() || mobileCheck.detectmob(),
        };
        return mobileCheck.any() ? true : false;
    };

    clickDobDatepicker(event) {
        this.ageValue = this.calculateAge(moment(event.value));
        sessionStorage.setItem('checkdob', this.ageValue);
        return this.ageValue
    }

    ngAfterViewInit(): void {
        if (this.urlData !== '' && this.urlData !== undefined) {
            //dp something
        } else {
            //dp something
            // window.alert('No Data found, try again!');
        }
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    startAssessmentEdit() {
        // const temperature = this.patientFormEdit.value.temperature;
        this.urlStr = 'key#' + this.action + '##patient#' + this.urlPatientId + '##labName#' + this.urlLabName;
        this.router.navigate(['/covid-assessment'], { queryParams: { token: this.globals.encodeToa(this.urlStr) } });
    }

    startAssessment() {
        
        const email = this.patientForm.value.email;
        const phone: number = this.patientForm.value.mobile;
        const firstName = this.patientForm.value.firstName;
        const lastName = this.patientForm.value.lastName.trim();
        const dob = moment(this.patientForm.value.dob).toDate();

        console.log(dob);
        const relationship = this.patientForm.value.relationship;
        localStorage.setItem('relationship', relationship);
        if (lastName) {
            this.fullName = firstName + '-' + lastName;
        } else {
            this.fullName = firstName;
        }
        localStorage.setItem('fullName', this.fullName);
        if (this.action === undefined) {
            this.action = 'guest';
            this.urlLabName = 'covidGuest';
            if (lastName) {
                this.urlPatientId = firstName + '-' + lastName;
            } else {
                this.urlPatientId = firstName;
            }
            // const urlStrGuest='key#'+this.action+'##patient#'+this.urlPatientId+'##labName#' + this.urlLabName+'##email#'+email+'##phone#'+ phone+'##temperature#'+temperature;
            const urlStrGuest = 'key#' + this.action + '##patient#' + this.urlPatientId + '##labName#' + this.urlLabName + '##email#' + email + '##phone#' + phone + '##dob#' + dob;

            console.log(urlStrGuest);
            // this.router.navigate(['/covid-assessment'], { queryParams: {key: this.action,patient: this.urlPatientId , labName: this.urlLabName,email:email, phone:phone }});
            this.router.navigate(['/covid-assessment'], { queryParams: { token: this.globals.encodeToa(urlStrGuest) } });

        } else {
            if (this.direct && this.direct !== undefined) {
                this.urlStr = 'key#' + this.action + '##patient#' + this.urlPatientId + '##labName#' + this.urlLabName + '##email#' + email + '##phone#' + phone + '##type#' + this.direct + '##dob#' + dob;
            } else {
                this.urlStr = 'key#' + this.action + '##patient#' + this.urlPatientId + '##labName#' + this.urlLabName + '##email#' + email + '##phone#' + phone + '##dob#' + dob + '##docId#' + this.docId + '##doa#' + this.doa;
            }
            // const urlStr = 'key#'+this.action+'##patient#'+this.urlPatientId+'##labName#'+this.urlLabName+'##email#'+email+'##phone#'+phone+'##type#'+this.direct;
            // return;
            console.log(this.urlStr);
            this.router.navigate(['/covid-assessment'], { queryParams: { token: this.globals.encodeToa(this.urlStr) } });
        }
    }

    openDialog(fullName) {
        this.loadingSpinner=true;
      console.log('FullName=',fullName);
        const dialogRef = this.dialog.open(DialogContentCovidComponent,
            {
                disableClose: true,
                data: { patientId: this.urlPatientId, fullName: fullName },
                width: '450px',
            });
        dialogRef.afterClosed().subscribe(result => {

            this.dialogResult = result;
            if (result) {
                const relationship = this.patientForm.get('relationship');
                relationship.setValidators([Validators.required]);

                this.isReadOnly = false;
                localStorage.setItem('covidoptional', 'y');
                // alert(`Prior to the appointment for ${this.firstName} ${this.lastName} we need this Covid 19 assessment completed. Please state your First last name, phone no. and relationship with ${this.firstName} ${this.lastName}.`);
                // alert(`Please fill in your details below before starting the assessment`);
                this.firstName = '';
                this.lastName = '';
                    this.patientForm.get('firstName').setValue("") ;
                    this.patientForm.get('lastName').setValue("")
                    this.setFocus('firstName');
                    this.setFocus('lastName');
                sessionStorage.setItem('questName', this.checkExist.firstName + ' ' + this.checkExist.lastName);

            } else {

                localStorage.setItem('covidoptional', 'n');
            }

            this.covidoptionalVal = localStorage.getItem('covidoptional');
        });
    }

    numericOnlyTemp(event): boolean {
        const patt = /^-?\d*(\.\d+)?$/;
        const result = patt.test(event.key);
        return result;
    }

    numericOnly(event): boolean {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            var charCode = (event.which) ? event.which : event.keyCode;
            // Only Numbers 0-9
            if ((charCode < 48 || charCode > 57)) {
                event.preventDefault();
                return false;
            } else {
                return result;
            }
        }
        return result;
    }
    numericOnlyMobileKeyUp(event): boolean {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            const patt = /^([0-9])$/;
            const result = patt.test(event.key);
            this.mobileError = 'Please enter numbers only';
            return result;
        }
    }

    showImage(data, pad) {
        this.signatureImage = data;

        if (pad.signaturePad.isEmpty() || this.patientForm.invalid) {

                    if (pad.signaturePad.isEmpty()) {
                        this.alertSign = 'signature required';
                       // this.setFocus(pad.signaturePad);
                    }
                    if (this.patientForm.invalid) {
                         console.log(this.firstName+'F-'+this.lastName+'L-'+this.dob+'dob-'+this.mobile+'mob='+this.sharedEmail,this.relationship);
                        // let emptyFields ='';
        
                        if(!this.firstName){
                            // emptyFields +='FirstName, ';
                            this.setFocus('firstName');
        
                        }
        
                        if(!this.sharedEmail){
                            this.setFocus('email');
                            // emptyFields +='Email, ';
                        }
                        if(!this.mobile){
                            this.setFocus('mobile');
                            // emptyFields +='Mobile,';
                        }
                        if(!this.dob){
                            this.setFocus('dob');
                            // emptyFields +='DOB, ';
                        } 
                        if(!this.relationship){
                            this.setFocus('relationship')
                        }        
                        // window.alert(`Please provide ${emptyFields} fields value`);
                        this.patientForm.markAllAsTouched();
                    }
                }
                else {
        
                    // update patient data SOC
                    const patt = /^[0-9]\d*$/;
                    let customMob = '';
                    if (patt.test(this.patientForm.value.mobile)) {
                        customMob = this.patientForm.value.mobile;
                    }
                    console.log(moment(this.patientForm.value.dob, 'DD/MM/YYYY').format('DD/MM/YYYY'));
                    const updateData: any = { 
                        emailId: this.patientForm.value.email,
                        mobileNo: customMob.toString(),
                        dob: moment(this.patientForm.value.dob).toDate().getTime(),
                        dobString : moment(this.patientForm.value.dob, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    };
                    console.log(updateData);
                    this.patientService.updatePersonalInfo(updateData, this.queryParam['patient'], this.queryParam['labName']).then(data1 => {
                        //dp something
                        console.log(data1);
                    })
        
                    // update patient data EOC
                    this.alertSign = '';
                    
                    console.log('patientSign==',this.signatureImage);
                    if (this.checkboxForm.value.optionalDate) {
                        const covidTestDate = moment(this.checkboxForm.value.optionalDate).toDate();
                        localStorage.setItem('optionalDate', moment(covidTestDate).toDate().getTime().toString());
                    }
        
                    localStorage.setItem('signatureImagePat', this.signatureImage);
                    this.startAssessment();
                }
               

 }

   


    onCheckboxChange(option, event) {
        if (event.checked) {
            this.checkedList.push(option.value);
            if (this.checkedList.includes(7) && !this.checkboxForm.value.optionalDate) {
                this.consentValidMsg = 'Select a date';
                this.selfChecked = false;
            }

        } else {
            for (let i = 0; i < this.acknowledgement.length; i++) {
                if (this.checkedList[i] === option.value) {
                    this.checkedList.splice(i, 1);
                } if (option.value === 7) {

                    this.consentValidMsg = '';
                    this.checkboxForm.reset('optionalDate');
                    this.selfChecked = true;
                }
            }
        }
    }

    clearMsg() {
        this.consentValidMsg = '';
    }
    testFoo(pad) {



        if(document.getElementById("relationship") != null){
        
        const element5 = document.getElementById("relationship");
        
        const element = document.getElementById("firstName");
        
        const element2 = document.getElementById("email");
        
        const element3 = document.getElementById("mobile");
        
        const element4 = document.getElementById("dob");
        
        if ( this.patientForm.valid && !pad.signaturePad.isEmpty() ) {
        
        element5.blur();
        
        element.blur();
        
        element2.blur();
        
        element3.blur();
        
        element4.blur();
        
        // this.setFocus(pad.signaturePad);
        
        }
        
        }
        
        }

        editFirstName(){

            this.patientForm.controls["firstName"].enabled;
            
            // document.getElementById("firstName").focus();
            
            }
        
            editEmail(){
            
            this.patientForm.controls["email"].enabled;
            
            }
            
            editMobile(){
            
            this.patientForm.controls["mobile"].enabled;
            
            }

            // editRelation(){

            //     this.patientForm.controls["relationship"].enabled;
                
            //     }
   
                editDob(){
                
                this.patientForm.controls["dob"].enabled;
                
                }
    calculateAge(dob) {
        if (dob) {
            const diffMs = Date.now() - dob;
            const ageDt = new Date(diffMs);
            return Math.abs(ageDt.getUTCFullYear() - 1970);
        }

    }


    setFocus(id) {
        const element = document.getElementById(id);
        element.focus();
    }

    ngOnDestroy(): void {

        // firebase.auth().signOut().then(()=> {
        //     // Sign-out successful.
        // }).catch((error) => {
        //     // An error happened.
        // });
    }

}
