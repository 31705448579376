import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable({ providedIn: 'root' })
export class FirebaseUtilService {

    public static LAB_PLACE_HOLDER = '{labId}';
    hasFirstDataShown = false;
    constructor(private afs: AngularFirestore, private userService: StorageService) {
    }

    public getById(path: string, docId: string): any {
        return this.afs.collection(path)
            .doc(docId)
            .valueChanges()
            .subscribe(item => {
                // return item;
                // // If you prefer including itemId back to object
                // return {...item, id: docId}
            });
    }

    public getObserableId(path: string, docId: string): Observable<any> {
        return this.afs.collection(this.refactorPath(path))
            .doc(docId)
            .valueChanges();
    }
    public async getDocument(path: string, docId: string) {
        let document = await this.afs.collection(path).doc(docId).get().toPromise();
        return await document.data();
    }

    addDocument(path: string, docId: string, data: any) {
        return new Promise((resolve, reject) => {
            this.afs.collection(path).doc(docId).set(data).then((resultData) => {
                const data = { msg: 'Patient Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });

        });
    }

    saveDocument(path: string, docId: string, data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.hasFirstDataShown = false;
            this.afs.collection(this.refactorPath(path)).doc(docId).update(data).then((resultData) => {
                this.hasFirstDataShown = true;
                const data = { msg: 'Patient Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                this.hasFirstDataShown = true;
                reject(error);
            });

        });
    }

    refactorPathV2(path: string): string {
        // getting from patient screening , accssing thru email ink
        return path.replace(FirebaseUtilService.LAB_PLACE_HOLDER, this.userService.getCookie("screeningLab"));
    }

    refactorPath(path: string): string {
        let lab = '';
        if (this.userService.getCookie("screeningLab")) {
            lab = this.userService.getCookie("screeningLab");
        } else {
            lab = this.userService.getCookie("lab") || localStorage.getItem('lab');
        }
        return path.replace(FirebaseUtilService.LAB_PLACE_HOLDER, lab);
    }

    getDocumentsForAll(path: string): Observable<any[]> {
        return this.afs.collection(this.refactorPathV2(path), ref => ref).snapshotChanges()

            .pipe(map(actions => {
                return actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    const doc = a.payload.doc;
                    return { id, ...data as {}, doc };
                });
            }));
    }

    getDocuments(path: string): Observable<any[]> {

        return this.afs.collection(this.refactorPathV2(path), ref => ref.orderBy('order')).snapshotChanges()

            .pipe(map(actions => {
                console.log(actions)
                return actions.map(a => {
                    console.log(a.payload.doc.data());

                    if (parseInt(sessionStorage.getItem('checkdob'), 10) < 70) {
                        if (a.payload.doc.data()['minAge'] === 0) {
                            const data = a.payload.doc.data();
                            const id = a.payload.doc.id;
                            const doc = a.payload.doc;
                            return { id, ...data as {}, doc };
                        }
                    } else {

                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        const doc = a.payload.doc;
                        return { id, ...data as {}, doc };
                    }

                });
            }));

    }

    getTimeWithAmPm(date, condition:boolean) {

        let hours = date.getHours();
        let minutes = date.getMinutes();

        if(condition){
            const ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            const dateStr = hours + ':' + minutes + ' ' + ampm;
            return dateStr;
        }
        return  this.addZeroToHourAndMinutes(hours) + ':' + this.addZeroToHourAndMinutes(minutes);
    }

    addZeroToHourAndMinutes(time) {
        if (time < 10) {
            return '0' + time;
        }
        return time;
    }


}
