import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
// import {CovidDto} from '../covid/CovidDto.modal';
import { CovidStaffDto } from './CovidSatffDto.modal';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QueryFn } from '@angular/fire/firestore/interfaces';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../common/global';
import { ApiService } from '../../common/service/api.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { FirebaseUtilService } from '../../common/service/FirebaseUtil.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable({ providedIn: CovidstaffService })
export class CovidstaffService implements Resolve<any> {
    private itemsSubject: BehaviorSubject<CovidStaffDto[]>;
    public itemsObservable: Observable<CovidStaffDto[]>;
    data: any;
    onDataChanged: BehaviorSubject<any>;
    private searchSubject: BehaviorSubject<CovidStaffDto[]>;
    public searchObservable: Observable<CovidStaffDto[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    table = 'staffResult';
    resultTable = 'staffResult';
    orderBy = 'addedOn';
    currentPage = 0;
    public notFoundSubject: BehaviorSubject<boolean>;
    public notFoundObservable: Observable<boolean>;
    protected isOrderByNegative = true;
    protected searchBy = 'barcode';
    public searchedText: string = null;
    routeParams: any;

    constructor(private afs: AngularFirestore, public globals: Globals,
        private userService: StorageService,
        public firebaseUtil: FirebaseUtilService,
        public storageServise: StorageService,
        public httpClient: HttpClient, public apiService: ApiService) {

            this.onDataChanged = new BehaviorSubject({});
    }
     /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
   
      resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        console.log(this.routeParams);
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getData(this.routeParams['labName'])
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */

    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }

    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }


    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const docId = a.payload.doc.id;
                const doc = a.payload.doc;
                return { docId, ...data as {}, doc };
            });
        }));
    }
    
    getSearchCollection(CovidStaffDto): Observable<any[]> {
        if (CovidStaffDto) {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .where(this.covidDtoSearchQuery(CovidStaffDto.toUpperCase()), '==', true)
                .limit(1000));
            // .limit(this.ROWS));

        } else {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .limit(this.ROWS));
        }
    }
    getData(labName:string): Promise<any> {

       // console.log("anjali" +`/lab/${FirebaseUtilService.LAB_PLACE_HOLDER}/covid/constant/staffQuestion`)
        return new Promise((resolve, reject) => {
            this.getDocumentsStaff('lab/'+ labName +'/covid/constant/staffQuestion')
                .subscribe((documents) => {
                    console.log(documents);
                    //  const questions = this.getQuestionObjFromDocuments(documents);
                    // console.log(questions.order);
                    // const result = {
                    //     questionArray: documents,
                    //     questionObj: questions
                    // };
                    // console.log(result);
                     this.data = documents;
                    // // this.onDataChanged.next(this.data);
                    console.log(this.data);
                    
                    resolve(this.data);
                }, reject);
        });
    }

    getQuestionObjFromDocuments(documents: any[]) :any {
console.log('Questions====',documents);
console.log('length==',documents.length);
        
        const questions = {};
        for (let i = 0; i < documents.length; i++) {
            const questionObj = documents[i];
            const id = questionObj.id;
            const prevId = (i == 0) ? 'startAssessment' : documents[i - 1].id;
            const nextId = (i == documents.length - 1) ? 'r7' : documents[i + 1].id;
            console.log(questionObj);
        
                const question = {
                    id: questionObj.id,
                    questionNo: '' + (i +1 ),
                    questionId: id,
                   // type: 'yesNo',
                    question: questionObj.title,
                    description: questionObj.description,
                    order: questionObj.order,
                    risk:questionObj.risk,
                    parent: questionObj.parent,
                    buttons: [
                        {
                            label: 'no',
                            cta: nextId,
                        },
                        {
                            label: 'yes',
                            cta: nextId,
                        },
                    ],
                    preAppointSelection: false,
                    inOfficeSelection: false,
                    // risk: this.getRiskLevel(questionObj.risk),
                    backButtonCta: prevId
                };

                console.log("questionmmmmm.." + question);
                // adding children if question have
                console.log()
            if(questionObj.children){
                
                question['questionItems'] = questionObj.children;
            }

            questions[id] = question;
          
        }
        return questions;
    }

    getRiskLevel(risk: string) {
        switch (risk) {
            case 'High':
                return 'high';
            case 'Probable':
                return 'medium';
            case 'Moderate':
                return 'medium';
            case 'Low':
                return 'low';

        }
    }

    getDocumentsStaff(path: string): Observable<any[]> {
        console.log(path);

        return this.afs.collection(this.refactorPathV2LAb(path), ref => ref.orderBy('order')).snapshotChanges()

            .pipe(map(actions => {
                return actions.map(a => {
                    console.log(a.payload.doc.data());
                    const data = a.payload.doc.data();
                    console.log('DATA', data);
                        const id = a.payload.doc.id;
                        const doc = a.payload.doc;
                        return { id, ...data as {}, doc };
                });
            }));

     }    
     
     refactorPathV2LAb(path: string): string {
        // getting from patient screening , accssing thru email ink
        return path.replace(FirebaseUtilService.LAB_PLACE_HOLDER, this.userService.getCookie("screeningLab"));
    }
    getCovidDto(covidDto): Observable<CovidStaffDto[]> {
        this.searchSubject = new BehaviorSubject([]);
        this.searchObservable = this.searchSubject.asObservable();
        const ref = this.getSearchCollection(covidDto.toUpperCase())
            .subscribe(data => {
                if (data && data.length > 0) {
                    // this.searchSubject.next(data);

                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.searchSubject.next(data);

                } else {
                    this.searchSubject.next(null);

                    this.notFoundSubject.next(true);
                    this.notFoundSubject.next(false);
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }

    search(): any {
        if (this.searchedText) {

            this.getCovidDto(this.searchedText).subscribe(covidDto => {
                this.itemsSubject.next(covidDto);
            });
        } else {
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .limit(this.ROWS))
                .subscribe(data => {
                    this.pageSubject.next(0);
                    if (data) {
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.itemsSubject.next(data);
                    } else {
                        // do something

                    }
                    // scoresRef.unsubscribe();
                });
        }
    }

    first(): void {
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();
        this.notFoundSubject = new BehaviorSubject<boolean>(false);
        this.notFoundObservable = this.notFoundSubject.asObservable();
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .limit(this.ROWS)
        )
            .subscribe(data => {
                this.pageSubject.next(0);
                if (data) {


                    this.latestEntry = data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.itemsSubject.next(data);
                } else {
                    // do something
                }
                // scoresRef.unsubscribe();
            });
    }

    next(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .startAfter(this.latestEntry)
            .limit(this.ROWS))
            .subscribe(data => {
                try {
                    if (data && data.length) {
                        this.currentPage++;

                        // And save it again for more queries
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.pageSubject.next(this.currentPage);
                        this.itemsSubject.next(data);
                    }
                } catch (e) {
                    // do something
                }
                this.itemsSubject.next(data);
                // scoresRef.unsubscribe();
            });
    }


    prev(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            // Now you can use the latestEntry to query with startAfter
            .startAfter(this.startingEntry)
            .limit(this.ROWS))
            .subscribe(data => {
                try {
                    if (data && data.length) {
                        data.reverse();
                        // And save it again for more queries
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.currentPage--;
                        this.pageSubject.next(this.currentPage);
                        this.itemsSubject.next(data);
                    }
                } catch (e) {
                    // do something
                }
                this.itemsSubject.next(data);
                // scoresRef.unsubscribe();
            });
    }


    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/covid/result/' + this.table;
    }

    covidDtoSearchQuery(covidDto): string {
        return 'anyMap.' + covidDto;
    }


    //    add covidDto starts

    addCovidDto(covidDtoData: any, docId) {

        const covidDtoCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(covidDtoCollectionPath).doc(docId).set(covidDtoData).then((resultData) => {
                const data = { msg: 'CovidDto Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });

        });
    }

    getCovidDtoById(docId) {
        const covidDtoCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(covidDtoCollectionPath).doc(docId).valueChanges().subscribe(data => {

                resolve(data);
            });
        });
    }

    deleteCovidDtoById(docId) {
        const covidDtoCollectionPath = this.getCollectionPath();
        // this.afs.collection(covidDtoCollectionPath).doc(docId).delete().then(() => {
        // }).catch((error)=> {

        // })

        return this.afs.collection(covidDtoCollectionPath).doc(docId).delete();


    }


    //    add patient starts

    getCovidResultPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/covid/result/' + this.resultTable;
    }

    getCovidGuestResultPath(): string {
        return 'covidGuest/';
    }

    addCovidResult(covidResultData: any) {
        const patientCollectionPath = this.getCovidResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(resultData);
            }).catch((error) => {
                reject(error);
            });

        });
    }

    addStaffCovidResultWithoutLogin(covidResultData: any, labName) {
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(resultData);
            }).catch((error) => {
                reject(error);
            });

        });
    }


    addCovidGuestResult(covidResultData: any) {
        const patientCollectionPath = this.getCovidGuestResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });

        });
    }


    getCovidResultById(docId) {
        const patientCollectionPath = this.getCovidResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).valueChanges().subscribe(data => {

                resolve(data);
            });
        });
    }


    checkCovidResultByIdWithoutLogin(docId, labName) {
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;

        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).get().subscribe(async (data) => {
                resolve(await data.data());

            });
        });
    }

    getCovidResultByIdWithoutLogin(docId, labName) {
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;

        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).valueChanges().subscribe(data => {

                resolve(data);
            });
        });
    }

    updateCovidResult(covidData, covidId) {
        const patientCollectionPath = this.getCovidResultPath();
        return this.afs.collection(patientCollectionPath).doc(covidId).update(covidData);
    }

    updateCovidStaffResultWithoutLogin(covidData, covidId, labName) {
    //    covidData=JSON.stringify(covidData);
       var arr =[];
       arr.push(covidData);
        console.log("covidData=",JSON.stringify(covidData)+' covidId='+covidId+' labName= '+labName)
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;
        console.log(' path ='+patientCollectionPath +' ')
        let mac:any=[];
        mac=covidData;

        return this.afs.collection(patientCollectionPath).doc(covidId).update(covidData).then(data =>{
            console.log(data)
        });
        // return this.afs.collection(patientCollectionPath).doc(covidId).update(covidData);
    }


    setCovidResultWithoutLogin(covidData, covidId, labName) {
        const patientCollectionPath = 'lab/' + labName + '/' + this.resultTable;
        return this.afs.collection(patientCollectionPath).doc(covidId).set(covidData);
    }

    createDocId(patId) {
        const date = new Date();
        const rand = Math.floor(Math.random() * 1000) + 1;
        const uniqId = date.getTime() + rand.toString();
        return uniqId;
    }

    shareLinkUrl(getLoadData): Observable<any> {
        const lab = this.userService.getCookie("lab");
        let url = this.globals.siteUrl + 'covid-assessment-start?token=';

        // const urlStr = '?key=add&patient='+this.globals.encodeToa(getLoadData.patientId)+'&labName='+this.globals.encodeToa(lab);
        // const docId = this.createDocId(getLoadData.patient);

        const urlStr = 'key#add##patient#' + getLoadData.patient + '##labName#' + lab + '##email#' + getLoadData.email + '##firstName#' + getLoadData.firstName + '##lastName#' + getLoadData.lastName + '##docId#' + getLoadData.docId;

        url = url + this.globals.encodeToa(urlStr);
        const data: any = {
            email: getLoadData.email,
            url: url,
            fullName: getLoadData.fullName,
            patientId: getLoadData.patientId,
            labId: getLoadData.labId
        };

        return this.apiService.shareLinkUrl(data);

    }

    getLabLogo(collectionPath) {
        return this.afs.collection(collectionPath).doc('lab');
    }

    async getLastTokenNo() {
        return new Promise((resolve, reject) => {
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy('tokenId', 'desc')
                .limit(1))
                .subscribe(data => {
                    if (data && data.length > 0) {
                        data.map(val => {
                            resolve(val.tokenId + 1);
                            // return val.tokenId+1;
                        });
                    } else {
                        resolve(null);
                    }

                });
        });
    }


    // being use in covid screening ID

    getTokenInitValue() {
        return new Promise<any>((resolve, reject) => {
            const collectionName = `lab/${this.userService.getCookie("lab")}/config`;
            this.afs.collection(collectionName).doc('covid').get().subscribe((doc) => {
                if (doc) {
                    resolve(doc.data());
                } else {
                    reject(' Error in reading the role details');
                }
            });
        });

    }


    getStaffQuestion() {
        console.log('ANjali');
        const collectionPath = `lab/${this.userService.getCookie("lab")}/covid/constant`;
        return new Promise<any>((resolve, reject) => {
            this.afs
                .collection(collectionPath)
                .doc('staffQuestion')
                .valueChanges()
                .subscribe((data) => {
                    console.log(data);
                    resolve(data);
                });
        });
    }

    // updateTokenConfig(tokenData) {
    //     const patientCollectionPath = 'common/config/tokenInitVal';
    //     return this.afs.collection(patientCollectionPath).doc('token').update(tokenData);
    // }


    // end Class
}