import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import * as moment from 'moment';
import { Globals } from '../../../../common/global';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsentService } from './consent.service';
import { Consent } from './consent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { CovidQuestion } from '../../questions/list/CovidQuestion';


@Component({
    selector: 'app-covidconsent',
    templateUrl: './covidconsent.component.html',
    styleUrls: ['./covidconsent.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CovidconsentComponent implements OnInit, OnDestroy, AfterViewInit {

    addconsentForm: FormGroup;
    editconsentForm: FormGroup;
    hasCheckbox = false;
    docId = '';

    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    length: any;
    perPageData = this.global.perPageData;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;
    protected extraCta = {
        visibility: true,
        text: 'Add Consent',
    };
    urlData = '';
    isLoading = true;
    hasFirstDataShown = false;
    checked = '';
    order: number;
    showGroup = false;
    orderList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
    cstObservableData: Observable<any>;
    enableInfoMsg  = false;

    // chip

    groupItems: Array<any> = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    add(event: MatChipInputEvent): void {

        const input = event.input;
        const value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.groupItems.push(value.trim());
        }



        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(item): void {
        const index = this.groupItems.indexOf(item);

        if (index >= 0) {
            this.groupItems.splice(index, 1);
        }
    }

    // chip


    constructor(public paginationService: ConsentService,
        private changeDetectorRefs: ChangeDetectorRef,
        private route: ActivatedRoute, private router: Router, private global: Globals,
        public snackBar: MatSnackBar, private _formBuilder: FormBuilder, public dialog: MatDialog,
        private consentService: ConsentService) {
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {

        this.page = 0;
        this.urlData = this.route.snapshot.queryParams.center;
        this.addconsentForm = this._formBuilder.group({
            option: ['', [Validators.required], ''],
            sortBy: ['', [Validators.required],],
            hasCheckbox: ['', [Validators.required],],
            type: ['', [Validators.required],],
        });

        this.editconsentForm = this._formBuilder.group({
            option: ['', [Validators.required], ''],
            sortBy: ['', [Validators.required],],
            hasCheckbox: ['', [Validators.required],],
            type: ['', [Validators.required],],
        });
    }

    changeSelect(event) {
        if (event.value === 'yes') {
            this.showGroup = true;
        } else {
            this.groupItems.length = 0;
            this.showGroup = false;
        }
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {

            if (data && data.length > 0) {
                data.forEach(value => this.updateRow(value));
                this.dataSource.data = data;
                this.hasFirstDataShown = true;
            } else {

                    this.hasFirstDataShown = false;
                    this.enableInfoMsg = false;
                    setTimeout(()=>{
                        this.hasFirstDataShown = true;
                        this.enableInfoMsg = true;
                    },6000)



                // if (this.hasFirstDataShown) {
                //     this.openSnackBar('No data available', 'OK');
                // }
            }
            this.isLoading = false;
        });
    }

    updateRow(row): any {
        row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
        row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    nextPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.next();
    }


    prevPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.prev();
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.groupItems.length = 0;
        this.dialog.open(ref, { width: '520px', disableClose: true });
    }

    openDialogWithRefEdit(ref: TemplateRef<any>, docId) {

        this.docId = docId;
        this.consentService.getConsentById(docId).then((data: any) => {
            if (data.hasCheckbox === true) {
                this.checked = 'Yes';

            } else {
                this.checked = 'No';
            }
            this.order = data.sortBy;



            this.editconsentForm.setValue({
                option: data.option,
                sortBy: this.order,
                hasCheckbox: this.checked,
                type: data.type,
            });

            if (data.type === 'yes') {
                this.showGroup = true;
                this.groupItems = data.children;
            } else {
                this.groupItems.length = 0;
                this.showGroup = false;
            }

        });

        this.dialog.open(ref, { width: '480px', disableClose: true });
    }


    // SOC add / edit conssent



    addConsent() {

        if (this.addconsentForm.value.hasCheckbox === 'Yes') {
            this.hasCheckbox = true;
        } else {
            this.hasCheckbox = false;
        }



        const consentData =
        {
            option: this.addconsentForm.value.option.trim(),
            hasCheckbox: this.hasCheckbox,
            sortBy: parseInt(this.addconsentForm.value.sortBy.trim(), 10),
            type: this.addconsentForm.value.type,
        };
        if (this.addconsentForm.value.type === 'yes') {
            if (this.groupItems.length < 1) {
                alert('Please add sub consent or change type to No');
                return;
            }

            consentData['children'] = this.groupItems;
        }
        const returnData = this.consentService.addConsentData(consentData);
        returnData.then((data: any) => {
            const msg = 'Consent Added Successfully';
            this.openSnackBar(msg, 'OK');
        }).catch((error) => {
            this.handleError(error);
        });
        this.addconsentForm.reset();

    }

    // EOC

    onCancelFrm() {
        this.addconsentForm.reset();
    }



    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getColumn(): any[] {
        return [
            { columnDef: 'Sr', header: 'Sr.', cell: (element: Consent, index: number) => `${this.page * 10 + index + 1}` },
            { columnDef: 'Title', header: 'Title', cell: (element: Consent, index: number) => `${element.option}` },
            { columnDef: 'SubQuestion', header: 'SubTitle', cell: (element: Consent, index: number) => `${element.option}` },
            { columnDef: 'Order', header: 'Order', cell: (element: Consent, index: number) => `${element.sortBy}` },
            { columnDef: 'Checkbox', header: 'Checkbox', cell: (element: Consent, index: number) => `${element.hasCheckbox}` },
            { columnDef: 'Edit', header: 'Edit', cell: (element: Consent, index: number) => `${element.id}` },
            { columnDef: 'Action', header: 'Action', cell: (element: Consent, index: number) => `${element.id}` },

        ];
    }

    getTableName(): string {
        return 'consent';
    }

    getTitleMatIcon(): string {
        return 'label';
    }

    getTitle(): string {
        return 'Consent Form';
    }

    getOrderByColumn(): string {
        return 'sortBy';
    }


    openDetail(row) {
        window.open(row.link, '_blank');
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    deleteConsent(docId) {
        this.paginationService.deleteConsentById(docId).then(result => {
            const msg = 'Consent Deleted Successfully';
            this.openSnackBar(msg, 'OK');
        }).catch((error) => {
            this.handleError(error);
        });

    }


    updateConsent(docId) {

        if (this.editconsentForm.value.hasCheckbox === 'Yes') {
            this.hasCheckbox = true;
        } else {
            this.hasCheckbox = false;
        }
        const updateData =
        {
            option: this.editconsentForm.value.option,
            hasCheckbox: this.hasCheckbox,
            sortBy: parseInt(this.editconsentForm.value.sortBy, 10),
            type: this.editconsentForm.value.type,
        };

        if (this.editconsentForm.value.type === 'yes') {
            if (this.groupItems.length < 1) {
                alert('Please add sub consent or change type to No');
                return;
            }
            updateData['children'] = this.groupItems;
        } else {
            delete updateData['children'];
        }

        this.consentService.updateConsent(docId, updateData).then(updateResult => {

        }).catch((error) => {
            this.handleError(error);
        });
    }
    openDialogWithRefDel(ref: TemplateRef<any>): void {



        this.dialog.open(ref, { width: '400px' });


        // this.router.navigate(['/viewtool']);
    }
}
