// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// dev1
export const environment = {
    production: false,
    hmr       : false,
    firebaseConfig: {
        apiKey: 'AIzaSyAFrAA9V84Cg8mfnVZykoX9qJaekkHT1jQ',
        authDomain: 'sterilwize-dev-5ebe8.firebaseapp.com',
        databaseURL: 'https://sterilwize-dev-5ebe8.firebaseio.com',
        projectId: 'sterilwize-dev-5ebe8',
        // cloudFunctionURL: 'https://us-central1-dentistry-project-dev.cloudfunctions.net/',
        cloudFunctionURL:'https://northamerica-northeast1-sterilwize-dev-5ebe8.cloudfunctions.net/',
        websiteUrl: 'https://sterilwize-dev-5ebe8.firebaseapp.com/',
        websiteFront:'https://wize.ai/',
        storageBucket: 'sterilwize-dev-5ebe8.appspot.com',
        messagingSenderId: '775947968351',
        appId: 'sterilwize-dev-5ebe8',
    }
};