import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from "../../common/global";
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from '../../common/service/storage.service';


@Injectable()
export class ReportService {
    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
        private afs: AngularFirestore,
        private userService: StorageService,
    ) {}

    allTeamData() {
    const labId = this.userService.getCookie('lab')
     const   collectionName = 'lab/' + labId + '/center/center1/dailyTasks';
    const data= new Promise<any>((resolve, reject) => {
            this.afs.collection(collectionName).valueChanges().subscribe(data => {
                if (data) {
                    resolve(data.map((value: any) => value ));
                }
            });
        });
      console.log("AllData=", data  )  
    }
    downloadPdf(getLoadData, labsList, reportId, patientId, patientName, timezone) {
        const data = {getLoadData: getLoadData, labsList: labsList, reportId: reportId, patientId: patientId, patientName: patientName,timezone: timezone, tokenId: this.globals.secretKey };
        return this.httpClient.post(this.globals.constUrl + 'pdfAllReport', data, { responseType: 'blob' });
    }

    downloadPdfCenterWize(centerName,getLoadData, labsList, reportId, patientId, patientName, timezone) {
        console.log(this.allTeamData(),'centerName=',centerName,'getLoadData=',getLoadData, 'labsList=', labsList, 'reportId =', reportId,'timezone=', timezone);
        const data = { centerName:centerName,getLoadData: getLoadData, labsList: labsList, reportId: reportId, patientId: patientId, patientName: patientName,timezone: timezone, tokenId: this.globals.secretKey };
        return this.httpClient.post(this.globals.constUrl + 'pdfAllReport', data, { responseType: 'blob' });
    }
}
