import { AfterViewInit, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { Cell } from '../cell';
import { CellService } from '../cell.service';
import { Asset } from '../../asset/asset';
import { BaseCellComponent } from '../basecell/basecell.component';


const util = require('util');

@Component({
    selector: 'cell-image',
    templateUrl: './image.component.html',
    styles: [':host {display: block; border: solid 1px red; width: 100%;height: 100%}']
})
export class ImageComponent extends BaseCellComponent implements AfterViewInit {
    @Input() cell: Cell;
    path: string;

    constructor(private cellService: CellService) {
        super('ImageComponent');
    }


    getImage(type: string, id: string): Observable<Asset> {
        return this.cellService.getBackgroundImage(type, id);
    }

    ngAfterViewInit(): void {
        const cellType: ECellType = this.cellService.getCellType(this.cell.type);
        this.fetchAsset(cellType, this.cell.imageIdOuter);
    }

    fetchAsset(cellType: ECellType, id: string) {
        const path = this.cellService.getAssetPath(cellType, id);
        this.cellService.getRef(path).valueChanges().subscribe((asset) => {
            if (asset) {
                this.path = asset.imageUrl;
            }

        });
    }

    getUrl() {
        return this.path;
    }
}
