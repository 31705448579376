import { AfterViewInit, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { Cell } from '../cell';
import { CellService } from '../cell.service';
import { Asset } from '../../asset/asset';


const util = require('util');

export class BaseCellComponent {

    constructor(type: string) {

    }

}
