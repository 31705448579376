import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '@fuse/shared.module';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {AgmCoreModule} from '@agm/core';
import {ScreeningPatientResultListComponent} from "./results/list/screening-patient-result-list.component";
import {AuthGuard} from "../../auth/auth.guard";
import {SelfAssessmentInitComponent} from "./self-assessment/init/self-assessment-init.component";
import {ScreeningPatientResultDetailComponent} from "./results/detail/screening-patient-result-detail.component";
import {ScreeningPatientHistoryListComponent} from "./results/patientHistory/screening-patient-history-list.component";
import {CovidconsentComponent} from "../setting/consent/list/covidconsent.component";
import {CovidquestionComponent} from "../setting/questions/list/covidquestion.component";
import {AddcovidquestionComponent} from "../setting/questions/add/addcovidquestion.component";
import {EditcovidquestionComponent} from "../setting/questions/edit/editcovidquestion.component";
import {AddcovidconsentComponent} from "../setting/consent/add/addcovidconsent.component";
import {SelfAssessmentResolutionComponent} from "./self-assessment/resolution/self-assessment-resolution.component";
import {DialogContentCovidComponent} from "./self-assessment/nameConfirmationDialog/dialog-content-covid.component";
import {SharedModule} from "../../common/shared.module";
import {AsapMaterialModule} from "../../../../material-module";
import {FuseWidgetModule} from "../../../../../@fuse/components";
import {SignaturePadModule} from "@ng-plus/signature-pad";
import {CovidService} from "../covid.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireStorage} from "@angular/fire/storage";
import {SelfAssessmentQuestionsService} from "./self-assessment/questions/self-assessment-questions.service";
import {InofficeComponent} from "./results/inOffice/inoffice.component";
import {PatientScreeningInfiniteListService} from "./results/list/screening-patient-infinite-scrol.service";


const routes = [
    {
        path: 'covid',
        component: ScreeningPatientResultListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'covid-assessment-start',
        component: SelfAssessmentInitComponent,
        // canActivate: [AuthGuard],
    },
    {
        path: 'covidresultdetail',
        component: ScreeningPatientResultDetailComponent,
        // canActivate: [AuthGuard],
    },
    {
        path: 'covidviewdetail',
        component: ScreeningPatientHistoryListComponent,
        canActivate: [AuthGuard],
    }
    ,
    {
        path: 'consent',
        component: CovidconsentComponent,
        canActivate: [AuthGuard],
    }
    ,
    {
        path: 'question',
        component: CovidquestionComponent,
        canActivate: [AuthGuard],

    },
    {
        path: 'addcovidquestion',
        component: AddcovidquestionComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'editcovidquestion',
        component: EditcovidquestionComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'addcovidconsent',
        component: AddcovidconsentComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'inoffice',
        component: InofficeComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        ScreeningPatientResultListComponent,
        SelfAssessmentInitComponent,
        SelfAssessmentResolutionComponent,
        ScreeningPatientResultDetailComponent,
        DialogContentCovidComponent,
        ScreeningPatientHistoryListComponent,
        CovidconsentComponent,
        CovidquestionComponent,
        AddcovidconsentComponent,
        AddcovidquestionComponent,
        EditcovidquestionComponent,
        InofficeComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        SharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,
        SignaturePadModule



    ],
    entryComponents: [
    ],
    exports: [
        ScreeningPatientResultListComponent,
        SelfAssessmentInitComponent,
        SelfAssessmentResolutionComponent,
        InofficeComponent,
        ScreeningPatientResultDetailComponent,
        ScreeningPatientHistoryListComponent,
        CovidconsentComponent,
        CovidquestionComponent,
        AddcovidconsentComponent,
        AddcovidquestionComponent

    ],
    providers: [
        PatientScreeningInfiniteListService,
        CovidService,AngularFirestore,AngularFireStorage,SelfAssessmentQuestionsService
    ]
})

export class PatientScreeningModule
{

}
