import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../../../common/global';
import { PatientService } from '../../../../sterilwize/patient/patient.service';


@Component({
    selector: 'app-dialog-content-covid',
    templateUrl: './dialog-content-covid.component.html',
    styleUrls: ['./dialog-content-covid.component.scss']
})
export class DialogContentCovidComponent implements OnInit {

    firstName = '';
    lastName = '';
    token = '';
    private queryParam: any = {};
    fullName = '';
    resultData: any;
    patientFullName = '';
    constructor(public dialogRef: MatDialogRef<DialogContentCovidComponent>,
        private route: ActivatedRoute, public globals: Globals,
        public dialog: MatDialog,
        public patientService: PatientService,
    ) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.globals.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0])
            const abVal = keyVal.map(para1 => para1.split('#')[1])
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {})
        }
        this.patientService.getPatientByIdWithoutLogin(this.queryParam['patient'], this.queryParam['labName']).then(async (resultData: any) => {
            this.resultData = await resultData;
            if (this.resultData) {
                this.patientFullName = this.resultData.firstName + ' ' + this.resultData.lastName;
            } else {
                // do something
            }
        });

        //
        // this.firstName = this.queryParam.firstName;
        // this.lastName = this.queryParam.lastName;
        // this.fullName = this.firstName + ' '+ this.lastName;
    }
}
