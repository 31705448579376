import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../status/status.service';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {LoadService} from '../load.service';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../common/service/api.service';


@Component({
    selector: 'app-loaddetail',
    templateUrl: './loaddetail.component.html',
    styleUrls: ['./loaddetail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LoaddetailComponent implements OnDestroy,OnInit, AfterViewInit {

    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['barcode', 'cassettetype', 'status'];
    urlData = '';
    loadObservableData: Observable<any>;
    cstObservableData: Observable<any>;

    program = '';
    status = '';
    loadDetailsData;
    any;
    aiImageUrl = '';
    machineReportUrl = '';
    barcode = '';
    barcodeBd = '';
    barcodeBi = '';
    barcodeEi = '';
    aiValue = 0;
    startedName = '';
    startedTime = '';
    endedName = '';
    endedTime = '';
    loadNo: number;
    cycleNo: number;
    machineName = '';
    cassetteObj = {};
    cassetteObjArr: Array<any> = [];
    isLoading: boolean;
    objData = {};
    reportImageUrl ='';

    constructor(

        public loadListService: LoadService,
        private route: ActivatedRoute, private router: Router,
        public statusService: StatusService,
        private location: Location, public dialog: MatDialog, public apiService: ApiService,
    ) {

        this.isLoading = true;
    }
    ngOnDestroy(): void {
        this.loadObservableData
    }

    ngOnInit(): void {
        this.urlData = this.route.snapshot.queryParams.barcode;
    }


    ngAfterViewInit(): void {

        this.loadObservableData = this.loadListService.getLoadById(this.urlData);
        this.loadObservableData.subscribe(resultData => {

            this.loadDetailsData = resultData[0];
            this.barcode = resultData[0].barcode;
            this.program = resultData[0].program;
            this.loadNo = resultData[0].loadNo;
            // this.status = resultData[0].status;

            this.status = this.getLoadStatus(resultData[0]);
            this.machineName = resultData[0].machineName;
            this.cycleNo = resultData[0].cycleNo;
            this.aiImageUrl = resultData[0].aiImageUrl;
            this.barcodeBi = resultData[0].barcodeBi;
            this.barcodeEi = resultData[0].barcodeEi;
            this.machineReportUrl = resultData[0].machineReportUrl;
            this.reportImageUrl = resultData[0].reportImageUrl;

            if (resultData[0].startedBy) {

                this.startedName = resultData[0].startedBy.name;
                // this.startedTime = resultData[0].startedBy.time;
                this.startedTime = this.apiService.getDateStringMomentByTz(resultData[0].startedBy.time);

            }
            if (resultData[0].updatedBy) {
                this.endedName = resultData[0].updatedBy.name;
                // this.endedTime = resultData[0].updatedBy.time;
                this.endedTime = this.apiService.getDateStringMomentByTz(resultData[0].updatedBy.time);

            }

            if (resultData[0].cassetteIds) {
                const cassetteBc = resultData[0].cassetteIds.map(data => data.split('_')[0]);
                cassetteBc.map(barcode => {
                    this.cstObservableData = this.loadListService.getCassetteByBarcodeAndLoadNo(barcode, this.loadNo);
                    this.cstObservableData.subscribe(async data => {
                        // this.cassetteObjArr.length = 0;
                        const allData: any = data[0];
                        // const eStatus: EStatus = this.statusService.getStatusNew(resultData[0].barcodeBd, resultData[0].barcodeBi, resultData[0].barcodeEi);
                        // const customStat = this.statusService.getCassetteResult(eStatus);
                        this.objData = { cassettetype: allData.type, barcode: allData.barcode, status: this.statusService.getCassetteStatus(allData) };

                         this.cassetteObjArr.push(this.objData);

                        const sorted = this.cassetteObjArr.sort((t1, t2) => {
                            const name1 = t1.barcode.toLowerCase();
                            const name2 = t2.barcode.toLowerCase();
                            if (name1 > name2) { return 1; }
                            if (name1 < name2) { return -1; }
                            return 0;
                        });
                        // this.dataSource.data = this.cassetteObjArr;
                        this.dataSource.data = sorted;
                        this.objData = {};

                    });
                });
            }

        });

    }

    getLoadStatus(load): string {
        return this.statusService.getMessage(this.statusService.getStatusNewTest(load.status,load.statusBd, load.statusBi, load.statusEi));
    }

    goBack() {
        this.router.navigate(["/load"])
  .then(() => {
    //  this.ngOnDestroy();
      this.ngOnInit()
    //  window.location.reload();
  });
        }
    hideLoader() {

        this.isLoading = false;
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.dialog.open(ref, {
            disableClose: true, width: '50%',
        });
    }


}
