import {AfterViewInit, Component, Input} from '@angular/core';
import {CellService} from './cell.service';
import {Observable} from 'rxjs';
import {Asset} from '../asset/asset';
import {Cell} from './cell';

const util = require('util');

@Component({
    selector: 'dash-cell',
    templateUrl: './cell.component.html',
    styles: [':host {display: block; border: solid 1px red; width: 100%;height: 100%}']
})
export class CellComponent {
    @Input() cell: Cell;
    path: string;

    constructor() {
    }



}
