import { AfterViewInit, Component, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

declare var google: any;
import { HomeService } from './home.service';
import * as shape from 'd3-shape';
import { AnalyticsService } from './analytics.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { urlList } from '../../../../../constant';
import { element } from 'protractor';
import { collectionPath } from '../../../../../../functions/src/Constants';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Team } from '../team/team';
import { Load } from '../load/load';
import { BaseComponent } from '../../base/base.component';
import { ConfigService } from '../../common/config/config.service';
import { Globals } from '../../common/global';
import * as moment from 'moment';
import { ApiService } from '../../common/service/api.service';
import * as firebase from 'firebase';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    widgets: any;
    widget1SelectedYear = (new Date().getFullYear()).toString();
    widget5SelectedDay = 'today';
    dateNow = Date.now();

    widget5: any = {};
    widget6: any = {};
    staticData = false;
    cassetteAvailability = [];
    // chart vars

    resultDataLoad: any = [];
    resultDataLoadPrev1: any = [];
    resultDataLoadPrev2: any = [];
    resultDataLoadPrev3: any = [];
    resultDataCst: any = [];
    incPen: any = 0;
    passBd = 0;
    passBi = 0;
    passEi = 0;
    pendingBd = 0;
    inProgressBi = 0;
    pendingEi = 0;
    failBd = 0;
    failBi = 0;
    failEi = 0;
    email = '';
    progBi: any;
    reportId: any;
    cstInp: any = 0;
    cstIncubFail: any = 0;
    cstReady: any = 0;
    cstAssigned: any = 0;
    dateRange: any = [];
    filterData: any = [];
    inputReadonly = true;
    sterilizerName = '';
    resultCassetteData: any = [];
    loadStatus = false;
    labs: string[] = [this.userService.getCookie("lab")];
    chartPath = 'lab/' + this.labs + '/report/chart/yearlyLoadChart';
    fromDate: number;
    toDate: number;
    option = '';
    multiArr: any;
    activeBtn1 = true;
    activeBtn2 = false;
    activeBtn3 = false;
    spinner = false;
    labId = '';
    optional = '';
    initState = true;
    jsonData: any;
    chartYear: Array<any> = [];
    countVal: Array<any> = [];
    allData: any;

    private _unsubscribeAll: Subject<AnalyticsService[]>;
    itemsObservable: Observable<AnalyticsService[]>;

    date = new Date();
    from = this.date.getFullYear() - 2;
    to = this.date.getFullYear();

    @ViewChild('pieChart', { static: true }) pieChart: ElementRef;
    private chartStatus: boolean;

    /**
     * Constructor
     *
     * @param {AnalyticsDashboardService} _analyticsDashboardService
     */
    constructor(
        private _analyticsDashboardService: HomeService,
        public analyticService: AnalyticsService,
        public afs: AngularFirestore,
        private configService: ConfigService,
        private globals: Globals,
        private apiService: ApiService,
        private userService: StorageService,
    ) {
        super(configService, globals);
        // Register the custom chart.js plugin
        this._registerCustomChartJSPlugin();

        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);

        this.widget5 = {
            currentRange: 'TW',
            xAxis: true,
            yAxis: true,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'Days',
            showYAxisLabel: false,
            yAxisLabel: 'Isues',
            scheme: {
                domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
            },
            onSelect: (ev) => {//dp something
            },
            supporting: {
                currentRange: '',
                xAxis: false,
                yAxis: false,
                gradient: false,
                legend: false,
                showXAxisLabel: false,
                xAxisLabel: 'Days',
                showYAxisLabel: false,
                yAxisLabel: 'Isues',
                scheme: {
                    domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
                },
                curve: shape.curveBasis
            }
        };
        this.widget6 = {
            currentRange: 'TW',
            legend: false,
            explodeSlices: false,
            labels: true,
            doughnut: true,
            gradient: false,
            scheme: {
                domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
            },
            onSelect: (ev) => { // do something
            }
        };
    }

    ngOnInit(): void {


        // Get the widgets from the service
          this.yearlyChart();
         // this.threeYearChart();
        this.updateMonthlyLoadEvent(); // @Hot update
        this.widgets = this._analyticsDashboardService.widgets;
        this.isYearChartDataAvailable();

        this.itemsObservable = this.analyticService.getLoadchartYears();
        this.itemsObservable.subscribe(data => {
            // this.threeYearChart();
            this.chartYear.length = 0;
            const dataAll = {};
            this.allData = null;
            for (let i = this.from; i <= this.to; i++) {
                data.forEach((ele: any) => {


                    if (ele.year === i) {
                        this.countVal.push(ele.totalRecord);

                    }

                    this.allData = Object.assign({
                        [i]: [{
                            data: this.countVal,
                            label: 'Count',
                            fill: 'start'
                        }]
                    }, this.allData);

                });
                this.countVal = []; // empty after first year data added
                this.chartYear.push(i.toString());
            }
            return this.widgets.widget1.datasets = this.allData;
        });

        if (localStorage.getItem('staticData') === 'N') {
            this.getDateValue(this.option);
            this.multiArr = this.createRangeArray();
            this.staticData = false;
            this.getLoadChart(this.multiArr);  // for dynamic data 26FEb-2020
            // this.getLoadChart(this.dateRange);

            this.labId = this.userService.getCookie("lab");
            const collectPath = `/lab/${this.labId}/label/`;

            // const  obj = {id:undefined, minimumCount:undefined};
            const cassette = this.afs.collection(collectPath);
            cassette.ref.get().then(data => {
                data.docs.map(elem => {
                    cassette.doc(elem.id).ref.get().then(result => {
                        // assigning data into cassette availability card
                        this.cassetteAvailability.push({ id: elem.id, currentCount: result.data().currentCount, minimumCount: result.data().minimumCount });

                    });
                });
            });

        }

        // being use to add a data in auth user collection key default (being use to operate lon db if no user logged in)
        // boc
        firebase.auth().fetchSignInMethodsForEmail(this.globals.staticEmail)
            .then(async signInMethods => {
                if (signInMethods.length === 0) {
                    await firebase.auth().createUserWithEmailAndPassword(this.globals.staticEmail, this.globals.staticPass)
                        .then(u => { })
                        .catch(error => {
                            // auth/user-not-found
                            // Handle Errors here.
                        });
                }
            });

        // eoc

    }


    getLoadChart(dateRange) {
        this.spinner = true;
        this.apiService.getCountDataForAll3(dateRange, this.labs).subscribe(values => {
            this.resultDataLoad = this.resultDataLoadPrev1 = values[0].data;
            this.resultDataLoadPrev2 = values[1].data;
            this.resultDataLoadPrev3 = values[2].data;
            this.assignValueToChart();
            // this.spinner = false;
        });
    }

    updateMonthlyLoadEvent() {
        this.apiService.updateCurrentMonthLoad(this.labs).then(values => {
        // console.log(values);

        });
    }


    // run only once when no chart data is available
    yearlyChart() {
        // this.analyticService.getYearlyChartData
        this.apiService.getYearlyChartData(this.labs).then(values => {
        });
    }


    assignValueToChart() {

        if (this.resultDataLoad.data && this.resultDataLoad.data.BD) {
            this.spinner = true;
            this.passBd = this.resultDataLoad.data.BD.passBd;
            this.pendingBd = this.resultDataLoad.data.BD.pendingBd;
            this.failBd = this.resultDataLoad.data.BD.failBd;
            this.spinner = false;
        }
        if (this.resultDataLoad.data && this.resultDataLoad.data.BI) {
            this.spinner = true;
            this.passBi = this.resultDataLoad.data.BI.passBi;
            this.inProgressBi = this.resultDataLoad.data.BI.pendingBi;
            this.failBi = this.resultDataLoad.data.BI.failBi;
            this.spinner = false;
        }

        if (this.resultDataLoad.data && this.resultDataLoad.data.EI) {
            this.spinner = true;
            this.passEi = this.resultDataLoad.data.EI.passEi;
            this.pendingEi = this.resultDataLoad.data.EI.pendingEi;
            this.failEi = this.resultDataLoad.data.EI.failEi;
            this.incPen = this.resultDataLoad.data.BI.incubatorPen;
            // this.incPen = this.resultDataLoad.data.EI.pBiInEi;
            this.spinner = false;
        }


        this.drawChart1();
        this.chartStatus = false;
    }

    // onclick load data of lastweek and 2 week ago

    onClickLoadData(option) {
        this.spinner = true;

        if (option === 'lastweek') {
            this.resultDataLoad = null;
            this.activeBtn1 = false;
            this.activeBtn2 = true;
            this.activeBtn3 = false;
            this.resultDataLoad = this.resultDataLoadPrev2;
            this.assignValueToChart();
            this.spinner = false;

        } else if (option === 'lastweek2') {
            this.resultDataLoad = null;
            this.activeBtn1 = false;
            this.activeBtn2 = false;
            this.activeBtn3 = true;
            this.resultDataLoad = this.resultDataLoadPrev3;
            this.assignValueToChart();
            this.spinner = false;
        } else {

            this.activeBtn1 = true;
            this.activeBtn2 = false;
            this.activeBtn3 = false;
            this.resultDataLoad = this.resultDataLoadPrev1;
            this.assignValueToChart();
            this.spinner = false;

        }
    }

    // dynamic chart start

    drawChart1 = () => {
        const data = google.visualization.arrayToDataTable([
            // Define the chart to be drawn.
            ['Status',
                'In Progress', { role: 'annotation' },
                'Passed', { role: 'annotation' },
                'Failed', { role: 'annotation' },
                'Incubator Pending', { role: 'annotation' },
            ],
            ['BD', this.pendingBd, this.pendingBd, this.passBd, this.passBd, this.failBd, this.failBd, 0, ,],
            ['Spore Test(BI)', this.inProgressBi, this.inProgressBi, this.passBi, this.passBi, this.failBi, this.failBi, this.incPen, this.incPen,],
            // ['Incubator', 45, '45',    12, '12',   7,  '7',    0,    ,   ],
            ['PCD(EI)', this.pendingEi, this.pendingEi, this.passEi, this.passEi, this.failEi, this.failEi, 0, 0],
            // ['Cassette', 25, '25', 31, '31', 2, '2', 45, '45',],
        ]);

        const options = {
            title: 'Load Reports',
            // width: 650,
            // height: 400,
            bar: { groupWidth: '80%' },
            legend: { position: 'right' },
            chartArea: { width: '70%' },
            colors: ['#C6ECFD', '#3A4BA5', '#5797E2', '#65BEF2', '#f44336'],
            is3D: true,
            vAxis: { minValue: 1, format: 0 },


        };
        const chart = new google.visualization.ColumnChart(this.pieChart.nativeElement);
        chart.draw(data, options);

    };

    getValueAt(column, dataTable, row) {
        return dataTable.getFormattedValue(row, column);
    }

    /** End of Graphs */


    // dynamic chart ends

    createRangeArray() {
        const onlyDate = moment().format('LL');

        const curr = new Date(onlyDate);

        this.toDate = curr.getTime();

        const currentWeek = curr.setDate(curr.getDate() - curr.getDay());

        const dateArr = new Array();

        dateArr[0] = new Array(currentWeek, this.toDate);


        // const previousWeek = curr.setDate(currentWeek - (currentWeek + 7));
        const previousWeek = curr.setDate(curr.getDate() - (curr.getDay() + 7));

        dateArr[1] = new Array(previousWeek, currentWeek);

        // const twoWeeksAgo = curr.setDate(currentWeek - (currentWeek - 14));
        const twoWeeksAgo = curr.setDate(curr.getDate() - (curr.getDay() + 14));

        dateArr[2] = new Array(twoWeeksAgo, previousWeek);
        return dateArr;

    }


    getDateValue(option) {

        const curr = new Date();
        this.toDate = curr.getTime();



        if (option === 'current') {

            const currentWeek = curr.setDate(curr.getDate() - curr.getDay());
            this.fromDate = currentWeek;


        } else if (option === 'prev1') {

            const previousWeek = curr.setDate(curr.getDate() - (curr.getDay() + 7));
            this.fromDate = previousWeek;
        } else if (option === 'prev2') {

            const twoWeeksAgo = curr.setDate(curr.getDate() - (curr.getDay() + 14));
            this.fromDate = twoWeeksAgo;

        } else {
            // default all data
            const twoWeeksAgo = curr.setDate(curr.getDate() - (curr.getDay() + 14));
            this.fromDate = twoWeeksAgo;

            this.dateRange = [this.fromDate, this.toDate];
            this.getLoadChart(this.dateRange);
        }
    }


    drawChart = () => {
        const data = google.visualization.arrayToDataTable([
            // Define the chart to be drawn.
            ['Status',
                'InProgress', { role: 'annotation' },
                'Passed', { role: 'annotation' },
                'Failed', { role: 'annotation' },
                'Pending', { role: 'annotation' }
            ],
            ['BD', 5, 5, 30, 30, 1, 1, 0, 0],
            ['BI', 8, 8, 40, 40, 0, 0, 6, 6],
            // ['Incubator', 45, '45',    12, '12',   7,  '7',    0,    ,   ],
            ['EI', 12, 12, 50, 50, 2, 2, 15, 15],
            // ['Cassette', 25, '25', 31, '31', 2, '2', 45, '45',],
        ]);

        const options = {
            title: '',
            maxwidth: 500,
            // height: 400,
            bar: { groupWidth: '80%' },
            legend: { position: 'right' },
            chartArea: { width: '70%' },
            colors: ['#C6ECFD', '#3A4BA5', '#5797E2', '#65BEF2', '#f44336'],
            is3D: true,
            vAxis: { minValue: 1, format: 0 },
        };
        const chart = new google.visualization.ColumnChart(this.pieChart.nativeElement);
        chart.draw(data, options);
    };


    private _registerCustomChartJSPlugin(): void {
        (window as any).Chart.plugins.register({
            afterDatasetsDraw: (chart, easing): any => {
                // Only activate the plugin if it's made available
                // in the options
                if (
                    !chart.options.plugins.xLabelsOnTop ||
                    (chart.options.plugins.xLabelsOnTop && chart.options.plugins.xLabelsOnTop.active === false)
                ) {
                    return;
                }

                // To only draw at the end of animation, check for easing === 1
                const ctx = chart.ctx;

                chart.data.datasets.forEach((dataset, i): any => {
                    const meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach((elementt, index): any => {

                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                            const fontSize = 13;
                            const fontStyle = 'normal';
                            const fontFamily = 'Roboto, Helvetica Neue, Arial';
                            ctx.font = (window as any).Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            // const dataString = dataset.data[index].toString() + 'k';
                            const dataString = dataset.data[index].toString();

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            const padding = 15;
                            const startY = 24;
                            const position = elementt.tooltipPosition();
                            ctx.fillText(dataString, position.x, startY);

                            ctx.save();

                            ctx.beginPath();
                            ctx.setLineDash([5, 3]);
                            ctx.moveTo(position.x, startY + padding);
                            ctx.lineTo(position.x, position.y - padding);
                            ctx.strokeStyle = 'rgba(255,255,255,0.12)';
                            ctx.stroke();

                            ctx.restore();
                        });
                    }
                });
            }
        });
    }

    getRowBackgroundColor(count): string {
        if (count < 10) {
            return '#F29E9E';
        } else {
            return '#FFFFFF';
        }
    }

    ngAfterViewInit(): void {

        google.charts.load('current', { packages: ['corechart'] });
        // if (localStorage.getItem('staticData') === 'N') {
            google.charts.setOnLoadCallback(this.drawChart1);
        // } else {
        //     google.charts.setOnLoadCallback(this.drawChart);
        //
        // }

        // this.itemsObservable =  this.analyticService.getLoadchartYears();
        // this.itemsObservable.subscribe(data =>{
        //     this.allData = data;
        //     this.widgets.widget1.datasets = data;
        //
        // });
    }

    isYearChartDataAvailable() {

        const date = new Date();
        const month = date.getMonth();  // 0 to 11 (jan to Dec)
        const getYearMonth = month + '-' + (date.getFullYear());
        this.afs.collection(this.chartPath).doc(getYearMonth).get()
            .subscribe(currVal => {
                if (!currVal.exists) {
                    this.yearlyChart();     // first time data to be saved for 3 years
                } else {
                }
            });
        // this.threeYearChart(); // fetch data from yearlyChart collection
    }

    threeYearChart() {
        this.analyticService.getThreeYearsDataChart(this.labs).subscribe((data: any) => {
            this.jsonData = data.datasets;
            const yearString = Object.keys(this.jsonData);
            this.chartYear = yearString.toString().split(',');
            // count.forEach((data1:any) =>{
            //     this.jsonData[data1] =  Object.assign([{label: 'Sales',fill : 'start'}],this.jsonData[data1]);
            //     // this.jsonData[data1] =  this.jsonData[data1].push({label: 'Sales',fill : 'start'});
            // })

            /** Assigning chart data  */
            return this.widgets.widget1.datasets = this.jsonData;

        });

    }

    ngOnDestroy(): void {
        this.jsonData = null;
    }

}
