import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import "rxjs/add/operator/toPromise";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Patient } from "../patient";
import { MatDialog } from "@angular/material/dialog";
import { AddPatientDialogComponent } from "../addpatient/add-patient-dialog.component";
import { EditPatientDialogComponent } from "../editpatient/edit-patient-dialog.component";
import { Load } from "../../load/load";
import { ShareAssessmentDialogComponent } from "../shareAssessmentLink/share-assessment-dialog.component";
import { CovidService } from "../../../screening/covid.service";
import { Globals } from "../../../common/global";
import { TeamService } from "../../team/team.service";
import { SuccessfulComponent } from "../successful/successful.component";
import * as moment from "moment";
import { BaseListComponent } from "../../../base/base-list.component";
import { ConfigService } from "../../../common/config/config.service";
import { PatientListService } from "./patient-list.service";
import { FirebaseUtilService } from "../../../common/service/FirebaseUtil.service";
import { ApiService } from "../../../common/service/api.service";
const timeZone = require("moment-timezone");
import { Subscription } from "rxjs";
import { PatientInfiniteScroll } from "./patient-infinite-scroll.service";
import { MatTableDataSource } from "@angular/material/table";
import { Style } from "util";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
    selector: "patient-table",
    templateUrl: "patient.component.html",
    styleUrls: ["patient.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PatientComponent
     extends BaseListComponent
    implements OnInit, OnDestroy, AfterViewInit
    
{
    patient: string;
    navigationType = this.route.snapshot.data.navType;
    checkHide = this.getSubscriptionType();
    columns = this.getColumn();
    columnsConfig = this.getColumnConfig();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns
        .filter((c) => c.hide === false)
        .map((c) => c.columnDef);
    displayedColumnsConfig = this.columnsConfig
        .filter((c) => c.hide === false)
        .map((c) => c.columnDef);

    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    isLoading = true;
    alertSign = "";
    enableInfoMsg = false;
    protected extraCta = {
        visibility: false,
        text: "",
    };

    points = [];
    signatureImage;
    staffDropDown: any;
    staffForm: FormGroup;
    staffName = "";

    perPageData = this.global.perPageData;

    token: number;
    queryParam = {};

    isInitComplete = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any;
    isMoreDataAvailable = true;
    pmsIntegration: boolean;
    loadingSpinner: boolean=true;
    labConfig: any;
    dateTime: any;
    getCountrycode: any =0;
    // countryCode='+91';
  

    constructor(
        private userSevice1Child: StorageService,
        private fb1: FormBuilder,
        private route1: ActivatedRoute,
        private router1: Router,
        private changeDetectorRefs1: ChangeDetectorRef,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: PatientListService,
        private infiniteScroll: PatientInfiniteScroll,
        private changeDetectorRefs: ChangeDetectorRef,
        public snackBar: MatSnackBar,
        public covidService: CovidService,
        public global: Globals,
        public configService: ConfigService,
        public team: TeamService,
        private firebaseUtilService: FirebaseUtilService,
        private httpClient: HttpClient,
        private apiService: ApiService
    ) {
        super(snackBar, configService, global);

        // if(localStorage.getItem('staticData') === 'Y' ){
        this.extraCta.visibility = true;
        this.extraCta.text = "Add";
        // }
        this.navigationType = this.userSevice1Child.getCookie("navigationType");
        this.configService
        .getLabConfig("lab")
        .valueChanges()
        .subscribe((labConfig: any) => {
            this.labConfig = labConfig;
            console.log(this.labConfig.sendSMSFromNumber);
        });
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.initData();
        this.team.allTeamData().then((data) => {
            this.staffDropDown = data;
        });
        this.staffForm = this.fb1.group({
            staff: ["", [Validators.required], ""],
        });

        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.global.decodeToa(this.token).split("##");
            const abKey = keyVal.map((para1) => para1.split("#")[0]);
            const abVal = keyVal.map((para1) => para1.split("#")[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
            this.searchTxt = this.queryParam["searchTxt"];
        }
        this.paginationService.getLab().then((data) => {
            if (data.pmsIntegration) {
                this.pmsIntegration = false;
            } else this.pmsIntegration = true;
        });

       
    }

    initData() {
        if (PatientComponent.oldData && PatientComponent.oldData.length > 0) {
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck();
            this.changeDetectorRefs.detectChanges();
            this.dataN = PatientComponent.oldData;
            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            this.isInitComplete = true;
            this.isLoading = false;
            this.initWithOldData();
            return;
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.infiniteScroll.init("patient", 10, "firstName", {
            reverse: false,
            prepend: false,
        });

        this.subscription = this.infiniteScroll.data.subscribe((data) => {
            if (data) {
            } else return;
            this.dataN = data;
            if (
                this.dataSource &&
                this.dataN &&
                this.dataSource.data.length == this.dataN.length
            ) {
                this.isMoreDataAvailable = false;
            }
            try {
                data.forEach((value) => this.updateRow(value));
            } catch (e) {}

            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if (this.dataN && this.dataN.length > 0) {
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000);
            } else {
            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 200);
        });
    }

    initWithOldData() {
        this.infiniteScroll.initWithPrev(
            PatientComponent.cursor,
            PatientComponent.oldData,
            "patientResult",
            10,
            "doa",
            { reverse: false, prepend: false }
        );

        this.subscription = this.infiniteScroll.data.subscribe((data) => {
            if (data) {
            } 
            else return;
            this.dataN = data;
            if (
                this.dataSource &&
                this.dataN &&
                this.dataSource.data.length - PatientComponent.oldData.length ==
                    this.dataN.length
            ) {
                console.log(
                    "isMoreDataAvailable" +
                        "this.dataSource.data.length" +
                        this.dataSource.data.length +
                        "this.dataN.length" +
                        this.dataN.length
                );
                // this.isMoreDataAvailable = false;
            }
            data.forEach((value) => this.updateRow(value));
            this.dataSource = new MatTableDataSource(this.dataN);

            this.dataSource.data = PatientComponent.oldData.concat(this.dataN);
            if (this.dataSource.data && this.dataSource.data.length > 0) {
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000);
            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 1000);
        });
        setTimeout(() => {
            this.scrollToBottom();
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck();
            this.changeDetectorRefs.detectChanges();
        }, 1000);
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector("mat-table");
            table.scrollBy({
                behavior: "smooth",
                left: 0,
                top: table.scrollHeight,
            });
        }
    }

    nextPage(): void {
        this.isLoading = true;
        this.isNextPressed = true;
        this.infiniteScroll.more();
    }

    getRowBackgroundColor(element): string {
        // if (element.deleted == true) {
        //     return "lightgrey";
        // } else
        return "#FFFFFF";
    }

    getRowFontColor(element) {
        if (element.deleted == true) {
            return "italic";
        } else return "bold";
    }

    getFontColor(element) {
        if (element.deleted == true) {
            return "#808080";
        } else return "#000000";
    }
    getCrossWord(element) {
        if (element.deleted == true) {
            return "line-through";
        } else return "overline";
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "First Name",
                header: "First Name",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${element.firstName}`,
            },
            {
                columnDef: "Last Name",
                header: "Last Name",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${element.lastName}`,
            },
            {
                columnDef: "ID",
                header: "ID",
                hide: false,
                cell: (element: Patient, index: number) => `${element.id}`,
            },

            {
                columnDef: "Email",
                header: "Email",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.replaceWithX(element.emailId ? element.emailId : ""),
            },
            {
                columnDef: "mobileNumber",
                header: "Mobile Number",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.replaceWithMob(element.mobileNo),
            },
            {
                columnDef: "dob",
                header: "DOB (DD/MM/YYYY)",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${
                        element.dobString
                            ? element.dobString
                            : this.changeTimestampToDate(element.dob)
                    }`,
            },
            {
                columnDef: "Action",
                header: "Edit",
                hide: false,
                cell: (element: Patient, index: number) => `${element.id}`,
            },
            {
                columnDef: "Share Link",
                header: "Share Link",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.prepareComboId(
                        element.id,
                        element.firstName,
                        element.lastName,
                        element.mobileNo
                    ),
            },
            // {
            //     columnDef: 'Assessment',
            //     header: 'Assessment',
            //     cell: (element: Patient, index: number) => this.prepareComboId(element.docId, element.firstName, element.lastName, element.mobileNo)
            // },
            {
                columnDef: "View",
                header: "View",
                hide: this.checkHide,
                cell: (element: Patient, index: number) =>
                    this.preparePatientId(
                        element.id,
                        element.firstName,
                        element.lastName
                    ),
            },
            {
                columnDef: "Covid",
                header: "Covid",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.prepareCovidId(
                        element.id,
                        element.firstName,
                        element.lastName
                    ),
            },
        ];
    }
    getColumnConfig(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "First Name",
                header: "First Name",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${element.firstName}`,
            },
            {
                columnDef: "Last Name",
                header: "Last Name",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${element.lastName}`,
            },
            {
                columnDef: "ID",
                header: "ID",
                hide: false,
                cell: (element: Patient, index: number) => `${element.id}`,
            },

            {
                columnDef: "Email",
                header: "Email",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.replaceWithX(element.emailId ? element.emailId : ""),
            },
            {
                columnDef: "mobileNumber",
                header: "Mobile Number",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.replaceWithMob(element.mobileNo),
            },
            {
                columnDef: "dob",
                header: "DOB (DD/MM/YYYY)",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${
                        element.dobString
                            ? element.dobString
                            : this.changeTimestampToDate(element.dob)
                    }`,
            },
            // {
            //     columnDef: 'Action',
            //     header: 'Edit',
            //     hide: false,
            //     cell: (element: Patient, index: number) => `${element.id}`
            // },
            {
                columnDef: "Share Link",
                header: "Share Link",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.prepareComboId(
                        element.id,
                        element.firstName,
                        element.lastName,
                        element.mobileNo
                    ),
            },
            // {
            //     columnDef: 'Assessment',
            //     header: 'Assessment',
            //     cell: (element: Patient, index: number) => this.prepareComboId(element.docId, element.firstName, element.lastName, element.mobileNo)
            // },
            {
                columnDef: "View",
                header: "View",
                hide: this.checkHide,
                cell: (element: Patient, index: number) =>
                    this.preparePatientId(
                        element.id,
                        element.firstName,
                        element.lastName
                    ),
            },
            {
                columnDef: "Covid",
                header: "Covid",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.prepareCovidId(
                        element.id,
                        element.firstName,
                        element.lastName
                    ),
            },
        ];
    }

    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }

    replaceWithMob(mob) {
        mob = this.getStringOrDefault(mob, "");
        const mobileNo = mob.toString();
        // console.log(mobileNo);
        if (mobileNo !== undefined) {
            const mob = mobileNo.replace(/[^0-9]/g, "");
            // console.log(mob);
            const patt = /^[0-9]\d*$/;
            if (mob && patt.test(mob)) {
                const txt = mob.toString();
                let numb = txt.match(/\d/g);
                numb = numb.join("");
                if (numb.length < 3) {
                    return numb;
                }
                return (
                    numb[0] +
                    new Array(numb.length - 2).join("*") +
                    numb[numb.length - 2] +
                    numb[numb.length - 1]
                );
            } else {
                return "";
            }
        } else {
            return "-";
        }
    }

    replaceWithX(email) {
        let beforeText = "";
        const beforeAtTheRate = email.split("@")[0];
        const afterAtTheRate = email.split("@")[1];
        if (beforeAtTheRate) {
            if (beforeAtTheRate.length >= 3) {
                beforeText =
                    beforeAtTheRate[0] +
                    new Array(beforeAtTheRate.length - 1).join("*") +
                    beforeAtTheRate[beforeAtTheRate.length - 1];
            } else if (beforeAtTheRate.length === 2) {
                beforeText = "**";
            } else {
                beforeText = "*";
            }
            return beforeText.concat("@").concat(afterAtTheRate);
        }
    }

    prepareComboId(id, fname, lname, mobile) {
        let combo = "";
        if (mobile) {
            combo =
                "patient#" +
                id +
                "##firstName#" +
                fname +
                "##lastName#" +
                lname +
                "##mobile#" +
                mobile;
        } else {
            combo =
                "patient#" +
                id +
                "##firstName#" +
                fname +
                "##lastName#" +
                lname;
        }

        return combo;
        // return combo = combo.replace(/\s/g, '').toLowerCase();
    }

    preparePatientId(id, fname, lname) {
        const patientId =
            "patient#" + id + "##firstName#" + fname + "##lastName#" + lname;
        return patientId;
        // return combo = combo.replace(/\s/g, '').toLowerCase();
    }

    prepareCovidId(id, fname, lname) {
        const patientId =
            "patient#" + id + "##firstName#" + fname + "##lastName#" + lname;
        return patientId;
        // return combo = combo.replace(/\s/g, '').toLowerCase();
    }

    updateRow(row): any {
        // setDeriveFields(row);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        PatientComponent.oldData = this.dataSource.data;
        PatientComponent.cursor = this.infiniteScroll.getCursor();
    }

    getTitleMatIcon(): string {
        return "accessibility";
    }

    getTitle(): string {
        return "Patients";
    }

    getSearchText(): string {
        return "Search Patient via name";
    }

    extraButtonClick(): void {
        this.searchText='';
        const dialogRef = this.dialog.open(AddPatientDialogComponent, {
            disableClose: true,
            width: "350px",
            data: { id: "1", firstName: "", lastName: "" },
        });

        dialogRef.beforeClosed().subscribe((bfrC) => {});
        dialogRef.afterClosed().subscribe((result) => {});
    }

    shareCovid(comboId, patient): void {
        console.log('mobileNo=',patient.mobileNo,patient.emailId)
        console.log(comboId);
        const fname = comboId.split("##")[1].split("#")[1];
        const lName = comboId.split("##")[2].split("#")[1];
        const fullName = fname + " " + lName;

        const dialogRef = this.dialog.open(ShareAssessmentDialogComponent, {
            width: "350px",
            disableClose: true,
            data: {
                comboId: comboId,
                firstName: fname,
                lastName: lName,
                emailId: patient.emailId,
                patientId: patient.id,
                mobileNo: patient.mobileNo,
            },
        });
       
        dialogRef.afterClosed().subscribe((result) => {
            const getate = new Date(result.dateTime); // isoString to date time
            console.log(getate);
            this.dateTime = getate;
            console.log(this.dateTime);
       this.token= 0;
           if (result && result !== undefined)  {

             if(result.mobileNo && result.mobileNo !== undefined){
                this.loadingSpinner=false;
                console.log(result.mobileNo);
                const data = {
                    mobileNo: result.mobileNo,
                    comboId: comboId,
                    fullName: fullName,
                    patient: patient.id,
                    patientId: patient.id,
                    labId: this.userSevice1Child.getCookie("lab"),
                    firstName: fname,
                    lastName: lName,
                    countryCode: result.countryCode,
                };
                console.log('Email=',patient.emailId)
                if(patient.emailId == undefined){
                    patient.emailId =''
                }
                this.covidService.getTokenInitValue().then(async (token) => {
                    this.token = await token.screeningId;
                    console.log(this.token);
                    const covidData: any = {
                        mediumSelected:'SMS',
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.mobileNo,
                        email: patient.emailId,
                        labName: data.labId,
                        patient: patient.id,
                        patientId: data.patientId,
                        addedOn: -1 * new Date().getTime(),
                        tokenId: this.token,
                        anyScreening: false,
                        doaTimestamp: -1 * getate.getTime(),
                        doa: getate.getTime(),
                        // appointTime: this.addZeroToHourAndMinutes(getate.getHours()) + ':' + this.addZeroToHourAndMinutes(getate.getMinutes()),
                        // appointTime: this.firebaseUtilService.getTimeWithAmPm(getate,true),
                        appointTime: timeZone(getate.getTime())
                            .tz(this.userSevice1Child.getCookie("defaultTz"))
                            .format("hh:mm A"),
                        countryCode: data.countryCode  
                    };
    
                    const splitMapFName = data.firstName.toUpperCase().split(" ");
                    let splitMapF = {};
                    for (let i of splitMapFName) {
                        splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                    }
                    const splitMapLName = data.firstName.toUpperCase().split(" ");
                    let splitMapL = {};
                    for (let j of splitMapLName) {
                        splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                    }
    
                    const firstNameMap = this.getMapValue(
                        this.toUpperCaseString(data.firstName).replace(/\s/g, "")
                    );
                    const lastNameMap = this.getMapValue(
                        this.toUpperCaseString(data.lastName).replace(/\s/g, "")
                    );
                    const fullNameMap = (data.firstName + data.lastName)
                        .replace(/\s/g, "")
                        .toUpperCase();
                    const idMap = this.getMapValue(
                        this.toUpperCaseString(data.patientId)
                    );
                    const fullMapData = this.getMapValue(fullNameMap);
                    const anyMap = Object.assign(
                        {},
                        idMap,
                        firstNameMap,
                        lastNameMap,
                        fullMapData,
                        splitMapF,
                        splitMapL
                    );
    
                    // anyMap[fullNameMap] = true;
                    covidData.anyMap = anyMap;
                    const resultDataCovid =
                        this.covidService.addCovidResult(covidData);
                    resultDataCovid.then(async (returnData: any) => {
                        if (await returnData) {
                            console.log(returnData);
    
                            const lab = this.userSevice1Child.getCookie("lab");
                            let url = this.global.siteUrl + "covid-assessment-start?token=";
                    
                            const urlStr = 'key#add##patient#' + data.patientId + '##labName#' + lab + '##email#' + patient.emailId +
                             '##firstName#' + data.firstName + '##lastName#' + data.lastName + '##docId#' + returnData.id;
                            url = url + this.global.encodeToa(urlStr);
    
                    
                            if (data.countryCode === undefined) {
                                data.countryCode = "+1";
                            }
    
                    
                            var loadInfo = {
                                sid: "AC76f661b586ab5b3f2191b18953a3102b",
                                authToken: "719e6a80af86e26d5f570ff2c1a97576",
                                to: data.countryCode + data.mobileNo,
                                from: this.labConfig.sendSMSFromNumber,
                                message: "Message sent..",
                                labId: this.userSevice1Child.getCookie("lab"),
                                patientId: data.patientId,
                                url: url,
                                dateTime: timeZone(getate.getTime())
                                .tz(
                                    this.userSevice1Child.getCookie(
                                        "defaultTz"
                                    )
                                )
                                .format("LL") +
                            " at " +
                            timeZone(getate.getTime())
                                .tz(
                                    this.userSevice1Child.getCookie(
                                        "defaultTz"
                                    )
                                )
                                .format("hh:mm A"),
                            };
                            console.log(loadInfo);
                            Date.prototype.toISOString = function () {
    
                                return moment(this).format("YYYY-MM-DDTHH:mm:ss");
                                
                                };
                            const headers = new HttpHeaders({ "content-type": "application/json" });
                            
                            const body = JSON.stringify(loadInfo);
                            console.log(body);
                            this.httpClient
                                .post<any>(this.global.constUrl + "sendMessage", body, {
                                    headers: headers,
                                })
                                .subscribe(
                                    (res) => {
                                      
                                        console.log(res);
                                        setTimeout(()=>{
                                            this.openSuccessfulDialog();
                                            this.loadingSpinner=true;
                                        }, 8000);
                                    },
                                    (err) => {
                                        
                                        this.openSnackBar(err.message, "ok");
                                        this.loadingSpinner=true;
                                        console.log(err);
                                        // do something
                                    }
                                );
                            
                        }
                    });
                });
                
        }
        
            else{
                this.loadingSpinner=false;
                // console.log('ResultMobile=',result.mobile);
                const data = {
                    email: result.email,
                    comboId: comboId,
                    fullName: fullName,
                    patient: patient.id,
                    patientId: patient.id,
                    labId: this.userSevice1Child.getCookie("lab"),
                    firstName: fname,
                    lastName: lName,
                };
                // LogUtil.log('patientData', data);
                if(patient.mobileNo == undefined){
                    patient.mobileNo =''
                }
                
                this.covidService.getTokenInitValue().then(async (token) => {
                    this.token = await token.screeningId;
                    const covidData: any = {
                        mediumSelected:'EMAIL',
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        labName: data.labId,
                        phone: patient.mobileNo,
                        countryCode:this.getCountrycode,
                        patient: patient.id,
                        patientId: data.patientId,
                        addedOn: -1 * new Date().getTime(),
                        tokenId: this.token,
                        anyScreening: false,
                        doaTimestamp: -1 * getate.getTime(),
                        doa: getate.getTime(),
                        // appointTime: this.addZeroToHourAndMinutes(getate.getHours()) + ':' + this.addZeroToHourAndMinutes(getate.getMinutes()),
                        // appointTime: this.firebaseUtilService.getTimeWithAmPm(getate,true),
                        appointTime: timeZone(getate.getTime())
                            .tz(this.userSevice1Child.getCookie("defaultTz"))
                            .format("hh:mm A"),
                    };

                    console.log(covidData);

                    const splitMapFName = data.firstName
                        .toUpperCase()
                        .split(" ");
                    let splitMapF = {};
                    for (const i of splitMapFName) {
                        splitMapF = Object.assign(
                            splitMapF,
                            this.getMapValue(i)
                        );
                    }

                    const splitMapLName = data.lastName
                        .toUpperCase()
                        .split(" ");
                    let splitMapL = {};
                    for (const j of splitMapLName) {
                        splitMapL = Object.assign(
                            splitMapL,
                            this.getMapValue(j)
                        );
                    }

                    const firstNameMap = this.getMapValue(
                        this.toUpperCaseString(data.firstName).replace(
                            /\s/g,
                            ""
                        )
                    );
                    const lastNameMap = this.getMapValue(
                        this.toUpperCaseString(data.lastName).replace(/\s/g, "")
                    );
                    const fullNameMap = (data.firstName + data.lastName)
                        .replace(/\s/g, "")
                        .toUpperCase();
                    const idMap = this.getMapValue(
                        this.toUpperCaseString(data.patientId)
                    );
                    const fullNameMapData = this.getMapValue(fullNameMap);
                    const anyMap = Object.assign(
                        {},
                        idMap,
                        firstNameMap,
                        lastNameMap,
                        fullNameMapData,
                        splitMapF,
                        splitMapL
                    );
                    // anyMap[fullNameMap] = true;
                    covidData.anyMap = anyMap;

                    const ResultDataCovid =
                        this.covidService.addCovidResult(covidData);
                    ResultDataCovid.then(async (returnData: any) => {
                        if (await returnData) {
                            // updating token config , using trigger inspite of below query
                            // this.covidService.updateTokenConfig({tokenId:covidData.tokenId});
                            data["docId"] = returnData.id;
                            data["dateTime"] =
                                timeZone(getate.getTime())
                                    .tz(
                                        this.userSevice1Child.getCookie(
                                            "defaultTz"
                                        )
                                    )
                                    .format("LL") +
                                " at " +
                                timeZone(getate.getTime())
                                    .tz(
                                        this.userSevice1Child.getCookie(
                                            "defaultTz"
                                        )
                                    )
                                    .format("hh:mm A");
                            // data['dateTime'] = getate.toDateString() + ' at ' + covidData['appointTime'];

                            this.covidService
                                .shareLinkUrl(data)
                                .subscribe((dataResult) => {
                                    setTimeout(()=>{
                                        this.openSuccessfulDialog();
                                        this.loadingSpinner=true;
                                    }, 8000);
                                    

                                });
                        }
                    });
                });
            } 
         }
         });
    }

    addZeroToHourAndMinutes(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
        // if (time < 10) {
        //     return '0' + time;
        // }
        // return time;
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    editButtonClick(comboId): void {
        const dialogRef = this.dialog
            .open(EditPatientDialogComponent, {
                width: "350px",
                data: { id: "", firstName: "", lastName: "", comboId: comboId },
            })
            .afterClosed()
            .subscribe((data) => {
                this.page = 0;
                // this.paginationService.first();
            });
    }

    startAssessment(comboId, emailId) {
        this.staffName = this.staffForm.value.staff;
        // localStorage.setItem('staffName', this.staffName);
        this.userSevice1Child.setCookie("staffName", this.staffName);
        const lab = this.userSevice1Child.getCookie("lab");
        let urlStr = "";
        if (emailId) {
            urlStr =
                comboId +
                "##labName#" +
                lab +
                "##type#direct##key#start##email#" +
                emailId;
        } else {
            urlStr = comboId + "##labName#" + lab + "##type#direct##key#start";
        }

        this.router.navigate(["/covid-assessment-start"], {
            queryParams: { token: this.global.encodeToa(urlStr) },
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    viewHistory(patientId) {
        let addSearchText = "";
        if (this.searchText) {
            addSearchText = patientId + "##searchTxt#" + this.searchText;
        } else {
            addSearchText = patientId;
        }

        this.router.navigate(["/patientdetail"], {
            queryParams: { token: this.global.encodeToa(addSearchText) },
        });
    }

    viewCovidHistory(patientId) {
        let addSearchText = "";
        if (this.searchText) {
            addSearchText = patientId + "##searchTxt#" + this.searchText;
        } else {
            addSearchText = patientId;
        }
        this.router.navigate(["/covidviewdetail"], {
            queryParams: { token: this.global.encodeToa(addSearchText) },
        });
    }

    openDialogWithRef(ref: TemplateRef<any>, comboId, emailId) {
        this.dialog.open(ref, {
            disableClose: true,
            height: "250px",
            width: "350px",
        });
    }

    showImage(data, comboId, email, pad) {
        if (pad.signaturePad.isEmpty() || this.staffForm.invalid) {
            if (this.staffForm.invalid && pad.signaturePad.isEmpty()) {
                this.staffForm.markAllAsTouched();
                return;
            } else if (this.staffForm.invalid && !pad.signaturePad.isEmpty()) {
                this.staffForm.markAllAsTouched();
                return;
            } else if (!this.staffForm.invalid && pad.signaturePad.isEmpty()) {
                this.alertSign = "Signature required";
                return;
            }
        } else {
            this.dialog.closeAll();
            this.alertSign = "";

            // this.staffName = this.staffForm.value.staff;
            this.signatureImage = data;
            // localStorage.setItem('staffName', this.staffName);
            this.userSevice1Child.setCookie("staffName", this.staffName);
            localStorage.setItem("signatureImage", this.signatureImage);
            this.startAssessment(comboId, email);
        }
    }

    openSuccessfulDialog() {
        const dialogRef = this.dialog.open(SuccessfulComponent, {
            disableClose: true,
            width: "350px",
            height: "250px",
            // data: {firstName: this.firstName, lastName: this.lastName, pid: this.urlPatientId}
        });
    }

    changeTimestampToDate(timestamp) {
        if (timestamp) {
            // new Date('03/17/2021');
            // return timeZone(timestamp).tz(this.userSevice1Child.getCookie('defaultTz')).format('DD/MM/YYYY');
            return moment(timestamp).format("DD/MM/YYYY");
        }
        return "";
    }

    protected getPaginationService() {
        return this.paginationService;
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }

    getSubscriptionType() {
        console.log("navType", this.navigationType);
        switch (this.navigationType) {
            case "screening":
                return true;
            default:
                return false;
        }
    }
}
