import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {LogsComponent} from './logs.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {LogsService} from './logs.service';
import {MatChipsModule} from '@angular/material/chips';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';
import {AddPatientDialogComponent} from '../patient/addpatient/add-patient-dialog.component';
import {ShareAssessmentDialogComponent} from '../patient/shareAssessmentLink/share-assessment-dialog.component';
import {AppModule} from '../../../../app.module';
// import {ScrollContainerComponent} from '../../common/pagination/scroll-container/scroll-container.component';


const routes = [
    {
        path     : 'logs',
        component: LogsComponent,
        canActivate: [AuthGuard],
        resolve:{
            logsResolver:LogsService
        }
    },
];

@NgModule({
    declarations: [
        LogsComponent,
        
        // ScrollContainerComponent
    ],
    imports: [
        // [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
        RouterModule.forChild(routes ),
        TranslateModule,
        SharedModule,
        FuseSharedModule,
        MatChipsModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),
        FuseWidgetModule,
    ],

    entryComponents: [
    ],
    exports     : [
        LogsComponent,
        SharedModule,
    ],
    providers   : [
        LogsService
]
})

export class LogsModule
{
}