import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable({ providedIn: 'root' })

export class PatientdetailService {
    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    currentPage = 0;
    protected table = 'cassette';
    protected orderBy = '';
    protected searchBy = 'barcode';
    protected isOrderByNegative = true;
    public searchedText: string = null;
    public patientId = '';
    public colPatientId = 'patientId';
    public colAssigned = 'isAssignDone';
    constructor(private afs: AngularFirestore, private userService: StorageService) {
    }
    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }
    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }
    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                const doc = a.payload.doc;
                return { id, ...data as {}, doc };
            });
        }));
    }
    search(): any {
        let query = null;
        if (this.searchedText) {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .where(this.orderBy, '<', 0)
                .where(this.searchBy, '==', this.searchedText)
                .where(this.colPatientId, '==', this.patientId)
                .where(this.colAssigned, '==', true)
                .limit(this.ROWS));
        } else {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .where(this.orderBy, '<', 0)
                .where(this.colPatientId, '==', this.patientId)
                .where(this.colAssigned, '==', true)
                .limit(this.ROWS));
        }
        query.subscribe(data => {
            if ((this.searchedText)) {
                this.pageSubject.next(0);
                this.itemsSubject.next(data);
            } else {
                this.pageSubject.next(0);
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.itemsSubject.next(data);
            }
            // query.unsubscribe();
        });
    }

    first(): any {
        this.currentPage = 0;
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();
        let query = null;
        if (this.isOrderByNegative) {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .where(this.orderBy, '<', 0)
                .where(this.colPatientId, '==', this.patientId)
                .where(this.colAssigned, '==', true)
                .limit(this.ROWS));
        } else {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .where('patientId', '==', this.patientId)
                .where(this.colAssigned, '==', true)
                .limit(this.ROWS));
        }
        query.subscribe(data => {
            this.pageSubject.next(0);
            try {
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
            } catch (e) {//dp something

            }
            this.itemsSubject.next(data);
            // scoresRef.unsubscribe();
        });
    }
    next(): void {
        let query = null;
        if (this.isOrderByNegative) {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .startAfter(this.latestEntry)
                .where(this.orderBy, '<', 0)
                .where(this.colPatientId, '==', this.patientId)
                .where(this.colAssigned, '==', true)
                .limit(this.ROWS));
        } else {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .startAfter(this.latestEntry)
                .limit(this.ROWS));
        }
        query.subscribe(data => {
            try {
                // And save it again for more queries
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.currentPage++;
                this.pageSubject.next(this.currentPage);

            } catch (e) {//dp something
            }
            this.itemsSubject.next(data);
            // scoresRef.unsubscribe();
        });
    }

    prev(): void {
        let query = null;
        if (this.isOrderByNegative) {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy, 'desc')
                .startAfter(this.startingEntry)
                .where(this.orderBy, '<', 0)
                .where(this.colPatientId, '==', this.patientId)
                .where(this.colAssigned, '==', true)
                .limit(this.ROWS));
        } else {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .startAfter(this.startingEntry)
                .limit(this.ROWS));
        }
        query.subscribe(data => {

            try {
                data.reverse();
                // And save it again for more queries
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.currentPage--;
                this.pageSubject.next(this.currentPage);
            } catch (e) {//dp something
            }
            this.itemsSubject.next(data);
            // scoresRef.unsubscribe();
        });
    }

    getCassetteById(id): Observable<any[]> {

        const patientCollectionPath = 'lab/' + this.userService.getCookie("lab") + '/' + 'cassette';;
        return this.afs.collection(patientCollectionPath, ref => ref.where('id', '==', id)).snapshotChanges()
            .pipe(map(actions => {
                return actions.map((a: any) => {
                    return a.payload.doc.data();
                });
            }));


    }

    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/' + this.table;
    }
}
