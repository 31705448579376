import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FuseWidgetModule } from '../../../../../@fuse/components';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '../../common/shared.module';
import { AuthGuard } from '../../auth/auth.guard';
import { EmailListComponent } from "./list/EmailList.component";
import { EmailListService } from "./list/EmailList.service";
import { EmaildDetailComponent } from "./viewemaildetails/vieweEmailDetails.component";
import { CommonModule } from '@angular/common';

const routes = [
    {
        path: 'list',
        component: EmailListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'emaildetail',
        component: EmaildDetailComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    declarations: [
        EmailListComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        SharedModule,
        FuseSharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        CommonModule,
        // NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,

    ],
    exports: [
        EmailListComponent,
        CommonModule,
    ],
    providers: [
        EmailListService,
    ]
})

export class EmailModule {
}
