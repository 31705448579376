import { Component, Inject, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientService } from '../patient.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Patient } from '../patient';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
const timeZone = require('moment-timezone');
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../report/format-datepicker';
import * as moment from 'moment';
import { ApiService } from '../../../common/service/api.service';

@Component({
    selector: 'app-edit-patient-dialog',
    templateUrl: './edit-patient-dialog.component.html',
    styleUrls: ['edit-patient-dialog.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]

})
export class EditPatientDialogComponent implements OnInit {
    page = 0;
    patientForm: FormGroup;
    platform = 'web';
    comboId: string;
    @Input()
    min: 'D' | null;
    today = new Date();
    dobStamp: any;
    dobDate: any;
    dobString = '';
    rowId: any;
    deleted: boolean = false;
    addedOn: any;
    addedBy: any;

    constructor(
        public dialogRef: MatDialogRef<EditPatientDialogComponent>,
        private _formBuilder: FormBuilder,
        private changeDetectorRefs: ChangeDetectorRef,
        public patientService: PatientService,
        public snackBar: MatSnackBar,
        public route: Router,
        private userService: StorageService,
        private cookieService: CookieService,
        private apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: Patient) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.comboId = this.dialogRef.componentInstance.data.comboId;
        // const comboData = this.dialogRef.componentInstance.data.comboId.toLowerCase();
        const comboData = this.dialogRef.componentInstance.data.comboId;
        this.patientService.getPatientById(comboData).then((data: any) => {

            if (data.dobString) {
                console.log(data.dobString);
                // swap value of month and date
                console.log(this.apiService.swapMonthandDate(data.dobString));
                this.dobDate = new Date(this.apiService.swapMonthandDate(data.dobString));
                console.log(this.dobDate);
            }
            else {
                if (data.hasOwnProperty('dob')) {
                    if (data.dob === 0) {
                        this.dobDate = '';
                    } else {
                        this.dobDate = new Date(data.dob);
                    }
                } else {
                    this.dobDate = '';
                }
            }

            if (data.rowId !== undefined) {
                this.rowId = data.rowId;
                console.log(this.rowId);
            }
            if (data.addedBy !== undefined) {
                this.addedBy = data.addedBy;
                console.log(this.addedBy);
            }
            else {
                this.addedBy = ""
            }
            if (data.addedOn !== undefined) {
                this.addedOn = data.addedOn;
                console.log(this.addedOn);
            }
            else {
                this.addedOn = ""
            }



            this.patientForm.setValue({
                // @ts-ignore
                firstName: data.firstName,
                // @ts-ignore
                id: data.id,
                // @ts-ignore
                lastName: data.lastName,
                emailId: data.emailId ? data.emailId : '',
                mobileNo: data.mobileNo ? data.mobileNo : '',
                dob: this.dobDate,
            });

        }).catch(error => {
            this.handleError(error);

        });

        this.patientForm = this._formBuilder.group({
            // firstName: ['', [Validators.required, Validators.pattern(/^\S+/)], ''],
            firstName: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]/)]],
            id: ['', [Validators.required, Validators.pattern(/^\S+/)], ''],
            lastName: ['', [Validators.required, Validators.pattern(/^\S+/)],],
            emailId: ['', [Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
            // mobileNo: ['', [ Validators.pattern(/^\d{10}$/)], ''],
            mobileNo: [   "", [ Validators.pattern("^((\\+91-?))?[0-9]{10}$"),], ],
            dob: ['', ''],

        });


    }


    editPatientDetail() {
        if (this.patientForm.value.dob) {
            const date = new Date(this.patientForm.value.dob);
            this.dobString = moment(this.patientForm.value.dob, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.dobStamp = date.getTime();
        } else {
            this.dobStamp = 0;
        }

        return new Promise((resolve, reject) => {
            const patientData =
            {
                anyMap: undefined,
                firstName: this.patientForm.value.firstName.trim().toUpperCase(),
                firstNameMap: undefined,   // initialized
                lastName: this.patientForm.value.lastName.trim().toUpperCase(),
                lastNameMap: undefined, // initialized
                id: this.patientForm.value.id.trim().toUpperCase(),
                emailId: this.patientForm.value.emailId,
                mobileNo: this.patientForm.value.mobileNo ? (this.patientForm.value.mobileNo).toString() : '',
                dob: this.dobStamp,
                // docId: this.patientForm.value.id + this.patientForm.value.firstName + this.patientForm.value.lastName,
                idMap: undefined,   // initialized
                platform: this.platform,
                addedBy: this.addedBy,
                addedOn: this.addedOn,
                dobString: this.dobString,
                rowId: this.rowId,
                deleted: this.deleted,
                updateBy: this.userService.getCookie("email")
                    ? this.userService.getCookie("email")
                    : "NA",
                updatedOn: (-1 * new Date().getTime()),

            };

            const fName = this.patientForm.value.firstName;
            const lName = this.patientForm.value.lastName;
            const id = this.patientForm.value.id;

            // let combo = (this.patientForm.value.id + this.patientForm.value.firstName + this.patientForm.value.lastName).toLowerCase();
            //
            // combo = combo.replace(/\s/g, '').toLowerCase();

            let combo = (this.patientForm.value.id + this.patientForm.value.firstName + this.patientForm.value.lastName);

            let comboId = combo.toLowerCase();

            comboId = comboId.replace(/\s/g, '');

            combo = combo.replace(/\s/g, '');

            combo = this.toUpperCaseString(combo);


            // this.patientService.deletePatientById(this.comboId).then(() => {
            this.patientService.deletePatientById(this.comboId).then(() => {

                // const anyMap = this.getMapValue(combo);

                // const firstNameMap = this.getMapValue(this.toUpperCaseString(fName));

                // const lastNameMap = this.getMapValue(this.toUpperCaseString(lName));

                // const idMap = this.getMapValue(this.toUpperCaseString(id));
                // const fullNameMap = this.getMapValue(this.toUpperCaseString(fName+' '+lName));
                // const anyMap = Object.assign({},idMap,firstNameMap,lastNameMap,fullNameMap);

            }).then(addAgain => {

                const splitMapFName = fName.trim().toUpperCase().split(' ');

                let splitMapF = {}
                for (let i of splitMapFName) {
                    splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                }

                const splitMapLName = lName.trim().toUpperCase().split(' ');
                let splitMapL = {}
                for (let j of splitMapLName) {
                    splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                }


                const firstNameMap = this.getMapValue(this.toUpperCaseString(fName).replace(/\s/g, ''));
                const lastNameMap = this.getMapValue(this.toUpperCaseString(lName).replace(/\s/g, ''));
                const fullNameMap = (fName + lName).replace(/\s/g, '').toUpperCase();
                const fnamenameCombo = this.getMapValue(fullNameMap);

                const idMap = this.getMapValue(this.toUpperCaseString(id));
                const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap, fnamenameCombo, splitMapL, splitMapF);
                // anyMap[fullNameMap] = true;


                // const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap);

                patientData.anyMap = anyMap;
                patientData.firstNameMap = firstNameMap;
                patientData.lastNameMap = lastNameMap;
                patientData.idMap = idMap;
                patientData.rowId = this.rowId;
                patientData.deleted = false;

                const returnData = this.patientService.addPatient(patientData, id);
                // const returnData =  this.patientService.addPatient(patientData, comboId);
                returnData.then(result => {
                    const msg = 'Patient Updated Successfully';
                    this.openSnackBar(msg, 'OK');


                }).catch((error) => {
                    this.handleError(error);

                });

            }).catch((error) => {
                this.handleError(error);
            });

            this.patientForm.reset();

        })
    }



    toUpperCaseString(str) {
        return (str.trim()).toUpperCase();
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }


    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, '');
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = '';
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;

        }
        return obj;
    }

    numericOnly(event): boolean {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }

}
