import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FormBuilder } from '@angular/forms';
import { FuseConfigService } from '../../../../../../@fuse/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../common/global';
import { CovidstaffService } from '../covidstaff.service';
import { CovidService } from '../../covid.service';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Component({
    selector: 'app-staffconfirmcard',
    templateUrl: './staffconfirmcard.component.html',
    styleUrls: ['./staffconfirmcard.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class StaffconfirmcardComponent implements OnInit, OnDestroy {

    covidresult = '';
    selectedOpt = '';
    logoUrl = '';
    labWebSiteUrl = '';
    token = '';
    isLoading: boolean;
    queryParam = {};
    staffName = '';
    staffResolutionResult: any;

    constructor(private _fuseConfigService: FuseConfigService,
        private route: ActivatedRoute, private router: Router,
        public global: Globals,
        private covidstaffService: CovidstaffService,
        private userService: StorageService,
        private covidService: CovidService) {

        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.isLoading = true;
    }

    ngOnInit(): void {

        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.global.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }
        this.covidstaffService.getCovidResultByIdWithoutLogin(this.queryParam['docId'], this.queryParam['labName']).then((data: any) => {
            this.staffName = data.name;
        });

        const collectionPath = `/lab/${this.queryParam['labName']}/config/`;
        const logoPath = this.covidstaffService.getLabLogo(collectionPath);
        logoPath.ref.get().then(data => {
            const logoPathName = data.data();
            this.logoUrl = logoPathName['logoUrl'] ? logoPathName['logoUrl'] : this.global.siteLogo;
            this.labWebSiteUrl = logoPathName['websiteUrl'];
        });

        this.selectedOpt = localStorage.getItem('staffAns');

        this.covidService.getAllResolution(this.queryParam['labName']).then(async resultData => {
            this.staffResolutionResult = await resultData;
        }).then(resultData => {

            if (this.queryParam['key'] === 'email') {

                if (this.selectedOpt === 'No') {
                    this.covidresult = this.replaceText(this.staffResolutionResult.staffAtHomeRiskResolutionNo);
                    // this.covidresult = 'Thank you. We look forward to see you tomorrow';
                } else {
                    this.covidresult = this.replaceText(this.staffResolutionResult.staffAtHomeRiskResolutionYes);
                    // this.covidresult = 'Please contact your office manager or supervisor as soon as possible, prior to coming to office';
                }
            } else {

                if (this.selectedOpt === 'No') {
                    this.covidresult = this.replaceText(this.staffResolutionResult.staffInOfficeRiskResolutionNo);

                } else {
                    this.covidresult = this.replaceText(this.staffResolutionResult.staffInOfficeRiskResolutionYes);

                }

            }


        });


    }


    replaceText(str) {

        const pattern = /{staffName}/g;

        const newStr = str.replace(pattern, this.staffName);
        return newStr;


    }

    hideLoader() {
        this.isLoading = false;
    }

    visitLab() {
        if (this.userService.getCookie("lab")) {
            this.router.navigate(['/covidstaffresultlist']);
        } else {
            if (this.labWebSiteUrl) {
                window.open(this.labWebSiteUrl, 'blank');
            } else {
                alert('Please ask sterilwize team to add your website URL');
                window.open('', 'blank');
            }
        }


    }


    ngOnDestroy() {

        localStorage.setItem('staffAns', '');
    }

}


