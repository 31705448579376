import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CovidDto } from '../../../CovidDto.modal';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QueryFn } from '@angular/fire/firestore/interfaces';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../../common/global';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class ScreeningPatientHistoryListService {
    public snackBar: MatSnackBar
    public notFoundSubject: BehaviorSubject<boolean>;
    public notFoundObservable: Observable<boolean>;
    private itemsSubject: BehaviorSubject<CovidDto[]>;
    public itemsObservable: Observable<CovidDto[]>;
    private searchSubject: BehaviorSubject<CovidDto[]>;
    public searchObservable: Observable<CovidDto[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    table = 'patientResult';
    resultTable = 'patientResult';
    orderBy = 'doaTimestamp';
    currentPage = 0;
    patientIdCol = 'patientId';

    protected isOrderByNegative = true;
    protected searchBy = 'barcode';
    public searchedText: string = null;
    public patientId: string = null;
    hasFirstDataShown: boolean;
    lastSnackTime: number;

    constructor(private afs: AngularFirestore, public globals: Globals,
        private userService: StorageService,
        public httpClient: HttpClient,) {
    }

    setTable(table, orderBy): void {
        console.log(table,orderBy)
        this.table = table;
        this.orderBy = orderBy;
    }

    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }


    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const docId = a.payload.doc.id;
                const doc = a.payload.doc;
                return { docId, ...data as {}, doc };
            });
        }));
    }


    // getCollection(ref, queryFn?): Observable<any[]> {
    //
    //     const patientCollectionPath = this.getCovidResultPath();
    //     return this.afs.collection(patientCollectionPath,ref =>
    //         ref.orderBy('addedOn','asc')).snapshotChanges().pipe(map(actions => {
    //
    //         // this.afs.collection(covidDtoCollectionPath).doc(docId)
    //         return actions.map(a => {
    //             const data = a.payload.doc.data();
    //
    //             const docId = a.payload.doc.id;
    //             const doc = a.payload.doc;
    //             return {docId, ...data as {}, doc};
    //         });
    //     }));
    // }

    getSearchCollection(covidDto): Observable<any[]> {
        if (covidDto) {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .where(this.covidDtoSearchQuery(covidDto.toUpperCase()), '==', true)
                .limit(this.ROWS));

        } else {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .limit(this.ROWS));
        }
    }

    getCovidDto(covidDto): Observable<CovidDto[]> {
        this.searchSubject = new BehaviorSubject([]);
        this.searchObservable = this.searchSubject.asObservable();
        console.log('searchObservable=',this.searchObservable );
        const ref = this.getSearchCollection(covidDto.toUpperCase())
            .subscribe(data => {
                if (data && data.length > 0) {
                    // this.searchSubject.next(data);

                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.searchSubject.next(data);

                } else {
                    this.searchSubject.next(null);

                    this.notFoundSubject.next(true);
                    this.notFoundSubject.next(false);
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }

    search(): any {
        if (this.searchedText) {

            this.getCovidDto(this.searchedText).subscribe(covidDto => {
                this.itemsSubject.next(covidDto);
            });
        } else {
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .limit(this.ROWS))
                .subscribe(data => {
                    this.pageSubject.next(0);
                    if (data) {
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.itemsSubject.next(data);
                    } else {
                        // do something
                    }
                    // scoresRef.unsubscribe();
                });
        }
    }

    first(): void {
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.patientIdCol, '==', this.patientId)
            .limit(this.ROWS)
        )
            .subscribe(data => {
                this.pageSubject.next(0);
                if (data && data.length > 0) {
                    this.latestEntry = data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.itemsSubject.next(data);
                } else {
                    // do something
                }
                // scoresRef.unsubscribe();
            });
    }







    next(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.patientIdCol, '==', this.patientId)
            .startAfter(this.latestEntry)
            .limit(this.ROWS))
            .subscribe(data => {
                try {
                    if (data && data.length) {
                        this.currentPage++;

                        // And save it again for more queries
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.pageSubject.next(this.currentPage);
                        this.itemsSubject.next(data);
                    }
                } catch (e) {
                    // do something
                }
                this.itemsSubject.next(data);
                // scoresRef.unsubscribe();
            });
    }


    prev(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            .where(this.patientIdCol, '==', this.patientId)
            // Now you can use the latestEntry to query with startAfter
            .startAfter(this.startingEntry)
            .limit(this.ROWS))
            .subscribe(data => {
                try {
                    if (data && data.length) {
                        data.reverse();
                        // And save it again for more queries
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.currentPage--;
                        this.pageSubject.next(this.currentPage);
                        this.itemsSubject.next(data);
                    }
                } catch (e) {
                    // do something
                }
                this.itemsSubject.next(data);
                // scoresRef.unsubscribe();
            });
    }


    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/covid/result/' + this.table;
    }

    covidDtoSearchQuery(covidDto): string {
        return 'anyMap.' + covidDto;
    }


    //    add covidDto starts

    addCovidDto(covidDtoData: any, docId) {

        const covidDtoCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(covidDtoCollectionPath).doc(docId).set(covidDtoData).then((resultData) => {
                const data = { msg: 'CovidDto Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });

        });
    }


    getSearchRangeCollection(condition,patientId): Observable<any[]> {
        const startTimestamp = condition.from;
        // console.log(startTimestamp)
        const endTimestamp = condition.to;
        // console.log(endTimestamp)

        if (condition) {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .where('doa', '>=', startTimestamp)
                .where('doa', '<=', endTimestamp)
                .orderBy('doa', 'desc')
                .where(this.patientIdCol, '==', patientId)
                .limit(1000));

        } 
        else {
            // this.openSnackBar('No data found', 'OK')
                 console.log('else');
            return this.getCollection(this.getCollectionPath(), ref => ref
                .limit(this.ROWS));
        }
    }

    searchRange(condition,patientId): any {
        // console.log(condition,patientId);
        if (condition) {
            this.getCovidDtoRange(condition,patientId).subscribe(covidDto => {
                this.itemsSubject.next(covidDto);
            });
        } else {
            console.log(condition,patientId);
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy('doa', 'desc')
                .where(this.patientIdCol, '==', patientId)
                .limit(this.ROWS))
                .subscribe(data => {
                    this.pageSubject.next(0);
                    if (data) {
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.itemsSubject.next(data);
                    } else {
                        // do something
                    }
                    // scoresRef.unsubscribe();
                });
        }
    }




    getCovidDtoRange(condition,patientId): Observable<CovidDto[]> {
        // console.log(condition,patientId);
        this.searchSubject = new BehaviorSubject([]);
        console.log(this.searchSubject)
        this.searchObservable = this.searchSubject.asObservable();
        console.log(this.searchObservable)
        const ref = this.getSearchRangeCollection(condition,patientId)
            .subscribe(data => {
                this.hasFirstDataShown = false;
                if (data && data.length > 0) {
                    // this.searchSubject.next(data);
                    this.hasFirstDataShown = true;
                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.searchSubject.next(data);

                } else {
                   console.log('call else');
                    this.hasFirstDataShown = false;
                    this.searchSubject.next(null);

                     this.notFoundSubject.next(true);
                     this.notFoundSubject.next(false);
                    this.hasFirstDataShown = true;
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }

    getCovidDtoById(docId) {
        const covidDtoCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(covidDtoCollectionPath).doc(docId).valueChanges().subscribe(data => {
                resolve(data);
            });
        });
    }

    deleteCovidDtoById(docId) {
        const covidDtoCollectionPath = this.getCollectionPath();
        // this.afs.collection(covidDtoCollectionPath).doc(docId).delete().then(() => {

        // }).catch((error)=> {
        // })

        return this.afs.collection(covidDtoCollectionPath).doc(docId).delete();


    }


    //    add patient starts

    getCovidResultPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/' + this.resultTable;
    }

    getCovidGuestResultPath(): string {
        return 'covidGuest/';
    }

    addCovidResult(covidResultData: any) {
        const patientCollectionPath = this.getCovidResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(resultData);
            }).catch((error) => {
                reject(error);
            });

        });
    }





    addCovidGuestResult(covidResultData: any) {
        const patientCollectionPath = this.getCovidGuestResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });

        });
    }



    getCovidResultById(docId) {
        const patientCollectionPath = this.getCovidResultPath();

        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).valueChanges().subscribe(data => {

                resolve(data);
            });
        });
    }

    updateCovidResult(covidData, covidId) {
        const patientCollectionPath = this.getCovidResultPath();
        return this.afs.collection(patientCollectionPath).doc(covidId).update(covidData);
    }

    shareLinkUrl(getLoadData): Observable<any> {
        const lab = this.userService.getCookie("lab");
        let url = this.globals.siteUrl + 'covid-assessment-start?token=';

        // const urlStr = '?key=add&patient='+this.globals.encodeToa(getLoadData.patientId)+'&labName='+this.globals.encodeToa(lab);

        const urlStr = 'key#add##' + getLoadData.patientId + '##labName#' + lab + '##email#' + getLoadData.email;

        url = url + this.globals.encodeToa(urlStr);

        const data: any = { email: getLoadData.email, url: url };
        return this.httpClient.post(this.globals.constUrl + 'sendCovidAssessmentEmail', data);
    }

    getLabLogo(collectionPath) {
        return this.afs.collection(collectionPath).doc('lab');
    }


}
