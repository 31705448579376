import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../../common/service/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CovidStaffDto } from '../CovidSatffDto.modal';
import { MatDialog } from '@angular/material/dialog';
import { CovidstaffService } from '../covidstaff.service';
import { Globals } from '../../../common/global';
import { TeamService } from '../../../sterilwize/team/team.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShareAssessmentDialogComponent } from '../../../sterilwize/patient/shareAssessmentLink/share-assessment-dialog.component';
import { LogUtil } from '../../../../util/LogUtil';
import { SuccessfulComponent } from '../../../sterilwize/patient/successful/successful.component';
import { ApiService } from '../../../common/service/api.service';
import { Subscription } from 'rxjs';
import {CovidStaffResultInfiniteScroll} from './covidstaffressult-infinite-scrol.service';


@Component({
    selector: 'app-covidstaffresultlist',
    templateUrl: './covidstaffresultlist.component.html',
    styleUrls: ['./covidstaffresultlist.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CovidstaffresultlistComponent implements OnInit, OnDestroy, AfterViewInit {

    // dataSource = new MatTableDataSource<any>();
    // displayedColumns: string[] = ['Sr', 'ID', 'First Name', 'Last Name'];
    staffForm: FormGroup;
    patient: string;
    page = 0;
    columns = this.getColumn();
    columns2=this.getColumnInoffice();
   displayedColumns2 = this.columns2.map(c => c.columnDef);
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    searchText: string;
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    urlPatientId: any;
    urlLabName = this.userSevice1.getCookie("lab");
    perPageData = this.global.perPageData;
    staffName = '';
    alertSign = '';
    signatureImage: any;
    staffDropDown: any;
    isEnterPressed = false;
    isAssignedPressed = false;
    lastSnackTime = 0;
    @ViewChild('filter', { static: true })
    filter: ElementRef;
    length: any;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    hasFirstDataShown = false;
    enableInfoMsg = false;
    staffScreeningEnable: Boolean;
    isInitComplete = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    isLoading = false;
    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any;
    isMoreDataAvailable = true;


    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;

    protected extraCta = {
        visibility: false,
        text: '',
    };

    constructor(
        private userSevice1: StorageService, private fb1: FormBuilder,
        private route1: ActivatedRoute, private router1: Router,
        private route: ActivatedRoute, private router: Router,
        private infiniteScroll: CovidStaffResultInfiniteScroll,
        private changeDetectorRefs1: ChangeDetectorRef,
        public dialog: MatDialog,
        private paginationService: CovidstaffService,
        private changeDetectorRefs: ChangeDetectorRef,
        public global: Globals,
        public team: TeamService,
        public snackBar: MatSnackBar,
        public apiService: ApiService,
    ) {
        this._unsubscribeAll = new Subject();
        // if(localStorage.getItem('staticData') === 'Y' ){
        this.extraCta.visibility = true;
        this.extraCta.text = 'Start';
        // }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key.includes('Enter')
            && event.code.includes('Enter')
            && document.activeElement.id.includes('patient')) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {

                this.onClickSearch();
            }
        } else {
            this.isEnterPressed = false;
        }
    }


    /**
     * On init
     */
    ngOnInit(): void {
        this.team.getCovidConfig().then(data => {
            // this.staffScreeningEnable=data.isStaffPreScreeningEnabled ;
             if (data.isStaffPreScreeningEnabled) {
                 this.staffScreeningEnable = true;
                 } else 
                 this.staffScreeningEnable = false; 
 
                  });
        this.page = 0;
       // this.initData();
        this.hasFirstDataShown = false;
        this.team.allTeamData().then(data => {
            this.hasFirstDataShown = true
            this.staffDropDown = data;
        });

        this.staffForm = this.fb1.group({

            staff: ['', [Validators.required], ''],

        });
    }


    initData() {

        if(CovidstaffresultlistComponent.oldData && CovidstaffresultlistComponent.oldData.length > 0){
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck()
            this.changeDetectorRefs.detectChanges()
            this.dataN = CovidstaffresultlistComponent.oldData;
            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            this.isInitComplete = true;
            this.isLoading = false;
            this.initWithOldData()
            return
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.infiniteScroll.init('staffResult', 10, 'addedOn', {reverse: false, prepend: false})

        this.subscription = this.infiniteScroll.data.subscribe(data => {
            if(data){} else return
                        this.dataN = data;
            if (this.dataSource && this.dataN && this.dataSource.data.length == this.dataN.length) {
                this.isMoreDataAvailable = false;
            }
            try{
                data.forEach(value => this.updateRow(value));
            } catch (e){}

            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if(this.dataN && this.dataN.length > 0){
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000)
            } else {

            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 200)
        });
    }


    initWithOldData(){
        this.infiniteScroll.initWithPrev(CovidstaffresultlistComponent.cursor, CovidstaffresultlistComponent.oldData,
            'staffResult', 10, 'addedOn', {reverse: false, prepend: false})

        this.subscription = this.infiniteScroll.data.subscribe(data => {
            if(data){} else return

            this.dataN = data;
            if (this.dataSource && this.dataN &&
                (this.dataSource.data.length - CovidstaffresultlistComponent.oldData.length)  == this.dataN.length) {
                console.log('isMoreDataAvailable' + 'this.dataSource.data.length'  +this.dataSource.data.length
                + 'this.dataN.length'  +this.dataN.length)
                // this.isMoreDataAvailable = false;
            }
            data.forEach(value => this.updateRow(value));
            this.dataSource = new MatTableDataSource(this.dataN);

            this.dataSource.data = CovidstaffresultlistComponent.oldData.concat(this.dataN);
            if(this.dataSource.data && this.dataSource.data.length > 0){
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000)
            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 1000)
        });
        setTimeout(() => {
            this.scrollToBottom();
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck()
            this.changeDetectorRefs.detectChanges()
        }, 1000)
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector('mat-table');
            table.scrollBy({behavior: 'smooth', left: 0, top: table.scrollHeight});
        }

    }

    onClickSearch() {
        // alert('Search Text' + this.searchText);
        this.paginationService.searchedText = this.searchText.replace(/\s/g, '');
        // this.paginationService.searchedText = this.searchText;
        this.paginationService.search();
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();
        this.hasFirstDataShown = false
        this.paginationService.pageObservable.subscribe(pageNumber => {
            // this.hasFirstDataShown = true
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });

        this.hasFirstDataShown = false
        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {
            if (data && data.length > 0) {
                this.hasFirstDataShown = true
                this.length = data.length;
                this.dataSource.data = data;
            }else {
                this.hasFirstDataShown = false;
                this.enableInfoMsg = false;
                setTimeout(()=>{
                    this.hasFirstDataShown = true;
                    this.enableInfoMsg = true;
                },6000)

            }
        });
        this.hasFirstDataShown = false;
        this.paginationService.notFoundObservable.subscribe(data => {
            console.log(data);
            // this.hasFirstDataShown = true;
            if (data && this.searchText && this.searchText.length > 0) {
                this.openSnackBar('No patient found', 'OK');
            }

        });

    }

    nextPage(): void {

        // this.isLoading = true;
        // this.isNextPressed = true;
        this.paginationService.next();
    }


    prevPage(): void {
        this.paginationService.prev();
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // this.subscription.unsubscribe();
        // CovidstaffresultlistComponent.oldData = this.dataSource.data
        // CovidstaffresultlistComponent.cursor = this.infiniteScroll.getCursor()

    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }

    getColumnInoffice(): any[] {
        return [
            {
                columnDef: 'Sr',
                header: 'Sr.',
                cell: (element, index: number) => `${this.page * 10 + index + 1}`,
            },
            { columnDef: 'Name', header: 'Name', cell: (element: CovidStaffDto, index: number) => `${element.name ? element.name : ''}` },
            { columnDef: 'DateTime', header: 'DateTime', cell: (element: CovidStaffDto, index: number) => `${element.inOffTime ? this.apiService.getDateStringMomentByTz(Number(element.inOffTime)) : this.apiService.getDateStringMomentByTz(element.addedOn)}` },
            // {columnDef: 'Time', header: 'Time', cell: (element: CovidStaffDto, index: number) => this.getTime(Math.abs(element.addedOn))},
           // { columnDef: 'home', header: 'Staff Response (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preOffice ? element.preOffice : ''}` },
           // { columnDef: 'Temperaturehome', header: 'Temperature (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preOffTemperature ? element.preOffTemperature + '\xB0' + element.preOffTemperatureUnit : ''}` },
           // { columnDef: 'Spohome', header: 'SpO2 (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preSpo ? element.preSpo : ''}` },
            { columnDef: 'inOffice', header: 'Staff Response (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.inOffice ? element.inOffice : ''}` },
            { columnDef: 'Temperature', header: 'Temperature (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.inOffTemperature ? element.inOffTemperature + '\xB0' + element.unitInOffice : ''}` },
            { columnDef: 'Spo', header: 'SpO2 (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.spo ? element.spo : ''}` },
           // { columnDef: 'Signature', header: 'Signature', cell: (element: CovidStaffDto, index: number) => `${element.preStaffSign ? element.preStaffSign : element.staffSign}` },
            { columnDef: 'SignatureSc', header: 'Staff Screener', cell: (element: CovidStaffDto, index: number) => `${element.staffSign ? element.staffSign : element.staffSign}` },
            { columnDef: 'In Office', header: 'In Office', cell: (element: CovidStaffDto, index: number) => this.enableInOffice(element.docId, element.inOffice) },
        ];
    }


    getColumn(): any[] {


        return [
            {
                columnDef: 'Sr',
                header: 'Sr.',
                cell: (element, index: number) => `${this.page * 10 + index + 1}`,
            },

            { columnDef: 'Name', header: 'Name', cell: (element: CovidStaffDto, index: number) => `${element.name ? element.name : ''}` },
            { columnDef: 'DateTime', header: 'DateTime', cell: (element: CovidStaffDto, index: number) => `${element.inOffTime ? this.apiService.getDateStringMomentByTz(Number(element.inOffTime)) : this.apiService.getDateStringMomentByTz(element.addedOn)}` },
            // {columnDef: 'Time', header: 'Time', cell: (element: CovidStaffDto, index: number) => this.getTime(Math.abs(element.addedOn))},
            { columnDef: 'home', header: 'Staff Response (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preOffice ? element.preOffice : ''}` },
            { columnDef: 'Temperaturehome', header: 'Temperature (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preOffTemperature ? element.preOffTemperature + '\xB0' + element.preOffTemperatureUnit : ''}` },
            { columnDef: 'Spohome', header: 'SpO2 (Home)', cell: (element: CovidStaffDto, index: number) => `${element.preSpo ? element.preSpo : ''}` },
            { columnDef: 'inOffice', header: 'Staff Response (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.inOffice ? element.inOffice : ''}` },
            { columnDef: 'Temperature', header: 'Temperature (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.inOffTemperature ? element.inOffTemperature + '\xB0' + element.unitInOffice : ''}` },
            { columnDef: 'Spo', header: 'SpO2 (InOffice)', cell: (element: CovidStaffDto, index: number) => `${element.spo ? element.spo : ''}` },
            { columnDef: 'Signature', header: 'Signature', cell: (element: CovidStaffDto, index: number) => `${element.preStaffSign ? element.preStaffSign : element.staffSign}` },
            { columnDef: 'SignatureSc', header: 'Staff Screener', cell: (element: CovidStaffDto, index: number) => `${element.staffSign ? element.staffSign : element.staffSign}` },
            { columnDef: 'In Office', header: 'In Office', cell: (element: CovidStaffDto, index: number) => this.enableInOffice(element.docId, element.inOffice) },
        ]; 


}
    // returnViewIcon(docId,inOffice){
    //
    //     if(inOffice){
    //         return docId;
    //     }
    // }

    enableInOfficeSingle(docId, login, inOffice) {

        if (login === undefined) {
            switch (inOffice) {
                case true:
                    return 1;
                    break;
                case false:
                    return 2;
                    break;
                default:
                    return 3;
                    break;
            }
        }

    }




    isPreOfficeDone(docId, preOfficeTime, login) {

        if (preOfficeTime === undefined && login === undefined) {
            return 'Pending';
        }
        return 'Done';
    }

    isInOfficeDone(docId, inOffice) {
        if (inOffice === true) {
            return 'Done';
        } else {
            return 'Pending';
        }

    }


    enableInOffice(docId, inOffice) {
        let combo = '';
        if (inOffice === undefined) {
            return combo = docId;
        }

        return combo;
    }

    getOrderByColumn(): string {
        return 'addedOn';
    }

    getTableName(): string {
        return 'staffResult';
    }

    updateRow(row): any {
        // setDeriveFields(row);
    }

    getTitleMatIcon(): string {
        return 'touch_app';
    }

    getTitle(): string {
        return 'Staff Member Screening';
    }

    getSearchText(): string {
        return 'Search Patient via Id/Name';
    }

    extraButtonClick(): void {
        const retValue = this.apiService.startAssessment({ name: 'helloOne' });
        // const email = 'shubham@xccelerata.com';
        // const urlCovidStaff = 'key#add##email#'+email+'##labName#'+this.urlLabName;
        // this.router.navigate(['/staffassessment'],{queryParams:{token:this.global.encodeToa(urlCovidStaff)}}) ;
    }



    editButtonClick(comboId): void {
        this.staffName = this.staffForm.value.staff;
        // localStorage.setItem('staffName', this.staffName);
        this.userSevice1.setCookie("staffName", this.staffName);
        const urlStrGuest = 'key#edit##' + comboId + '##labName#' + this.urlLabName;
        // this.router.navigate(['/covid-assessment'], { queryParams: { key:'edit', patient: this.global.encodeToa(comboId) , labName: this.global.encodeToa(this.urlLabName) }});
        this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStrGuest) } });

    }


    editSinglePage(comboId): void {
        this.staffName = this.staffForm.value.staff;
        console.log(this.staffName);
        this.userSevice1.setCookie("staffName", this.staffName);
        const urlStrGuest = 'key#edit##' + comboId + '##labName#' + this.urlLabName;
        console.log(urlStrGuest);
        // this.router.navigate(['/covid-assessment'], { queryParams: { key:'edit', patient: this.global.encodeToa(comboId) , labName: this.global.encodeToa(this.urlLabName) }});
        this.router.navigate(['/staffassessment'], { queryParams: { token: this.global.encodeToa(urlStrGuest) } });

    }

    viewResult(comboId) {

        const tokenStr = 'patient#' + comboId + '##labName#' + this.urlLabName;

        this.router.navigate(['/covidresultdetail'], { queryParams: { token: this.global.encodeToa(tokenStr) } });
    }

    startAssessment() {
        const lab = this.userSevice1.getCookie('lab');
        const urlStr = 'key#start##labName#' + this.urlLabName + '##type#direct';
        this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStr) } });
        // this.router.navigate(['/covid-assessment-start'], { queryParams: { key: 'start', labName: this.global.encodeToa(lab), type:'direct'}});


    }

    openDialogWithRef(ref: TemplateRef<any>, comboId) {
        console.log(comboId);
        this.dialog.open(ref, {
            disableClose: true, height: '250px', width: '350px',

        });
    }

    showImage(data, comboId, email, pad) {
        if (pad.signaturePad.isEmpty() || this.staffForm.invalid) {

            if (this.staffForm.invalid && pad.signaturePad.isEmpty()) {
                this.staffForm.markAllAsTouched();
                return;
            }
            else if (this.staffForm.invalid && !pad.signaturePad.isEmpty()) {
                this.staffForm.markAllAsTouched();
                return;
            }
            else if (!this.staffForm.invalid && pad.signaturePad.isEmpty()) {
                this.alertSign = 'Signature required';
                return;
            }
        } else {
            this.dialog.closeAll();
            this.alertSign = '';
            this.staffName = this.staffForm.value.staff;
            this.userSevice1.setCookie("staffName", this.staffName);
            this.signatureImage = data;

            localStorage.setItem('signatureImage', this.signatureImage);
            // this.startAssessment(comboId, email);
            this.editButtonClick(comboId);

        }
    }


    openSnackBar(message: string, action: string): void {
        const currentTime = (new Date()).getTime();
        if (currentTime - this.lastSnackTime < 6000) {
            return;
        }
        this.lastSnackTime = currentTime;
        this.snackBar.open(message, action, {
            duration: 6000,
        });
    }

    downloadFile(url) {

        // const checkOncall = firebase.functions().httpsCallable('checkOncall');
        // checkOncall().then(result => {
        //
        // })
        window.open(url, '_blank');
    }


    shareCovid(comboId, patient): void {
        const fname = comboId.split('##')[1].split('#')[1];
        const lName = comboId.split('##')[2].split('#')[1];
        const fullName = fname + ' ' + lName;

        const dialogRef = this.dialog.open(ShareAssessmentDialogComponent, {
            width: '350px',
            data: { comboId: comboId, firstName: fname, lastName: lName, emailId: patient.email, patientId: patient.id, mobileNo: patient.mobileNo }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result !== undefined) {
                const data = {
                    email: result,
                    comboId: comboId,
                    fullName: fullName,
                    patient: patient.patient,
                    patientId: patient.patientId,
                    labId: this.userSevice1.getCookie("lab"),
                    firstName: fname,
                    lastName: lName,
                    docId: patient.docId,
                };
                LogUtil.log('patientData', data);
                // being called from covid service
                this.paginationService.shareLinkUrl(data).subscribe(dataResult => {
                    this.openSuccessfulDialog();

                });

            }
        });
    }

    openSuccessfulDialog() {
        const dialogRef = this.dialog.open(SuccessfulComponent,
            {
                disableClose: true,
                width: '350px',
                height: '250px',
                // data: {firstName: this.firstName, lastName: this.lastName, pid: this.urlPatientId}
            });
    }


    prepareComboId(patient) {

        let combo = '';
        if (patient.mobile) {
            combo = 'patient#' + patient.patientId+ '##firstName#' + patient.firstName + '##lastName#' + patient.lastName + '##mobile#' + patient.mobile;
        } else {
            combo = 'patient#' + patient.patientId+ '##firstName#' + patient.firstName + '##lastName#' + patient.lastName;
        }

        return this.shareCovid(combo, patient)


    }
}
