import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Cell } from './cell';
import { Asset } from '../asset/asset';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable()
export class CellService {
    private itemsSubject: BehaviorSubject<Cell[]>;
    public itemsObservable: Observable<Cell[]>;

    private searchSubject: BehaviorSubject<Cell[]>;
    public searchObservable: Observable<Cell[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    table = 'cell';
    currentPage = 0;


    constructor(private afs: AngularFirestore, private userService: StorageService) {
    }

    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const docId = a.payload.doc.id;
                const doc = a.payload.doc;
                const id = a.payload.doc.id;
                return { id, ...data as {}, doc };
            });
        }));
    }

    getSearchCollection(patient): Observable<any[]> {
        return this.getCollection(this.getCollectionPath(), ref => ref);


    }

    getCell(patient): Observable<Cell[]> {
        this.searchSubject = new BehaviorSubject([]);
        this.searchObservable = this.searchSubject.asObservable();
        const ref = this.getSearchCollection(patient)
            .subscribe(data => {
                if (data) {
                    this.searchSubject.next(data);
                } else {
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }

    first(): void {
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();
        const ref = this.getCollection(this.getCollectionPath(), ref => ref)
            .subscribe(data => {
                this.pageSubject.next(0);
                if (data) {
                    this.latestEntry = data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.itemsSubject.next(data);
                } else { //dp something
                }
                // scoresRef.unsubscribe();
            });
    }

    next(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref)
            .subscribe(data => {
                if (data.length) {
                    this.currentPage++;
                    this.pageSubject.next(this.currentPage);
                    // And save it again for more queries
                    this.latestEntry = data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.itemsSubject.next(data);
                }
                // scoresRef.unsubscribe();
            });
    }


    prev(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref)
            .subscribe(data => {
                if (data.length) {
                    data.reverse();
                    // And save it again for more queries
                    this.latestEntry = data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.currentPage--;
                    this.pageSubject.next(this.currentPage);
                    this.itemsSubject.next(data);
                }
                // scoresRef.unsubscribe();
            });
    }


    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/' + this.table;
    }

    getRef(path: string) {
        return this.afs.doc<any>(path);
    }

    getCellType(type: string): ECellType {
        switch (type) {
            case 'dashImage':
                return ECellType.DASH_IMAGE;
            case 'genericImage':
                return ECellType.GENERIC_IMAGE;
            case 'machine':
                return ECellType.MACHINE;
            case 'washer':
                return ECellType.WASHER;
            case 'dashButtons':
                return ECellType.TUTORIALS;
            case 'incubator':
                return ECellType.THREE_M;
        }
    }

    getAssetPath(cellType: ECellType, id: string): string {
        let pathPrefix = '';
        const common = 'common';
        switch (cellType) {
            case ECellType.DASH_IMAGE:
                pathPrefix = common + '/asset/generic';
                break;
            case ECellType.GENERIC_IMAGE:
                pathPrefix = common + '/asset/generic';
                break;
            case ECellType.MACHINE:
                pathPrefix = common + '/asset/sterilizer';
                break;
            case ECellType.WASHER:
                pathPrefix = common + '/asset/washer';
                break;
            case ECellType.TUTORIALS:
                pathPrefix = 'lab/' + this.userService.getCookie("lab") + '/tutorial';
                break;
            case ECellType.THREE_M:
                pathPrefix = common + '/asset/incubator';
                break;
        }
        return pathPrefix + '/' + id;
    }

    getBackgroundImage(type: string, id: string): Observable<Asset> {
        const cellType: ECellType = this.getCellType(type);
        const path = this.getAssetPath(cellType, id);
        return this.getRef(path).valueChanges();
    }
    // /common/asset/generic/blueZone
    // path common/asset/generic/blueZone

    sodDetails(newdate) {
        return this.afs.collection('/lab/developerLab/dailyTasks/').doc(newdate).get();
    }

}
