export class CovidSetting {
    staffAtHomeRiskResolutionNo: string;
    staffAtHomeRiskResolutionYes: string;
    staffInOfficeRiskResolutionNo: string;
    staffInOfficeRiskResolutionYes: string;


    /**
     * Constructor
     *
     * @param product
     */
    constructor(product?) {
        product = product || {};
        this.staffAtHomeRiskResolutionNo = product.staffAtHomeRiskResolutionNo || '';
        this.staffAtHomeRiskResolutionYes = product.staffAtHomeRiskResolutionYes || '';
        this.staffInOfficeRiskResolutionNo = product.staffInOfficeRiskResolutionNo || '';
        this.staffInOfficeRiskResolutionYes = product.staffInOfficeRiskResolutionYes || '';



    }

    toString(): string {
        return
        this.staffAtHomeRiskResolutionNo +
        this.staffAtHomeRiskResolutionYes +
        this.staffInOfficeRiskResolutionNo +
        this.staffInOfficeRiskResolutionYes




    }
}
