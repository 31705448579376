import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';
import {RcenterComponent} from "./list/rcenter.component";
import {RcenterService} from "./list/rcenter.service";
import {RcenterDetailComponent} from "./detail/rcenterDetail.component";
import {RcenterDetailService} from "./detail/rcenterDetail.service";
import { UploadComponent } from './upload/upload.component';


const routes = [
    {
        path     : 'rcenter',
        component: RcenterComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'rcenterDetail',
        component: RcenterDetailComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'upload',
        component: UploadComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        RcenterComponent,
        RcenterDetailComponent,
        UploadComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        SharedModule,
        FuseSharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
       // NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule

    ],
    exports     : [
        RcenterComponent,
        RcenterDetailComponent
    ],
    providers   : [
        RcenterService,
        RcenterDetailService
]
})

export class RcenterModule
{
}
