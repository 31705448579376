import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { StorageService } from 'app/main/module/common/service/storage.service';
@Injectable({ providedIn: 'root' })
export abstract class BaseListService {
    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;

    public notFoundObservable: Observable<boolean>;
    public notFoundSubject: BehaviorSubject<boolean>;

    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    currentPage = 0;
    protected table = '';
    protected orderBy = '';
    protected isOrderByNegative = true;
    public searchedText: string = null;
    constructor(private afs: AngularFirestore, private userService: StorageService) {
    }
    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }
    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                const doc = a.payload.doc;
                return { id, ...data as {}, doc };
            });
        }));
    }
    public first(): any {
        this.currentPage = 0;
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();

        this.notFoundSubject = new BehaviorSubject<boolean>(false);
        this.notFoundObservable = this.notFoundSubject.asObservable();

        this.getFirstQuery().subscribe(data => {
            this.pageSubject.next(0);
            this.latestEntry = data[data.length - 1].doc;
            this.startingEntry = data[0].doc;
            this.itemsSubject.next(data);
            // scoresRef.unsubscribe();
        });
    }
    public next(): void {
        this.getNextQuery().subscribe(data => {
            if (data && data.length > 0) {
                this.currentPage++;
                this.pageSubject.next(this.currentPage);
                // And save it again for more queries
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.itemsSubject.next(data);
            } else {
                if (this.notFoundSubject) {
                    this.notFoundSubject.next(true);
                    this.notFoundSubject.next(false);
                }
            }
            // scoresRef.unsubscribe();
        });
    }

    public prev(): void {
        this.getPrevQuery().subscribe(data => {
            if (data.length) {
                data.reverse();
                // And save it again for more queries
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.currentPage--;
                this.pageSubject.next(this.currentPage);
                this.itemsSubject.next(data);
            }
            // scoresRef.unsubscribe();
        });
    }

    public search(): any {
        let query = null;
        if (this.searchedText) {
            query = this.getSearchQueryWithText();
        } else {
            query = this.getSearchQueryWithOutText();
        }
        query.subscribe(data => {
            if ((this.searchedText)) {

                if (data && data.length > 0) {
                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.itemsSubject.next(data);
                } else {
                    if (this.notFoundSubject) {
                        this.notFoundSubject.next(true);
                        this.notFoundSubject.next(false);
                    }
                }
            } else {
                this.pageSubject.next(0);
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.itemsSubject.next(data);
            }
            // query.unsubscribe();
        });
    }

    public abstract getFirstQuery(): any;
    public abstract getNextQuery(): any;
    public abstract getPrevQuery(): any;
    public abstract getSearchQueryWithText(): any;
    public abstract getSearchQueryWithOutText(): any;

    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/' + this.table;
    }
}
