import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabsettingComponent } from './labsetting/labsetting.component';
import {EmailListComponent} from '../email/list/EmailList.component';
import {AuthGuard} from '../../auth/auth.guard';
import {EmaildDetailComponent} from '../email/viewemaildetails/vieweEmailDetails.component';
import {PiComponent} from '../pi/detail/pi.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../common/shared.module';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {AsapMaterialModule} from '../../../../material-module';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {AgmCoreModule} from '@agm/core';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {LabService} from './lab.service';


const routes = [
    {
        path: 'lab',
        component: LabsettingComponent,
        canActivate: [AuthGuard],
    },

];

@NgModule({
    declarations: [
        LabsettingComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        SharedModule,
        FuseSharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        // NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,
        CommonModule

    ],
    exports     : [
        LabsettingComponent
    ],
    providers   : [
        LabService
    ]
})



export class LabModule { }
