import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../../../common/service/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { CovidDto } from '../../../CovidDto.modal';
import { MatDialog } from '@angular/material/dialog';
import { CovidService } from '../../../covid.service';
import { Globals } from '../../../../common/global';
const timeZone = require("moment-timezone");
import { TeamService } from '../../../../sterilwize/team/team.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShareAssessmentDialogComponent } from '../../../../sterilwize/patient/shareAssessmentLink/share-assessment-dialog.component';
import { SuccessfulComponent } from '../../../../sterilwize/patient/successful/successful.component';
import { ApiService } from '../../../../common/service/api.service';
import { PatientScreeningInfiniteListService } from './screening-patient-infinite-scrol.service';
import * as firebase from 'firebase';
import { Subscription } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/main/module/common/config/config.service';
import { F } from '@angular/cdk/keycodes';



@Component({
    selector: 'covid-result-table',
    templateUrl: 'screening-patient-result-list.component.html',
    styleUrls: ['screening-patient-result-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ScreeningPatientResultListComponent implements OnInit, OnDestroy, AfterViewInit {


    // dataSource = new MatTableDataSource<any>();
    // displayedColumns: string[] = ['Sr', 'ID', 'First Name', 'Last Name'];
    staffForm: FormGroup;
    staffFormEdit: FormGroup;
    filterForm: FormGroup;
    patient: string;
    page = 0;
    MAX_ROW = 10;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    searchText: string = '';
    public dataCount:number;
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    urlPatientId: any;
    urlLabName = this.userSevice1.getCookie("lab")
    perPageData = this.MAX_ROW;
    staffName = '';
    alertSign = '';
    signatureImage: any;
    staffDropDown: any;
    isEnterPressed = false;
    isAssignedPressed = false;
    hasSearchedText = false;
    hasFirstDataShown = false;
    enableInfoMsg = false;

    searchFrom: number;
    searchTo: number;

    lastSnackTime = 0;
    @ViewChild('filter', { static: true })
    filter: ElementRef;
    length: any;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    filterOption = ['all', 'pending', 'done'];
     @Input()
    max: 'D' | null;
    today = new Date();

    startDate = moment().startOf('day');
    dayAfTomorrow = moment().add(2, 'days').startOf('day');


    @Input()
    min: 'D' | null;
    startAt: any;
    // Private
    token: number;
    dateIso: any;
    editedDocId: string;

    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;

    protected extraCta = {
        visibility: false,
        text: 'Consent',
    };
    protected extraCta1 = {
        visibility: false,
        text: 'Question',
    };
    disabled = false;
    showSpinners = true;
    showSeconds = false;
    touchUi = false;
    enableMeridian = true;
    minDate = new Date();
    maxDate: any
    stepHour = 1;
    stepMinute = 5;
    countryCode: any;
    paginationSpinner: boolean;
    disableClearRange: boolean = false;
    start: number;
    end: number;
    condition: { from: number; to: number; };
    hasDateRange: boolean;
    getCountrycode: any = "+1";

    isInitComplete = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any;
    isMoreDataAvailable = true;
    pmsIntegration: boolean;
    loadingSpinner: boolean=true;
    isLoading = false;
    labConfig: any;
    dateTime: Date;

    constructor(
        private userSevice1: StorageService, private fb1: FormBuilder,
        private route1: ActivatedRoute, private router1: Router,
        private route: ActivatedRoute, private router: Router,
        private changeDetectorRefs1: ChangeDetectorRef,
        public dialog: MatDialog,
        private paginationService: CovidService,
        private changeDetectorRefs: ChangeDetectorRef,
        public global: Globals,
        public team: TeamService,
        public snackBar: MatSnackBar,
        private httpClient: HttpClient,
        public configService: ConfigService,
        public apiService: ApiService,
        private infiniteScroll: PatientScreeningInfiniteListService,
    ) {
        // super(userSevice1, fb1, route1, router1,  authService1, paginationService1, changeDetectorRefs1);

        this._unsubscribeAll = new Subject();
        this.configService
        .getLabConfig("lab")
        .valueChanges()
        .subscribe((labConfig: any) => {
            this.labConfig = labConfig;
            console.log(this.labConfig.sendSMSFromNumber);
        });
        // if(localStorage.getItem('staticData') === 'Y' ){
        //     this.extraCta.visibility = true;
        //     this.extraCta.text = 'Start';
        // }

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key.includes('Enter')
            && event.code.includes('Enter')
            && document.activeElement.id.includes('patient')) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch();
            }
        } else {
            this.isEnterPressed = false;


        }
    }


    /**
     * On init
     */

    ngOnInit(): void {
        this.disableClearRange=false;
        this.isLoading = false;
        console.log(this.disableClearRange);
        this.perPageData = 10;// sets page to 1
        //ScreeningPatientResultListComponent.oldData = [];
        this.paginationService.searchedFrom = parseInt(this.startDate.format('x'), 10);
        this.paginationService.searchedTo = parseInt(this.dayAfTomorrow.format('x'), 10) + 86400000;
       

        this.page = 0;
        this.initData();

        this.dateIso = new Date();

        this.team.allTeamData().then(data => {
            this.staffDropDown = data;
        });

        this.staffForm = this.fb1.group({

            staff: ['', [Validators.required], ''],

        });

        this.staffFormEdit = this.fb1.group({
            dateTime: ['', [Validators.required], ''],

        });
    
        this.filterForm = this.fb1.group({
            from: [this.startDate, [Validators.required], ''],
            to: [this.dayAfTomorrow, [Validators.required], ''],
            // preOffice:['', [],''],
            // inOffice:['', [],''],
        });
    }


    initData() {
        if (ScreeningPatientResultListComponent.oldData && ScreeningPatientResultListComponent.oldData.length > 0) {
            console.log("neha1")
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck();
            this.changeDetectorRefs.detectChanges();
            this.dataN = ScreeningPatientResultListComponent.oldData;
            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            this.isInitComplete = true;
           // this.isLoading = false;
            this.initWithOldData();
            return;
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.infiniteScroll.init("patient", 10, "firstName", {
            reverse: false,
            prepend: false,
        });

        this.subscription = this.infiniteScroll.data.subscribe((data) => {
            if (data) {
            } else return;
            this.dataN = data;
            if (
                this.dataSource &&
                this.dataN &&
                this.dataSource.data.length == this.dataN.length
            ) {
                this.isMoreDataAvailable = false;
            }
            try {
                data.forEach((value) => this.updateRow(value));
            } catch (e) {}

            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if (this.dataN && this.dataN.length > 0) {
                console.log(this.dataN)
                this.isInitComplete = true;
                console.log("neha2")
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000);
            } else {
            }
            setTimeout(() => {
                this.scrollToBottom();
                console.log("setTimeout3")
                this.isLoading = false;
            }, 200);
        });
    }

    initWithOldData() {
        this.infiniteScroll.initWithPrev(
            ScreeningPatientResultListComponent.cursor,
            ScreeningPatientResultListComponent.oldData,
            "patientResult",
            10,
            "doa",
            { reverse: false, prepend: false }
        );

        this.subscription = this.infiniteScroll.data.subscribe((data) => {
            if (data) {
            } 
            else return;
            this.dataN = data;
            if (
                this.dataSource &&
                this.dataN &&
                this.dataSource.data.length - ScreeningPatientResultListComponent.oldData.length ==
                    this.dataN.length
            ) {
                console.log(
                    "isMoreDataAvailable" +
                        "this.dataSource.data.length" +
                        this.dataSource.data.length +
                        "this.dataN.length" +
                        this.dataN.length
                );
                // this.isMoreDataAvailable = false;
            }
            data.forEach((value) => this.updateRow(value));
            this.dataSource = new MatTableDataSource(this.dataN);

            this.dataSource.data = ScreeningPatientResultListComponent.oldData.concat(this.dataN);
            if (this.dataSource.data && this.dataSource.data.length > 0) {
                this.isInitComplete = true;
                console.log("neha4")
                this.isLoading = false;
                console.log("dataSource.length");
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000);
            }
            setTimeout(() => {
                this.scrollToBottom();
                console.log("setTimeOut1");
                this.isLoading = false;
            }, 1000);
        });
        setTimeout(() => {
            this.scrollToBottom();
            console.log("setTimeOut2");
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck();
            this.changeDetectorRefs.detectChanges();
        }, 1000);
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector("mat-table");
            table.scrollBy({
                behavior: "smooth",
                left: 0,
                top: table.scrollHeight,
            });
        }
    }


    nextPage2(): void {
          console.log("more")
        this.isLoading = true;
        this.isNextPressed = true;
        this.infiniteScroll.more();
    }

    dateRangeChange(from, to) {
       
        this.start = new Date(from.value).getTime();
        this.end = (new Date(to.value).getTime()) + 86400000;
        this.condition = { from: this.start, to: this.end };
        console.log(this.condition)
        this.disableClearRange=true;
        console.log(this.disableClearRange);
        console.log(this.paginationService.searchedText);
        if (this.paginationService.searchedText){
                console.log('if');
        this.paginationService.searchRangeAfterSearchPatient(this.condition,this.paginationService.searchedText);
        }
        else{
            console.log('else');
        this.paginationService.searchRange(this.condition);}

        // if(this.paginationService.searchedText == '')
        this.hasFirstDataShown = true;
      }


    onClickSearch() {
        
        this.paginationService.searchedText = this.searchText.replace(/[^a-zA-Z0-9]/g, '');
        // console.log(this.paginationService.searchedText);
        this.paginationService.search();
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.searchText = '';
        this.hasSearchedText = false;
        this.isLoading = false;
        if(this.condition){      
            this.paginationService.searchRange(this.condition);
        }
        else{
            this.onClickSearch();
        }
        this.ngOnInit();
      
    }

    refreshSearch(event) {
         console.log(event.code+'='+this.searchText.length);
        if (event.code === 'Backspace' && this.searchText.length <= 1) {
            this.searchText = '';
            this.onClickSearch();
        }
    }



    clearDateRangePicker() {
        // this.searchText = '';
        // this.onClickSearch();
        // this.pickerDR.select(undefined);
        // this.disableClearRange=false;
        window.location.reload();
    }

    onClickSearchByDateRange(dateRangeStart,dateRangeEnd) {
        // alert('Search Text' + this.searchText.replace(/[^a-zA-Z0-9]/g, ''));
         this.paginationService.searchedFrom = dateRangeStart;
         this.paginationService.searchedTo = dateRangeEnd;
        console.log(this.paginationService.searchedFrom,this.paginationService.searchedTo);
        this.paginationService.search();
    }
    
    
    ngAfterViewInit(): void {
        
        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            console.log('order',this.isOrderByNegative())
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {

            if (data === null) {
                this.openSnackBar('No data found', 'OK');
            }

            if (data && data.length > 0) {
                this.hasFirstDataShown = true;
                this.length = data.length;
                this.dataSource.data = data;

                if (this.searchText && this.searchText.length > 0) {
                    this.hasSearchedText = true;
                    const matTable = document.getElementById('matTable');
                    matTable.scrollTop = 0;
                } else {
                    this.hasSearchedText = false;
                }
                if(this.condition){
                    this.hasDateRange =true;
                }
                else{
                    this.hasDateRange =false; 
                }
            }else {
                this.hasSearchedText = true;
                this.hasFirstDataShown = false;
                this.enableInfoMsg = false;
                setTimeout(()=>{
                    this.hasFirstDataShown = true;
                    this.enableInfoMsg = true;
                },6000)
            }

        });

        this.paginationService.notFoundObservable.subscribe(data => {
            if (data && this.searchText && this.searchText.length > 0) {
                this.hasFirstDataShown = true;
                this.openSnackBar('No patient found', 'OK');
            }

        });

    }

    nextPage(): void {

        this.paginationService.next();
    }


    prevPage(): void {
        this.hasSearchedText = true;
    //    this.paginationSpinner=false;
        this.paginationService.prev();
        // this.paginationSpinner=true;
    }
    resetStafformEdit() {
        this.staffFormEdit.value.dateTime = '';
        this.staffFormEdit = this.fb1.group({
            dateTime: ['', [Validators.required], ''],

        });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
       // this.page = 0;
    
        this.subscription.unsubscribe();
        ScreeningPatientResultListComponent.oldData = this.dataSource.data;
        ScreeningPatientResultListComponent.cursor = this.infiniteScroll.getCursor();

        // Unsubscribe from all subscriptions
        // this._unsubscribeAll.next();
        // this._unsubscribeAll.complete();

    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }



    getColumn(): any[] {
        return [
            {
                columnDef: 'Sr',
                header: 'Sr.',
                cell: (element, index: number) => `${this.page * this.MAX_ROW + index + 1}`,

            },
            // {columnDef: 'Time', header: 'Time', cell: (element: CovidDto, index: number) => this.getTime(Math.abs(element.addedOn))},
            { columnDef: 'DOA', header: 'DateTime Of Appointment', cell: (element: CovidDto, index: number) => `${element.doa ? this.apiService.getDateStringMomentByTz(Number(element.doa)) : ''}` },
            { columnDef: 'MSD', header: 'DateTime of email sent', cell: (element: CovidDto, index: number) => `${element.addedOn ? this.apiService.getDateStringMomentByTz(element.addedOn) : ''}` },
            { columnDef: 'ID', header: 'PatientID', cell: (element: CovidDto, index: number) => `${element.patientId ? element.patientId : ''}` },
            { columnDef: 'ScreeningID', header: 'ScreeningID', cell: (element: CovidDto, index: number) => `${element.tokenId ? element.tokenId : ''}` },
            { columnDef: 'First Name', header: 'First Name', cell: (element: CovidDto, index: number) => `${element.firstName ? element.firstName : ''}` },
            { columnDef: 'Last Name', header: 'Last Name', cell: (element: CovidDto, index: number) => `${element.lastName ? element.lastName : ''}` },
            // {columnDef: 'Email', header: 'Email', cell: (element: CovidDto, index: number) => `${element.email}`},
            { columnDef: 'Phone', header: 'Phone', cell: (element: CovidDto, index: number) => `${element.phone ? element.phone : ''}` },
            // {columnDef: 'Result', header: 'Result', cell: (element: CovidDto, index: number) => `Negative`},
            // {columnDef: 'Modify', header: 'InOffice', cell: (element: CovidDto, index: number) => this.enableInOffice(element.docId, element.login,element.firstName,element.lastName,element.email,element.phone)},
            { columnDef: 'PreScreening', header: 'PreScreening', cell: (element: CovidDto, index: number) => this.isPreOfficeDone(element.docId, element.preOfficeTime, element.login, element.addedType) },
            { columnDef: 'In Office', header: 'In Office', cell: (element: CovidDto, index: number) => this.isInOfficeDone(element.docId, element.inOffice) },
            { columnDef: 'Details', header: 'Details', cell: (element: CovidDto, index: number) => `${element.docId}` },
            { columnDef: 'Modify', header: 'Action', cell: (element: CovidDto, index: number) => this.enableInOfficeSingle(element.docId, element.login, element.inOffice, element.addedType) },
            { columnDef: 'Edit', header: 'Edit', cell: (element: CovidDto, index: number) => this.reschedulePatient(element.docId, element.inOffice, element.preOffice, element.anyScreening) },
        ];
    }



    reschedulePatient(docId, inOffice, preOffice, anyScreening) {
        if ((inOffice === false && preOffice === true) || anyScreening === false) {
            return docId;
        }
    }


    // getAppointDateTime(appointDate, appointTime){
    //     let dateAndTime = '';
    //     let doa ='';
    //
    //
    //
    //     if(appointDate ) {
    //          doa = moment(parseInt(appointDate, 10)).format('DD-MM-YYYY');
    //         }
    //         if(appointTime){
    //              dateAndTime = doa +'|'+appointTime;
    //         }
    //         return dateAndTime
    //     }

    // enableInOffice(docId, login){
    //     if(login === undefined){
    //         return docId;
    //     }
    // }

    // returnViewIcon(docId,inOffice){
    //
    //     if(inOffice){
    //         return docId;
    //     }
    // }

    enableInOfficeSingle(docId, login, inOffice, addedType) {

        if(addedType !== undefined){
            return 4;
        }

        if (login === undefined) {
            switch (inOffice) {
                case true:
                    return 1;
                    break;
                case false:
                    return 2;
                    break;
                default:
                    return 3;
                    break;
            }
        }

    }


    // enableInOfficeSingle(docId, login, inOffice) {
    //     let combo = '';
    //     if(login === undefined ){
    //
    //
    //         return combo = 'patient#' + docId;
    //     }
    //     return combo;
    // }

    isPreOfficeDone(docId, preOfficeTime, login, addedType) {

        if(addedType !== undefined){
            return 'other';
        }

        if (preOfficeTime === undefined && login === undefined) {
            return 'Pending';
        }
        return 'Done';
    }

    isInOfficeDone(docId, inOffice) {
        if (inOffice === true) {
            return 'Done';
        } else {
            return 'Pending';
        }

    }


    enableInOffice(docId, login, fname, lname, email, mobile) {
        let combo = '';
        if (login === undefined) {
            // return docId;
            return combo = 'patient#' + docId + '##firstName#' + fname + '##lastName#' + lname + '##mobile#' + mobile + '##email#' + email;

        }

        return combo;
    }

    getOrderByColumn(): string {
        return 'doaTimestamp';
    }

    getTableName(): string {
        return 'patientResult';
    }

    updateRow(row): any {
        // setDeriveFields(row);
    }

    getTitleMatIcon(): string {
        return 'touch_app';
    }

    getTitle(): string {
        return 'Patient Screening';
    }

    getSearchText(): string {
        return 'Search Patient via Id/Name';
    }

    extraButtonClick(): void {
    }

    editButtonClick(comboId): void {
        this.staffName = this.staffForm.value.staff;
        // localStorage.setItem('staffName', this.staffName);
        this.userSevice1.setCookie("staffName", this.staffName);
        const urlStrGuest = 'key#edit##' + comboId + '##labName#' + this.urlLabName;
        this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStrGuest) } });

    }

    editSinglePage(comboId): void {
        console.log(comboId,this.urlLabName);
        this.staffName = this.staffForm.value.staff;
        this.userSevice1.setCookie("staffName", this.staffName);
        const urlStrGuest = 'key#edit##' + comboId + '##labName#' + this.urlLabName;
       
        this.router.navigate(['/inoffice'], { queryParams: { token: this.global.encodeToa(urlStrGuest) } });

    }

    startPreScreening(docId, email, data) {
        console.log(data,email);
        console.log(docId);
        const urlStr = 'key#add##patient#' + data.patientId + '##labName#' + this.userSevice1.getCookie("lab") + '##email#' + data.email + '##firstName#' + data.firstName + '##lastName#' + data.lastName + '##docId#' + docId ;
        this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStr) } });
    }
    viewResult(comboId) {

        const tokenStr = 'patient#' + comboId + '##labName#' + this.urlLabName;

        this.router.navigate(['/covidresultdetail'], { queryParams: { token: this.global.encodeToa(tokenStr) } });
    }

    startAssessment() {
        const lab = this.userSevice1.getCookie("lab")
        const urlStr = 'key#start##labName#' + this.urlLabName + '##type#direct';
        this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStr) } });
        // this.router.navigate(['/covid-assessment-start'], { queryParams: { key: 'start', labName: this.global.encodeToa(lab), type:'direct'}});


    }

    openDialogWithRef(ref: TemplateRef<any>, comboId, emailId) {
        this.dialog.open(ref, {
            disableClose: true, height: '250px', width: '350px',

        });
    }

    showImage(data, comboId, email, pad) {
        if (pad.signaturePad.isEmpty() || this.staffForm.invalid) {

            if (this.staffForm.invalid && pad.signaturePad.isEmpty()) {
                this.staffForm.markAllAsTouched();
                return;
            }
            else if (this.staffForm.invalid && !pad.signaturePad.isEmpty()) {
                this.staffForm.markAllAsTouched();
                return;
            }
            else if (!this.staffForm.invalid && pad.signaturePad.isEmpty()) {
                this.alertSign = 'Signature required';
                return;
            }
        } else {
            this.dialog.closeAll();
            this.alertSign = '';
            this.staffName = this.staffForm.value.staff;
            // localStorage.setItem('staffName', this.staffName);
            this.userSevice1.setCookie("staffName", this.staffName);
            this.signatureImage = data;

            localStorage.setItem('signatureImage', this.signatureImage);
            // this.startAssessment(comboId, email);
            this.editButtonClick(comboId);

        }
    }


    openSnackBar(message: string, action: string): void {
        const currentTime = (new Date()).getTime();
        if (currentTime - this.lastSnackTime < 6000) {
            return;
        }
        this.lastSnackTime = currentTime;
        this.snackBar.open(message, action, {
            duration: 6000,
        });
    }

    openSnackBarOne(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    downloadFile(url) {
        console.log(url);

        // const checkOncall = firebase.functions().httpsCallable('checkOncall');
        // checkOncall().then(result => {
        
        // })
        // if(url!=undefined){
        
        window.open(url, '_blank');
    }


    shareCovid(comboId, patient): void {
        if (patient.patientId) {
            this.paginationService.checkPatientExistOrNot(patient.patientId, this.urlLabName).then(async (checkExist: any) => {
                const checkPatientExist = await checkExist;
                if (checkPatientExist) {
                    const patientId = comboId.split('##')[0].split('#')[1];
                    const fname = comboId.split('##')[1].split('#')[1];
                    const lName = comboId.split('##')[2].split('#')[1];
                    const fullName = fname + ' ' + lName;
                    const dialogRef = this.dialog.open(ShareAssessmentDialogComponent, {
                        width: '350px',
                        data: { comboId: comboId, firstName: fname, lastName: lName, emailId: patient.email, patientId: patientId, doa: patient.doa, mobileNo: patient.phone}
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        console.log('RESult=',result);
                        const getate = new Date(result.dateTime); // isoString to date time
                        if(result.countryCode !== undefined){

                            this.getCountrycode = result.countryCode;
                            
                        }else {
                            console.log(patient.countryCode);
                        }

                        if (result && result !== undefined) {
                           console.log('anjali');
                            const data = {
                                email: result.email,
                                comboId: comboId,
                                fullName: fullName,
                                patient: patient.docId,
                                patientId: patientId,
                                labId: this.userSevice1.getCookie("lab"),
                                firstName: fname,
                                lastName: lName,

                            };

                            this.paginationService.getTokenInitValue().then(async token => {
                                this.token = await token.screeningId;
                                const covidData: any = {
                                    firstName: data.firstName,
                                    lastName: data.lastName,
                                    email: data.email,
                                    labName: data.labId,
                                    // pId: data.patientId,
                                    patient: data.patientId,
                                    patientId: data.patientId,
                                    addedOn: (-1) * new Date().getTime(),
                                    tokenId: this.token,
                                    anyScreening: false,
                                    doaTimestamp: (-1) * getate.getTime(),
                                    doa: getate.getTime(),
                                    countryCode:this.getCountrycode,
                                    appointTime: this.addZeroToHourAndMinutes(getate.getHours()) + ':' + this.addZeroToHourAndMinutes(getate.getMinutes()),
                                };

                                const splitMapFName = data.firstName.toUpperCase().split(' ');
                                let splitMapF = {}
                                for (let i of splitMapFName) {
                                    splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                                }
                                const splitMapLName = data.firstName.toUpperCase().split(' ');
                                let splitMapL = {}
                                for (let j of splitMapLName) {
                                    splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                                }


                                const firstNameMap = this.getMapValue(this.toUpperCaseString(data.firstName).replace(/\s/g, ''));
                                const lastNameMap = this.getMapValue(this.toUpperCaseString(data.lastName).replace(/\s/g, ''));
                                const fullNameMap = (data.firstName + data.lastName).replace(/\s/g, '').toUpperCase();
                                const idMap = this.getMapValue(this.toUpperCaseString(data.patientId));
                                const fullMapData = this.getMapValue(fullNameMap);
                                const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap, fullMapData, splitMapF, splitMapL);

                                // anyMap[fullNameMap] = true;
                                covidData.anyMap = anyMap;
                                const resultDataCovid = this.paginationService.addCovidResult(covidData);
                                resultDataCovid.then(async (returnData: any) => {
                                    if (await returnData) {
                                        // updating token config , using trigger inspite of below query
                                        // this.covidService.updateTokenConfig({tokenId:covidData.tokenId});
                                        data['docId'] = returnData.id;
                                        data['dateTime'] = getate.toDateString() + ' at ' + covidData['appointTime'];

                                        this.paginationService.shareLinkUrl(data).subscribe(dataResult => {

                                            this.openSuccessfulDialog();
                                        });
                                    }
                                })

                            })
                        }
                    });
                }
                else {
                    this.openSnackBarOne('No patient found to Re-share link', 'OK');
                }
            });
        }
    }

    reShareCovid(comboId, patient): void {
        if(patient.email == undefined){
            patient.email = "";
            }
            // console.log(patient.countryCode)
            // if(patient.countryCode == undefined){
            // patient.countryCode = '';
            // }
        if (patient.patientId) {
            console.log(patient)
            this.paginationService.checkPatientExistOrNot(patient.patientId, this.urlLabName).then(async (checkExist: any) => {
                const checkPatientExist = await checkExist;

                if (checkPatientExist) {
                    const patientId = comboId.split('##')[0].split('#')[1];
                    const fname = comboId.split('##')[1].split('#')[1];
                    const lName = comboId.split('##')[2].split('#')[1];
                    const fullName = fname + ' ' + lName;
                    const dialogRef = this.dialog.open(ShareAssessmentDialogComponent, {
                        width: '350px',
                        data: { comboId: comboId, firstName: fname, lastName: lName, emailId: patient.email, patientId: patientId, doa: patient.doa,mobileNo: patient.phone,countryCode:patient.countryCode}
                    })


                    dialogRef.afterClosed().subscribe(result => {
                         console.log('result=',result.countryCode);
                        const getate = new Date(result.dateTime); // isoString to date time
                        this.dateTime=getate;
                        if(result.countryCode !== undefined && result.countryCode !== "" ){

                            this.getCountrycode = result.countryCode;
                            
                            }else {
                                console.log(patient.countryCode)
                                this.getCountrycode = patient.countryCode;
                            }
                            if(result.email == undefined){
                              if(patient.email !== undefined && patient.email !== ""){
                                  result.email = patient.email;
                              }else{
                                 result.email = "";
                              }
                                
                             }

                        if (result && result !== undefined && getate !== undefined && result!== 'OTHER')  {
			this.paginationService.updateCovidResult2(patient.tokenId).get().then(dataCovid =>{
                const docId = dataCovid.docs[0].id;
                        console.log('anjali',patientId,patient.patientId);
                        if(result.mobileNo && result.mobileNo !== undefined){
                            console.log(result.mobileNo);
                            const data = {
                                mobileNo: result.mobileNo,
                                comboId: comboId,
                                fullName: fullName,
                                patient: docId,
                                patientId: patientId,
                                labId: this.userSevice1.getCookie("lab"),
                                firstName: fname,
                                lastName: lName,
                                countryCode: this.getCountrycode
                            };
                        
                            if(result.email == undefined){
                                if(patient.email !== undefined && patient.email !== ""){
                                    result.email = patient.email;
                                }else{
                                   result.email = "";
                                }
                                  
                               }
			        
                            const covidData: any = {
                                // mediumSelected:'SMS',
                                firstName: data.firstName,
                                lastName: data.lastName,
                                phone: data.mobileNo,
                                email: result.email,
                                labName: data.labId,
                                patient: docId,
                                patientId: data.patientId,
                                addedOn: -1 * new Date().getTime(),
                                // tokenId: this.token,
                                anyScreening: false,
                                doaTimestamp: -1 * getate.getTime(),
                                doa: getate.getTime(),
                                // appointTime: this.addZeroToHourAndMinutes(getate.getHours()) + ':' + this.addZeroToHourAndMinutes(getate.getMinutes()),
                                // appointTime: this.firebaseUtilService.getTimeWithAmPm(getate,true),
                                appointTime: timeZone(getate.getTime())
                                    .tz(this.userSevice1.getCookie("defaultTz"))
                                    .format("hh:mm A"),
                                countryCode: data.countryCode  
                            };
                
                                const splitMapFName = data.firstName.toUpperCase().split(" ");
                                let splitMapF = {};
                                for (let i of splitMapFName) {
                                    splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                                }
                                const splitMapLName = data.firstName.toUpperCase().split(" ");
                                let splitMapL = {};
                                for (let j of splitMapLName) {
                                    splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                                }
               
                                const firstNameMap = this.getMapValue(
                                    this.toUpperCaseString(data.firstName).replace(/\s/g, "")
                                );
                             
                                const lastNameMap = this.getMapValue(
                                    
                                    this.toUpperCaseString(data.lastName).replace(/\s/g, "")
                                );
                           
                                const fullNameMap = (data.firstName + data.lastName)
                                    .replace(/\s/g, "")
                                    .toUpperCase();
                                const idMap = this.getMapValue(
                                    this.toUpperCaseString(data.patientId)
                                );

                                const fullMapData = this.getMapValue(fullNameMap);
                                const anyMap = Object.assign(
                                    {},
                                    idMap,
                                    firstNameMap,
                                    lastNameMap,
                                    fullMapData,
                                    splitMapF,
                                    splitMapL
                                );

                
                                // anyMap[fullNameMap] = true;
                                covidData.anyMap = anyMap;
                                const resultDataCovid =  this.paginationService.updateCovidResult(covidData,docId)
                                resultDataCovid.then(async (returnData: any) => {
                                    
                                        const lab = this.userSevice1.getCookie("lab");
                                        let url = this.global.siteUrl + "covid-assessment-start?token=";
                                
                                        const urlStr = 'key#add##patient#' + data.patientId + '##labName#' + lab + 
                                         '##firstName#' + data.firstName + '##lastName#' + data.lastName + '##docId#' + docId;
                                        url = url + this.global.encodeToa(urlStr);
            
                
                                        var loadInfo = {
                                            sid: "AC76f661b586ab5b3f2191b18953a3102b",
                                            authToken: "719e6a80af86e26d5f570ff2c1a97576",
                                            to: data.countryCode + data.mobileNo,
                                            from: this.labConfig.sendSMSFromNumber,
                                            message: "Message sent..",
                                            labId: this.userSevice1.getCookie("lab"),
                                            patientId: data.patientId,
                                            url: url,
                                            dateTime: timeZone(getate.getTime())
                                            .tz(
                                                this.userSevice1.getCookie(
                                                    "defaultTz"
                                                )
                                            )
                                            .format("LL") +
                                        " at " +
                                        timeZone(getate.getTime())
                                            .tz(
                                                this.userSevice1.getCookie(
                                                    "defaultTz"
                                                )
                                            )
                                            .format("hh:mm A"),
                                        };
                                        console.log(loadInfo);
                                        Date.prototype.toISOString = function () {
                
                                            return moment(this).format("YYYY-MM-DDTHH:mm:ss");
                                            
                                            };
                                        const headers = new HttpHeaders({ "content-type": "application/json" });
                                        
                                        const body = JSON.stringify(loadInfo);
                                        console.log(body);
                                        this.httpClient
                                            .post<any>(this.global.constUrl + "sendMessage", body, {
                                                headers: headers,
                                            })
                                            .subscribe(
                                                (res) => {
                                                  
                                                    console.log(res);
                                                    setTimeout(()=>{
                                                        this.openSuccessfulDialog();
                                                        
                                                    }, 8000);
                                                },
                                                (err) => {
                                                    
                                                    this.openSnackBar(err.message, "ok");
                                                    
                                                    console.log(err);
                                                    // do something
                                                }
                                            );
                                        
                                    // }
                                });
                            // });
                            
                    }
                        else{

                        
                            const data = {
                                email: result.email,
                                comboId: comboId,
                                fullName: fullName,
                                patient: docId,
                                patientId: patientId,
                                labId: this.userSevice1.getCookie('lab'),
                                firstName: fname,
                                lastName: lName,

                            };
                                const covidData: any = {
                                    email: data.email,
                                    // phone: result.mobileNo,
                                    addedOn: (-1) * new Date().getTime(),
                                    anyScreening: false,
                                    doaTimestamp: -1 * getate.getTime(),
                                    doa: getate.getTime(),
                                    appointTime: timeZone(getate.getTime())
                            .tz(this.userSevice1.getCookie("defaultTz"))
                            .format("hh:mm A"),
                                };
                                console.log(patient.mediumSelected);
                                // const resultDataCovid = this.paginationService.addCovidResult(covidData);
                               
                                const resultDataCovid =  this.paginationService.updateCovidResult(covidData,docId)
                                
                                resultDataCovid.then(async (returnData: any) => {

                                        data['docId'] = docId;
                                        data['dateTime'] = getate.toDateString() + ' at ' + covidData['appointTime'];
                              if(patient.mediumSelected !== 'OTHER'){
                                        this.paginationService.shareLinkUrl(data).subscribe(dataResult => {
                                            console.log(data);
                                            this.openSuccessfulDialog();
                                       this.ngOnInit()
                                        });
                                    }
                                })
                          
                            }
                        })
                        }
                        
                    });
                } else {
                    this.openSnackBarOne('No patient found to Re-share link', 'OK');
                }
            });
        }
    }


    openSuccessfulDialog() {
        const dialogRef = this.dialog.open(SuccessfulComponent,
            {
                disableClose: true,
                width: '350px',
                height: '280px',
                // data: {firstName: this.firstName, lastName: this.lastName, pid: this.urlPatientId}
            });
    }


    // fucntion to prefill hours and minutes

    addZeroToHourAndMinutes(time) {
        if (time < 10) {
            return '0' + time;
        }
        return time;
    }


    // creating searching hash

    getMapValue(stringData) {
        const param = stringData.replace(/\./g, '');
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = '';
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;

        }
        return obj;
    }

    // to uppercase string

    toUpperCaseString(str) {
        return (str.trim()).toUpperCase();
    }


    prepareComboId(patient) {
        console.log('PATIENT==',patient,patient.mobile,patient.countryCode);
        let combo = '';
        // console.log(patient.countryCode);
        if (patient.mobile) {
            combo = 'patient#' + patient.patientId + '##firstName#' + patient.firstName + '##lastName#' + patient.lastName + '##mobile#' + patient.mobile;
        } else {
            combo = 'patient#' + patient.patientId + '##firstName#' + patient.firstName + '##lastName#' + patient.lastName;
        }
        console.log('PATIENT==',patient.patientId);
        return this.reShareCovid(combo, patient);

    }


    // rescheduleAppoint(,patient) {
    //     const dialogRef = this.dialog.open(ShareAssessmentDialogComponent, {
    //         width: '350px',
    //         data: {emailId: patient.email}
    //     });
    //
    // }

    openDialogWithRefEdit(ref: TemplateRef<any>, docId) {
        this.editedDocId = docId;

        this.dialog.open(ref, {
            disableClose: true, height: '250px', width: '350px',
            data: { docId: docId },
        })
        //if(pre)
        //isPreOfficeDone()
    }


    updateAppointmentTime() {
        const getate = new Date(this.staffFormEdit.value.dateTime);
        const dataToBeupdated = {
            doaTimestamp: (-1) * getate.getTime(),
            doa: getate.getTime(),
            appointTime: this.addZeroToHourAndMinutes(getate.getHours()) + ':' + this.addZeroToHourAndMinutes(getate.getMinutes()),
        }
        this.paginationService.updateCovidResult(dataToBeupdated, this.editedDocId)
            .then(data => {
                // do something

                if (this.searchText && this.searchText.length > 0) {
                    this.onClickSearch();
                }
                this.resetStafformEdit()
            })

    }
    getAppointmentData(condition) {
        this.hasFirstDataShown = false;
        this.paginationService.searchToday(condition);
        this.hasFirstDataShown = true;
    }

    fromDateVal(value) {
        this.startAt = value;
        this.paginationService.searchedFrom = parseInt(value.format('x'), 10);

    }

    toDateVal(value) {
        this.paginationService.searchedTo = parseInt(value.format('x'), 10) + 86400000;
    }



}