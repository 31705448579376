import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';

import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {AngularFireStorage} from '@angular/fire/storage';
import {CovidService} from '../../../covid.service';
import {FuseConfigService} from '../../../../../../../@fuse/services/config.service';
import {Globals} from '../../../../common/global';
import {ApiService} from '../../../../common/service/api.service';
import {StorageService} from '../../../../common/service/storage.service';




@Component({
    selector: 'app-inoffice',
    templateUrl: './inoffice.component.html',
    styleUrls: ['./inoffice.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InofficeComponent implements OnInit {
    patientForm: FormGroup;
    urlPatientId = '';
    urlLabName = '';
    viewData: any = {};
    feverish = '';
    breathing = '';
    flu = '';
    heartdisease = '';
    covidContact = '';
    cough = '';
    taste = '';
    questArr: Array<any> = [];
    fullName = '';
    inOfficeStatus: boolean;
    firstName = '';
    lastName = '';
    email = '';
    phone: number;
    firstNameOpt: any;
    lastNameOpt: any;
    disablePre: any;
    temperature = '';
    token = '';
    queryParam = {};
    inOfficeTime = '';
    preOfficeTime = '';
    addedOn: any;
    patientId = '';
    inOfficeTemperature = '';
    covidoptional = '';
    patientSign = '';
    staffSign = '';
    staffName = '';
    unit = '';
    unitInOffice = 'C';
    age: any;
    relationship = '';
    innerHtml = '';
    minV: any;
    maxV: any;
    matError = '34-39C';
    checkedList: Array<any> = [];
    consentValidMsg = '';
    selfChecked = true;
    @Input()
    max: 'D' | null;
    today = new Date();
    covidTest: boolean;
    covidTestDate: any;
    maxLengthTemp = 5;
    // acknowledgement = acknowledge;
    acknowledgement: Array<any> = [];
    spo = '';
    temperatureInOff = '';
    alertSign = '';
    signatureImage = '';
    allQuestionnaireObj = {};
    signatureImagePat = '';
    signatureImageUrlPat = '';
    siteUrl = '';
    logoUrl = '';
    hasFirstDataShown = true;
    selectedValue:string;
    
    @ViewChild(SignaturePad) public signaturePad: SignaturePad;
    public signaturePadOptions: object = { // passed through to signature_pad constructor
        'minWidth': 1,
        'canvasWidth': 250,
        'canvasHeight': 150,
        backgroundColor: 'gray',
    };
    screeningId: any;

    constructor(public covidService: CovidService, private _fuseConfigService: FuseConfigService,
                private route: ActivatedRoute, private router: Router,
                public global: Globals, private apiService: ApiService,
                private userService: StorageService,
                private formBuilder: FormBuilder, private storage: AngularFireStorage) {
    

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {


        this.patientForm = this.formBuilder.group({
            // Validators.min(36),Validators.max(108)
            inOfficeTemperature: ['', [Validators.required, Validators.pattern(/^\d*\.?\d*$/),
                (control: AbstractControl) => Validators.max(this.maxV)(control),
                (control: AbstractControl) => Validators.min(this.minV)(control)
            ]],
            unitInOffice: ['', ''],
            optionalDate: ['', ''],
            // /^[0-9]\d*$/
            // spo:['',[Validators.required,Validators.min(1),Validators.max(100),Validators.pattern(/^\d*\.?\d*$/)]],
            spo: ['', [Validators.min(1), Validators.max(100), Validators.pattern(/^\d*\.?\d*$/)]],
        });

        // set focus start

        const element = document.getElementById('spo');
        element.focus();
        // set focus end
        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.global.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }

        this.covidService.getAllConsentData(this.queryParam['labName']).then(consentData => {
           console.log('ConsentData',consentData);
            this.acknowledgement = consentData;
        })

        this.siteUrl = this.global.siteLogo;
        this.urlPatientId = this.queryParam['patient'];
        this.urlLabName = this.queryParam['labName'];

        const collectionPath = `/lab/${this.urlLabName}/config/`;
        const logoPath = this.covidService.getLabLogo(collectionPath);
        logoPath.ref.get().then(data => {
            const logoPathName = data.data();
            if (logoPathName) {
                this.logoUrl = logoPathName.logoUrl;
            }
        });
        this.covidService.getCovidResultById(this.urlPatientId).then(viewData => {
            this.viewData = viewData;
            this.allQuestionnaireObj = Object.assign({}, viewData);
            this.screeningId = this.viewData.tokenId;
            console.log(this.screeningId);
            this.fullName = this.viewData.firstName + ' ' + this.viewData.lastName;
            this.firstName = this.viewData.firstName;
            this.lastName = this.viewData.lastName;
            this.inOfficeStatus = this.viewData.inOffice;
            this.email = this.viewData.email;
            this.phone = this.viewData.phone;
            this.patientId = this.viewData.patientId;
            this.patientSign = this.viewData.patientSign;
            console.log('PatientSign',this.patientSign)
            this.addedOn = Math.abs(this.viewData.addedOn);
            this.covidoptional = this.viewData.covidoptional;
            if (this.viewData.inOfficeTime) {
                this.inOfficeTime = this.apiService.getDateStringMomentByTz(this.viewData.inOfficeTime);
            }
            if (this.viewData.preOfficeTime) {
                this.preOfficeTime = this.apiService.getDateStringMomentByTz(this.viewData.preOfficeTime);
            }
            // this.inOfficeTime = this.viewData.inOfficeTime?this.viewData.inOfficeTime: '';
            // this.preOfficeTime = this.viewData.preOfficeTime ? this.viewData.preOfficeTime:'N/A';

            // this.temperature = this.viewData.temperature ? this.viewData.temperature : 'N/A';
            if (this.viewData.temperature) {
                this.temperature = this.viewData.temperature;
                this.unit = this.viewData.unit;
                this.innerHtml = '&#176;';

            }
            if (this.viewData.inOfficeTemperature) {
                this.inOfficeTemperature = this.viewData.inOfficeTemperature;
                // this.patientForm.value.inOfficeTemperature = this.inOfficeTemperature;
                this.unitInOffice = this.viewData.unitInOffice;

            } else {
                this.inOfficeTemperature = '';
                this.unitInOffice = 'C'
            }

            if (this.viewData.login && this.viewData.login !== undefined) {
                this.disablePre = true;
            }

            if (this.covidoptional === 'y') {
                this.firstNameOpt = this.viewData.firstNameOpt;
                this.lastNameOpt = this.viewData.lastNameOpt;
                this.relationship = this.viewData.relationship;
            }

            if(this.viewData.patientSign){
                this.patientSign = this.viewData.patientSign;
                console.log('PatientSign',this.patientSign)
            }
            else{
                this.patientSign = localStorage.getItem('signatureImagePat');
                console.log('PatientSign',this.patientSign)
            }
            if (this.viewData.staffSign) {
                this.staffSign = this.viewData.staffSign;
            }
            if (this.viewData.staffName) {
                this.staffName = this.viewData.staffName;
                console.log('If',this.staffName);
            }
            else{
                this.staffName = this.userService.getCookie('staffName');
                console.log('else=',this.staffName);
            }
            if (this.viewData.ageStamp) {
                this.age = this.calculateAge(this.viewData.ageStamp);
            }
            if (this.viewData.spo) {
                this.spo = this.viewData.spo;
            }

            // replacing question data to save in db
            for (const a in this.allQuestionnaireObj) {
                if (this.allQuestionnaireObj[a].hasOwnProperty('question')) {
                    this.allQuestionnaireObj[a].question = this.replaceQuestTest(this.allQuestionnaireObj[a].question);
                }
        
            }

            this.covidTest = this.viewData.covidTest;
            this.covidTestDate = this.viewData.covidTestDate;

            // fetching only value from object
            const values: Array<any> = Object.values(this.viewData);


            for (const value of values) {

               // console.log("value" + value);
                if (value.question) {
                    this.questArr.push({
                        questionId: value.questionId,
                        questionNo: value.questionNo,
                        question: this.replaceQuestTest(value.question),
                        questionItems: value.questionItems,
                        inOfficeSelection: value.inOfficeSelection,
                        preAppointSelection: value.preAppointSelection,
                        questionVisibility : value.questionVisibility
                    });
                }
    
            }


            const sorted = this.questArr.sort((t1, t2) => {
                const name1 = t1.questionNo;
                const name2 = t2.questionNo;
                if (name1 > name2) { return 1; }
                if (name1 < name2) { return -1; }
                return 0;
            });

            this.questArr = sorted;
        });

        // show required fields
        this.patientForm.markAllAsTouched();

        console.log(this.questArr);

    }

    calculateAge(dob) {
        const diffMs = Date.now() - dob;
        const ageDt = new Date(diffMs);
        return Math.abs(ageDt.getUTCFullYear() - 1970);
    }

    numericOnlyTemp(event): boolean {
        const unitVal: string = this.patientForm.value.unitInOffice;
        if (this.patientForm.invalid) {
            this.patientForm.markAllAsTouched();
        }
        if (unitVal === 'C') {
            this.minV = 25;
            this.maxV = 40;
            this.matError = '34-39C';
        } else if (unitVal === 'F') {
            this.maxLengthTemp = 6;
            this.minV = 85;
            this.maxV = 110;
            this.matError = '96-108F';
        }


        const patt = /^\d*\.?\d*$/;
        const result = patt.test(event.key);
        return result;

    }

    onCheckboxChange(key, event) {
        this.createObj(key, event.value);
    }

    onConsentCheckboxClick(option, event) {

        if (event.checked) {
            this.checkedList.push(option.value);
            if (this.checkedList.includes(7) && !this.patientForm.value.optionalDate) {
                this.consentValidMsg = 'Select a date';
                this.selfChecked = false;
            }

        } else {
            console.log(this.acknowledgement.length);
            for (let i = 0; i < this.acknowledgement.length; i++) {
                if (this.checkedList[i] === option.value) {
                    this.checkedList.splice(i, 1);
                } if (option.value === 7) {
                    this.consentValidMsg = '';
                    this.patientForm.reset('optionalDate');
                    this.selfChecked = true;
                }
            }
        }
    }


    onChangeSelect(eventVal) {
        this.inOfficeTemperature = '';
        if (eventVal === 'C') {
            this.minV = 34;
            this.maxV = 39;
            this.matError = '34-39C';
        } else if (eventVal === 'F') {
            this.maxLengthTemp = 6;
            this.minV = 96;
            this.maxV = 108;
            this.matError = '96-108F';

        }

        // this.matError = '';
    }

    clearMsg() {
        this.consentValidMsg = '';
    }

    showImage(data, pad) {
        // || this.patientForm.invalid
        if (pad.signaturePad.isEmpty() || this.patientForm.invalid) {


            if (pad.signaturePad.isEmpty()) {
                this.alertSign = 'Signature is required.';
            }
            if (this.patientForm.invalid) {
                // alert('Please provide all required input');
                this.patientForm.markAllAsTouched();
                if(!this.inOfficeTemperature){
                    this.setFocus('inOfficeTemperature');
                }

            }
        } else {

            this.hasFirstDataShown = false;
            this.alertSign = '';

            this.signatureImage = data;
            // localStorage.setItem('signatureImagePat', this.signatureImage);
            if (this.signatureImage) {
                this.uploadSignature(this.signatureImage)
                    .then((urlData: any) => {
                        if (urlData) {
                            console.log("URLDATA+",urlData);
                            this.signatureImageUrlPat = urlData;
                            this.allQuestionnaireObj['spo'] = this.patientForm.value.spo ? this.patientForm.value.spo : '';
                            this.allQuestionnaireObj['staffName'] = this.staffName;
                            this.allQuestionnaireObj['unitInOffice'] = this.patientForm.value.unitInOffice;
                            this.allQuestionnaireObj['staffSign'] = this.signatureImageUrlPat;
                            this.allQuestionnaireObj['inOfficeTemperature'] = this.patientForm.value.inOfficeTemperature;
                            this.allQuestionnaireObj['inOffice'] = true;
                            this.allQuestionnaireObj['inOfficeTime'] = new Date().getTime();
                            // this.allQuestionnaireObj['addedOn'] = (-1) * new Date().getTime();
                            this.allQuestionnaireObj['addedOn'] = this.viewData.addedOn;


                            // this.apiService.generateConsentPdf({ labId: this.queryParam['labName'], docId: this.urlPatientId }).then(resultD => {
                            //     // do something
                            //     console.log('RESULTFor ConsentPdf=',resultD);
                            // });

                            console.log(this.allQuestionnaireObj['addedOn'])
                            console.log("ALL=",this.allQuestionnaireObj);
                            // docId  is assignthised in this.urlPatientId
                            const returnData = this.covidService.updateCovidResultWithoutLogin(this.allQuestionnaireObj, this.urlPatientId, this.queryParam['labName']);

                            returnData.then(result => {

                                console.log('returnData',returnData,result );
                                this.apiService.generateConsentPdf({ labId: this.queryParam['labName'], docId: this.urlPatientId }).then(resultD => {
                                    // do something
                                    console.log('RESULTFor ConsentPdf=',resultD);
                                });
                                this.apiService.generatePatientCovidPdf(this.queryParam['labName'], this.patientId,this.screeningId).then(pdfData => {
                                    const msg = 'Covid screening Data  Updated Successfully';
                                    console.log('RESULTFor generatePatientCovidPdf=',pdfData);
                                    this.hasFirstDataShown = true;
                                    // redirection
                                    this.viewResult(this.urlPatientId);

                                })

                            }).catch((error) => {
                                console.log('error',error);
                                // this.handleError(error);
                            });
                        }
                        else{console.log('Anjali else');
                        // this.allQuestionnaireObj['staffName'] = this.staffName;
                        // this.allQuestionnaireObj['staffSign'] = this.signatureImageUrlPat;
                        // this.apiService.generateConsentPdf({ labId: this.queryParam['labName'], docId: this.urlPatientId }).then(resultD => {
                        //     // do something
                        //     console.log('RESULTFor ConsentPdf=',resultD);
                        // });
                    }
                    })
            }

        }

    }

    viewResult(comboId) {
        const tokenStr = 'patient#' + comboId + '##labName#' + this.urlLabName;
        this.router.navigate(['/covidresultdetail'], { queryParams: { token: this.global.encodeToa(tokenStr) } });
    }



    createObj(key, selectedVal) {
        if (selectedVal === 'true') {
            selectedVal = true; // boolean
        } else {
            selectedVal = false;
        }

        console.log("selectedVal" + selectedVal);
        if (this.allQuestionnaireObj[key]) {
            return this.allQuestionnaireObj[key].inOfficeSelection = selectedVal;
        }
    }



    uploadSignature(fileData) {
        console.log('staffsignature',fileData);
        return new Promise((resolve, reject) => {

            const file = fileData.split(',')[1];
            const metadata = {
                contentType: 'image/png',
            };
            const n = Date.now();
            const signatureRef = this.storage.ref(`/signature/${n}.png`);
            signatureRef.putString(file, 'base64', metadata).then(snapshot => {
                if (snapshot) {
                    signatureRef.getDownloadURL().subscribe(url => {
                        resolve(url);

                    });

                }
            }).catch(error => {
                // do something
            });

        })
    }


    replaceQuestTest(data) {
        const pattern1 = /Have you/g;
        const pattern2 = /Do you/g;
        const pattern3 = /Are you/g;
        return data.replace(pattern1, 'Has ' + this.fullName).replace(pattern2, 'Does ' + this.fullName).replace(pattern3, 'Does ' + this.fullName);

    }

    setFocus(id) {
        const element = document.getElementById(id);
        element.focus();
    }


}