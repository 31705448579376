export class FaqFakeDb
{
    public static data = [
        {
            'question': 'Do you heat-sterilize all your instruments, including handpieces ("dental drills"), between patients?',
            'answer': `IIn keeping with Centers for Disease Control and Prevention, American Dental Association, and OSAP recommendations, your dentist should be heat-sterilizing all instruments that penetrate or contact a patient's oral tissues. Although autoclaves are most commonly used to sterilize dental instruments, some offices may have other types of heat sterilizers. Chemical-vapor sterilizers ("Chemiclaves") and dry-heat sterilizers also are appropriate for sterilizing dental instruments. Your dentist (or his or her staff) should heat-sterilize instruments that have been used on a patient before they are introduced to treat the next patient.Most dental instruments are designed to withstand repeated heat sterilization.`
        },
        {
            'question': 'How do you know that the sterilizer is working properly?',
            'answer': `Most practices use a variety of methods to ensure that the office sterilizer is doing its job. In addition to monitoring the sterilizer's gauges and readouts for proper temperature and (for autoclaves and chemical-vapor sterilizers) pressure, the staff of your dental office should wrap and seal instruments in packaging equipped with a chemical that changes color on exposure to heat and/or some other combination of sterilizing conditions. These color-change indicators also help to identify instrument packages that have been sterilized, so there's no chance that contaminated instruments could inadvertently be selected to treat the next patient. In addition, your dentist should routinely test his or her sterilizer using a vial or envelope containing spores. Called biologic monitoring, subjecting commercially prepared, sealed spore strips or vials to a sterilization cycle, then culturing the spores to ensure they have been killed, is the highest guarantee that a sterilizer is functioning and being utilized properly. Most practices use biologic monitoring weekly or monthly in combination with color-change indicators on each instrument packet and monitoring of the sterilizer gauges and readouts.`
        },
        {
            'question': 'Do you change your gloves for every patient?',
            'answer': `Every dental care provider should use new gloves for each and every patient. For procedures that are likely to involve splash or spatter, your dental team will also don a new mask as well as wear protective eyewear and apparel (possibly a gown or clinic jacket).`
        },
        {
            'question': 'Do you disinfect the surfaces in the operatory between patients?',
            'answer': `Between patients, your dental team should disinfect all the surfaces they are likely to touch during treatment. This eliminates the possibility of a dentist or auxiliary dental care provider transferring germs from a contaminated surface to the patient. To save time in preparing the treatment room for the next patient (and hopefully minimize your time in the waiting area), many practices choose to cover surfaces such as light handles, tubing, and chair controls with a plastic barrier film that keeps the surface underneath free of debris. Instead of disinfecting these surfaces between patients, the dentist or dental team member simply removes and discards the barrier and places a new, clean barrier on the surface for the next patient. Most practices choose to cover some surfaces and disinfect others between patients. Some practices disinfect all surfaces between patients; others use protective barriers for all surfaces in the treatment room.`
        },
        {
            'question': `If you are unclear on or uncomfortable with the precautions your dental practice takes to protect you during treatment, talk to your dentist or dental team member about your concerns and ask to see the office's instrument processing area.`,
            'answer': `The overwhelming majority of dental practices work very hard to ensure your safety and health during treatment and will be happy to show you what they do to protect against disease transmission. Feel free to ask questions and communicate any concerns you may have so your dentist, hygienist, or dental assistant can properly address them.`
        },
    ];
}
