import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {CassetteComponent} from './list/cassette.component';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {MatSelectModule} from '@angular/material/select';
import {BaseListService} from '../../base/base-list.service';
import {StatusService} from '../status/status.service';
import {MatChipsModule} from '@angular/material/chips';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';
import {CassetteListService} from "./list/cassette-list.service";
import { ViewcassettedetailsComponent } from './detail/viewcassettedetails.component';
import {UnassignService} from "./unassign/unassign.service";
import {PatientService} from "../patient/patient.service";
import {AssignService} from "./assign/assign.service";
import {AssignComponent} from "./assign/assign.component";
import {UnassignComponent} from "./unassign/unassign.component";
import {LabService} from '../lab/lab.service';
import {CassetteInfiniteScroll} from "./list/cassette-infinite-scroll.service";


const routes = [
    {
        path     : 'cassette',
        component: CassetteComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'assign',
        component: AssignComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'unassign',
        component: UnassignComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        CassetteComponent,
        AssignComponent,
        UnassignComponent,
        ViewcassettedetailsComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        SharedModule,


        // MatButtonModule,
        // MatChipsModule,
        // MatExpansionModule,
        // MatFormFieldModule,
        // MatIconModule,
        // MatInputModule,
        // MatPaginatorModule,
        // MatRippleModule,
        // MatSelectModule,
        // MatSortModule,
        // MatSnackBarModule,
        // MatTableModule,
        // MatTabsModule,

        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule

    ],
    exports     : [
        CassetteComponent,
        UnassignComponent,
        AssignComponent
    ],
    providers   : [
        CassetteInfiniteScroll,
        StatusService,
        CassetteListService,
        UnassignService,
        PatientService,
        AssignService,
        LabService
]
})

export class CassetteModule
{
}
