import { AfterViewInit, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Cell } from '../cell';
import { CellService } from '../cell.service';
import { Asset } from '../../asset/asset';
import { BaseCellComponent } from '../basecell/basecell.component';
import { Sterilizer } from './sterilizer';
import { LoadService } from '../../../load/load.service';
import { Load } from '../../../load/load';
import { Router } from '@angular/router';
import { StorageService } from 'app/main/module/common/service/storage.service';

const util = require('util');

@Component({
    selector: 'cell-sterilizer',
    templateUrl: './sterilizer.component.html',
    styles: [':host {display: block; border: solid 1px red; width: 100%;height: 100%}']
})
export class SterilizerComponent extends BaseCellComponent implements AfterViewInit {
    @Input() cell: Cell;
    path: string;
    sterilizer: Sterilizer;

    pendingBd: Load[];
    pendingBi: Load[];
    pendingEi: Load[];
    pendingIncubator: Load[];

    loadButtonObject = {
        text: 'Start BI',
        object: null,
        tag: '',
        visibility: true
    };


    incubatorButtonObject = {
        text: 'Update Incubator',
        object: null,
        tag: '',
        visibility: true
    };


    constructor(private cellService: CellService,
        private router: Router,
        private userService: StorageService,
        private loadService: LoadService) {
        super('SterilizerComponent');
    }


    getImage(type: string, id: string): Observable<Asset> {
        return this.cellService.getBackgroundImage(type, id);
    }

    ngAfterViewInit(): void {
        const cellType: ECellType = this.cellService.getCellType(this.cell.type);
        const equipmentPath = 'lab/' + this.userService.getCookie("lab") + '/' + 'machine' + '/' + this.cell.viewId;
        this.cellService.getRef(equipmentPath).valueChanges().subscribe((equipment) => {
            this.sterilizer = equipment;
            this.sterilizer.id = this.cell.viewId;
            // const assetId = equipment.assetId;
            // this.fetchAsset(cellType, assetId); // after remove the dependency of assetid #issue397
            const type = equipment.type;
            this.fetchAsset(cellType, type);
            this.fetchPendingBd();
        });
    }

    fetchAsset(cellType: ECellType, id: string) {
        const path = this.cellService.getAssetPath(cellType, id);
        this.cellService.getRef(path).valueChanges().subscribe((asset) => {
            if (asset) {
                this.path = asset.imageUrl;
            }

        });
    }


    fetchPendingBd() {
        this.loadService
            .getQuery(this.sterilizer.id, 'BD_Test', 'statusBd', 'P')
            .subscribe((loads) => {
                this.pendingBd = loads;
            });
    }

    fetchPendingBI() {
        this.loadService
            .getQuery(this.sterilizer.id, 'BD_Test', 'statusBi', 'I')
            .subscribe((loads) => {
                this.pendingBi = loads;
            });
    }

    fetchPendingEI() {
        this.loadService
            .getQuery(this.sterilizer.id, 'Sterilization', 'statusEi', 'I')
            .subscribe((loads) => {
                this.pendingEi = loads;
            });
    }

    fetchPendingIncubator() {
        this.loadService
            .getQuery(this.sterilizer.id, 'BI_Test', 'statusBi', 'P')
            .subscribe((loads) => {
                this.pendingIncubator = loads;
            });
    }

    getUrl() {
        return this.path;
    }

    updateSterilizerButton() {
        this.loadButtonObject.visibility = true;
        if (this.pendingBd && this.pendingBd.length > 0) {
            this.loadButtonObject.text = this.sterilizer.needAR ? 'UPDATE_AR' : 'UPDATE_BD';
            this.loadButtonObject.object = this.pendingBd[0];
            this.loadButtonObject.visibility = true;
            return;
        }

        if (this.pendingBi && this.pendingBi.length > 0) {
            this.loadButtonObject.text = 'START_INCUBATOR';
            this.loadButtonObject.object = this.pendingBi[0];
            this.loadButtonObject.visibility = true;
            return;
        }

        if (this.pendingEi && this.pendingEi.length > 0) {
            this.loadButtonObject.text = 'UPDATE_LOAD';
            this.loadButtonObject.object = this.pendingEi[0];
            this.loadButtonObject.visibility = true;
            return;
        }

        if (this.sterilizer.isNextBdMustRequire) {
            this.loadButtonObject.text = this.sterilizer.needAR ? 'START_AR' : 'START_BD';
            this.loadButtonObject.visibility = true;
            return;
        }

        if (this.sterilizer.isNextBiMustRequire) {
            this.loadButtonObject.text = 'START_BI';
            this.loadButtonObject.visibility = true;
            return;
        }

        this.loadButtonObject.text = 'ADD_LOAD';
    }

    updateIncubatorButton() {
        if (this.pendingIncubator && this.pendingIncubator.length > 0) {
            this.incubatorButtonObject.text = 'UPDATE_INCUBATOR';
            this.incubatorButtonObject.object = this.pendingIncubator[0];
            this.incubatorButtonObject.visibility = true;
            return;
        }
    }

    loadButtonClick() {
        this.router.navigateByUrl('/addLoad', { state: { sterilizerName: this.sterilizer.name } });
    }

    incubatorButtonClick() {

    }
}
