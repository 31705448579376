import {Load} from "../load";
import {customLoadType, customProgram, getLoadColor, getLoadStatus, getSingleLoadColor} from "./loadutils";

export const LoadListColumns  = (page: number) => {
    return [
        {
            columnDef: 'Sr',
            header: 'Sr.',
            cell: (element: Load, index: number) => `${page * 1 + index + 1}`,

        },
        {
            columnDef: 'Sterilizer',
            header: 'Sterilizer',
            cell: (element: Load, index: number) => `${element.machineName}`
        },
        {columnDef: 'Type', header: 'Type', cell: (element: Load, index: number) => customLoadType(element.type)},
        {
            columnDef: 'Program',
            header: 'Program',
            cell: (element: Load, index: number) => customProgram(element.type, element.program)
        },
        {columnDef: 'Load No', header: 'Load No', cell: (element: Load, index: number) => `${element.loadNo}`},
        {columnDef: 'Cycle No', header: 'Cycle No', cell: (element: Load, index: number) => `${element.cycleNo}`},
        {columnDef: 'Barcode', header: 'Barcode', cell: (element: Load, index: number) => `${element.barcode}`},
        {
            columnDef: 'BD', header: 'BD',
            cell: (element: Load, index: number) => `${element.barcodeBd ? element.barcodeBd : '-'}`,
            textColor: (element: Load, index: number) => `${getSingleLoadColor(element.statusBd)}`
        },
        {
            columnDef: 'BI', header: 'BI(Spore Test)',
            cell: (element: Load, index: number) => `${element.barcodeBi ? element.barcodeBi : '-'}`,
            textColor: (element: Load, index: number) => `${getSingleLoadColor(element.statusBi)}`
        },
        {
            columnDef: 'EI', header: 'PCD',
            cell: (element: Load, index: number) => `${element.barcodeEi ? element.barcodeEi : ''}`,
            textColor: (element: Load, index: number) => `${getSingleLoadColor(element.statusEi)}`
        },
        {columnDef: 'User', header: 'User', cell: (element: Load, index: number) => `${element.startedBy.name}`},
        {columnDef: 'Date', header: 'Date', cell: (element: Load, index: number) => `${element.date}`},
        {columnDef: 'Time', header: 'Time', cell: (element: Load, index: number) => `${element.time}`},
        {
            columnDef: 'Status', header: 'Status',
            cell: (element: Load, index: number) => `${getLoadStatus(element)}`,
            textColor: (element: Load, index: number) => `${getLoadColor(element)}`
        },
        {
            columnDef: 'AI Image',
            header: 'AI Image',
            cell: (element: Load, index: number) => `${element.aiImageUrl ? element.aiImageUrl : ''}`
        },

        {
            columnDef: 'view',
            header: 'View',
            cell: (element: Load, index: number) => `${element.machineReportUrl ? element.machineReportUrl : element.reportImageUrl ? element.reportImageUrl : ''}`
        },
    ]
};
